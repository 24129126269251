import { useEffect, useState } from "react";
import { Province } from "../../../shared/types";
import getAvailableBillingCodes, { BillingCodesDict } from "./getAvailableBillingCodes";

interface useBillingCodesProps {
    province: Province
}

const useBillingCodes = ({ province }: useBillingCodesProps) => {
    const [billingCodes, setBillingCodes] = useState<BillingCodesDict>({})
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getAvailableBillingCodes(province)
            .then((codes) => {
                setBillingCodes(codes);
            }).finally(() => {
                setLoading(false);
            })
    }, [province])


    return {
        billingCodes,
        loading
    }
}

export default useBillingCodes;