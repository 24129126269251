import { BillingRecord, FrontendData } from "../../../shared/types";
import { FetchMethod } from "./BillingTable";
import { SortableKeys, Order } from "./types";


export function formatStatus(status: string){
    const result = status.replace( /([A-Z])/g, " $1" );
    return result.charAt(0).toUpperCase() + result.slice(1);
}

function descendingComparator(a: BillingRecord, b: BillingRecord, orderBy: SortableKeys) {
    if(orderBy === 'created' || orderBy === 'lastUpdatedAt' || orderBy === 'submittedAt') {
        const aTime = a[orderBy];
        const bTime = b[orderBy];
        if(aTime === undefined && bTime === undefined)
            return 0;
        if(aTime === undefined)
            return 1;
        if(bTime === undefined)
            return -1;
        if (bTime.seconds < aTime.seconds)
            return -1;
        if (bTime.seconds > aTime.seconds)
            return 1;
        return 0;
    }
    else if(orderBy === 'firstName') {//Order by full name
        const aName = `${a.firstName} ${a.lastName}`;
        const bName = `${b.firstName} ${b.lastName}`;
        if (bName <  aName)
            return -1;
        if (bName > aName) 
            return 1;
        return 0;
    }
    else {
        const aOrder = a[orderBy]
        const bOrder = b[orderBy]
    
        if(bOrder === undefined && aOrder !== undefined) {
            return -1;
        }
        if(bOrder !== undefined && aOrder === undefined) {
            return 1;
        }
        if(bOrder === undefined && aOrder === undefined) {
            return 0;
        }
        if(bOrder !== undefined && aOrder !== undefined) {
            if (bOrder < aOrder) {
                return -1;
            }
            if (bOrder > aOrder) {
                return 1;
            }
        }
        return 0;
    }

}


export function getComparator(order: Order, orderBy: SortableKeys): (a: BillingRecord, b: BillingRecord) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array: FrontendData[], comparator: (a: BillingRecord, b: BillingRecord) => number) {
    const stabilizedThis = array.map((el: FrontendData, index: number) => [el, index] as [FrontendData, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0].data, b[0].data);
        if (order !== 0) 
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function getFetchMethodText (fetchMethod: FetchMethod) {
    switch(fetchMethod) {
        case FetchMethod.Created:
            return "Service Date"
        case FetchMethod.LastUpdated:
            return "Last Updated"
        case FetchMethod.HLinkResultReceivedAt:
            return "Date Accepted or Refused"
        case FetchMethod.BatchNumber:
            return "Batch Number"
        case FetchMethod.All:
            return "All Time"
    }
}