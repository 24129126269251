import {
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
} from "@material-ui/core";
import app from "../../../firebase";
import GetAppIcon from "@material-ui/icons/GetApp";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import {
    ProcessState,
    ProcessStatus,
    useProcessState,
} from "@alethea-medical/alethea-components";

import { useState, useEffect } from "react";

import PaymentBatchErrors from "./PaymentBatchErrors";
import PaymentRecordsTable from "./PaymentRecordsTable";
import { PaymentRecord } from "../../../../shared/types";

import { saveAs } from "file-saver";

interface BatchProps {
    batch: any;
    classes: any;
    refresh: (message: string) => Promise<void>;
}

const Batch = ({ batch, classes, refresh }: BatchProps) => {
    const submitPaymentBatch = app
        .functions()
        .httpsCallable("submitPaymentBatch");

    const [openConfirmSubmissionDialog, setConfirmSubmissionDialog] =
        useState(false);
    const [openDeletePaymentBatchDialog, setDeletePaymentBatchDialog] =
        useState(false);

    const [selectedResponseErrors, setSelectedResponseErrors] = useState<
        PaymentRecord[] | null
    >(null);

    const [submitButtonDisabled, setSubmitButtonDisabled] =
        useState<boolean>(false);

    const [displayRecords, setDisplayRecords] = useState<boolean>(true);

    const [successMessage, setSuccessMessage] = useState<string>("");
    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState({});

    const getPaymentFile = app.functions().httpsCallable("getPaymentFile");
    const checkForResponseFile = app
        .functions()
        .httpsCallable("checkForResponseFile");
    const deletePaymentBatch = app
        .functions()
        .httpsCallable("deletePaymentBatch");

    const submitPaymentBatchHandler = () => {
        setConfirmSubmissionDialog(true);
    };

    const submitPaymentBatchCall = () => {
        setConfirmSubmissionDialog(false);
        setSubmitButtonDisabled(true);
        setProcessState(ProcessState.running);
        submitPaymentBatch({ batchNumber: batch.batchNumber })
            .then((response) => {
                setSubmitButtonDisabled(false);
                setProcessState(ProcessState.idle);
                return refresh(response.data);
            })
            .catch((error) => {
                setSubmitButtonDisabled(false);
                errorHandler({ error });
            });
    };

    const deletePaymentBatchHandler = () => {
        setDeletePaymentBatchDialog(true);
    };

    const deletePaymentBatchCall = () => {
        setDeletePaymentBatchDialog(false);
        setProcessState(ProcessState.running);
        deletePaymentBatch({ batchNumber: batch.batchNumber })
            .then((response) => {
                setProcessState(ProcessState.idle);
                refresh(response.data);
            })
            .catch((error) => {
                errorHandler({ error });
            });
    };

    const handleFileDownload = (fileName: string) => {
        setProcessState(ProcessState.running);
        getPaymentFile({ batchNumber: batch.batchNumber, dateTo: batch.dateTo })
            .then((response) => {
                saveTxtFile(
                    response.data,
                    fileName + "-" + batch.batchNumber + ".txt"
                );
                setSuccessMessage("File downloaded successfully.");
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 2500);
            })
            .catch((error) => {
                errorHandler({ error });
            });
    };

    const saveTxtFile = (content: string, fileName: string) => {
        const blob = new Blob([content], { type: "text/plain" });
        saveAs(blob, fileName);
    };

    const renderRecords = () => {
        if (displayRecords) return setDisplayRecords(false);
        setSelectedResponseErrors(null);
        setDisplayRecords(true);
    };

    const renderErrors = () => {
        if (selectedResponseErrors) return setSelectedResponseErrors(null);
        setDisplayRecords(false);
        setSelectedResponseErrors(batch.errorRecords);
    };

    const timestampToDateAndTime = (timestamp: {
        _seconds: number;
        _nanoseconds: number;
    }) => {
        if (!timestamp) return "N/A";
        const date = new Date(timestamp._seconds * 1000);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    };

    useEffect(() => {
        setSuccessMessage("");
        setProcessState(ProcessState.idle);
        setSelectedResponseErrors(null);
    }, [batch.batchNumber]);

    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <div className={classes.paper}>
                        <ProcessStatus
                            state={processState}
                            errorMessage={processErrorMessage}
                            successMessage={successMessage}
                            useSnackbar
                            setState={setProcessState}
                        />

                        <Typography variant="h5" className={classes.title}>
                            Payment Details
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Download ACH File</TableCell>
                                    <TableCell align="right">
                                        <Tooltip title="Download ACH Payment File">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    handleFileDownload(
                                                        "achFile"
                                                    )
                                                }
                                                startIcon={<GetAppIcon />}
                                            >
                                                Export
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Date Submitted</TableCell>
                                    <TableCell align="right">
                                        {timestampToDateAndTime(
                                            batch.dateSubmitted
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Payment Records</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => renderRecords()}
                                            endIcon={<ArrowRightAltIcon />}
                                        >
                                            View Payments
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                {!batch.dateSubmitted && (
                                    <>
                                        <TableRow>
                                            <TableCell>
                                                Payment File Submitted
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Confirm payment file was submitted to RBC">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() =>
                                                            submitPaymentBatchHandler()
                                                        }
                                                        disabled={
                                                            submitButtonDisabled
                                                        }
                                                    >
                                                        Batch Submitted to RBC
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Delete Payment Batch
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Delete payment batch and associated payment records">
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            deletePaymentBatchHandler()
                                                        }
                                                    >
                                                        Delete Batch
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>

                        {batch.dateSubmitted && batch.responses.length > 0 && (
                            <>
                                <Typography
                                    variant="h6"
                                    className={classes.title}
                                >
                                    RBC Response Details
                                </Typography>
                                <Table>
                                    <TableRow>
                                        <TableCell>Failed Records</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="right">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => renderErrors()}
                                                endIcon={<ArrowRightAltIcon />}
                                            >
                                                View Errors
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Response File Received
                                            </TableCell>
                                            <TableCell align="right">
                                                # of errors
                                            </TableCell>
                                            <TableCell align="right">
                                                Date Received
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {batch.responses.map(
                                            (response: any, index: number) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {response.fileName}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            response
                                                                .failedCustomerNumbers
                                                                .length
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {timestampToDateAndTime(
                                                            response.dateReceived
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    {!displayRecords && selectedResponseErrors && (
                        <PaymentBatchErrors
                            errorRecords={selectedResponseErrors}
                            refresh={refresh}
                            classes={classes}
                        />
                    )}
                    {displayRecords && (
                        <PaymentRecordsTable
                            records={batch.paymentRecords}
                            classes={classes}
                        />
                    )}
                </Grid>
            </Grid>

            <Dialog // confirm submission
                open={openConfirmSubmissionDialog}
                onClose={() => setConfirmSubmissionDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm autopayments submission"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to submit autopayments to RBC express for
                        the selected period. Do you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmSubmissionDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={submitPaymentBatchCall}
                        color="primary"
                        autoFocus
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog // delete payment batch
                open={openDeletePaymentBatchDialog}
                onClose={() => setDeletePaymentBatchDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm payment batch deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to delete the selected payment batch. Do
                        you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeletePaymentBatchDialog(false)}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={deletePaymentBatchCall}
                        color="primary"
                        autoFocus
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Batch;

