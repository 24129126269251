import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormTextField from '../../components/FormTextField';
import EmailInput from '../../components/EmailInput';


interface FormLoginInputProps {
    control: any,
    showUnchangedPassword?: boolean
}

const FormLoginInput = ({ control, showUnchangedPassword }: FormLoginInputProps) => {

    const isPasswordValid = (newPassword?: string) => {
        if(newPassword !== undefined)
            return (newPassword.length >= 6 || newPassword.length == 0) ? undefined : "Password must be at least 6 characters "
        return undefined;
    }    

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} lg={6}>
                <EmailInput
                    control={control}
                    name="email"
                    label="Email"
                    rules={{
                        required: { value: true, message: "Email is required" }
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <FormTextField
                    {...{ placeholder: showUnchangedPassword ? "Password: (Unchanged)" : undefined }}
                    control={control}
                    name="password"
                    label={showUnchangedPassword ? undefined : "Password"}
                    rules={{
                        validate: { isPasswordValid: isPasswordValid }
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default FormLoginInput;