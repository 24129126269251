import React, {useState, useEffect, useContext} from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../AuthProvider";

const Footer = () => {

    return (
        <div className="footer">
            {/* <small>ENTiD</small> */}
        </div>
    )
}

export default Footer;