import React, { useState } from 'react';
import app from "../../firebase";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ProcessState, ProcessStatus } from '@alethea-medical/react-components';
import { FrontendData } from '../../../shared/types';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submitStatus: {
            marginTop: theme.spacing(1)
        },
        tableCell: {
            overflow: 'auto',
        }
    }),
);

interface SubmitDialogProps {
    showButton: boolean,
    dataToSubmit: FrontendData[],
    refreshRecords: () => void
}

const SubmitButton = ({ showButton, dataToSubmit, refreshRecords  }: SubmitDialogProps) => {
    const submitHLinkFile = app.functions().httpsCallable("hLink-submitHLinkFile");

    const classes = useStyles();
    const [showConfirmSubmit, setShowConfirmSubmit] = useState<boolean>(false);
        
    const [submitState, setSubmitState] = useState(ProcessState.idle);
    const [submitError, setSubmitError] = useState('');

    const submitAllNew = () => {

        setSubmitState(ProcessState.running);
        
        //Filter out non new statuses in case some somehow make it through.
        const filteredSubmitData = dataToSubmit.filter((data) => {
            return data.data.status === "new"
        })

        //Submit new based on filter
        submitHLinkFile(filteredSubmitData)
        .then((result: any) => {
            if(result.data.error)
                return Promise.reject(new Error(result.data.error));

            setTimeout(() => {
                setSubmitState(ProcessState.success);

                //Allow db to propogate
                //Force refresh of billing records to get claim numbers and batch numbers
                refreshRecords();
                setTimeout(() => {
                    setSubmitState(ProcessState.idle);
                }, 2000)
            }, 1000)
        })
        .catch((error: Error) => {
            console.error(error);
            setSubmitError(error.message);
            setSubmitState(ProcessState.error);
        })
    }

    return (
        <>
            <Collapse in={showButton} timeout="auto">
   
                <Tooltip title="Records are automatically submitted once a day at 12:15 AM. Click to open manual submission dialog.">
                    <Button                 
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon/>}
                        onClick={() => {setShowConfirmSubmit(true);}}
                        disabled={submitState !== ProcessState.idle || !showButton || dataToSubmit.length === 0}
                    >
                        Manual Submission
                    </Button>
                </Tooltip>
            </Collapse>
            <div className={classes.submitStatus}>
                <ProcessStatus state={submitState} errorMessage={submitError} successMessage={`Billing records submitted`} loadingMessage={"Submitting records..."} useSnackbar setState={setSubmitState}/>
            </div>   
            
            <Dialog 
                open={showConfirmSubmit}
                onClose={() => { setShowConfirmSubmit(false);}}
            >
                <DialogTitle id="alert-dialog-title">{`Submit ${dataToSubmit.length} billing records?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Submitting the following records
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow>
                                                <TableCell align={'left'} padding={'default'}>Doctor</TableCell>
                                                <TableCell align={'left'} padding={'default'}>Service Date</TableCell>
                                                <TableCell align={'left'} padding={'default'}>PHN</TableCell>
                                                <TableCell align={'left'} padding={'default'}>Billing Code</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {dataToSubmit.sort((a, b) => {
                                        return a.data.created.toMillis() > b.data.created.toMillis() ? -1 : 1
                                    }).map((data) => {
                                        return (
                                            <TableRow
                                                key={`submit_record_data_${data.uid}`}
                                                tabIndex={-1}
                                            >
                                                <TableCell className={classes.tableCell} align="left">{data.data.firstName} {data.data.lastName}</TableCell>
                                                <TableCell className={classes.tableCell} align="left">{new Date(data.data.created.toMillis()).toLocaleString()}</TableCell>
                                                <TableCell className={classes.tableCell} align="left">{data.data.patientId}</TableCell>
                                                <TableCell className={classes.tableCell} align="left">{data.data.billingCode}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={(event: any) => { setShowConfirmSubmit(false);}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={(event: any) => {
                        event.preventDefault();
                        setShowConfirmSubmit(false);
                        submitAllNew();
                    }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SubmitButton;