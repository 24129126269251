import React, { useEffect } from "react";
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        datePicker: {
            paddingRight: theme.spacing(2)
        }
    })
);

interface BillingDatePickerProps {
    fromDate: Date,
    toDate: Date,
    handleDateFromChange: (date: any) => void,
    handleDateToChange: (date: any) => void,
}

const DatePicker = ( { fromDate, toDate, handleDateFromChange, handleDateToChange }: BillingDatePickerProps) => {
    const classes = useStyles();

    const setFromDate = (date: MaterialUiPickersDate) => {
        if(date !== null) {
            handleDateFromChange(date.startOf('day').toDate());
        }
    }

    const setToDate = (date: MaterialUiPickersDate) => {
        if(date !== null) {
            handleDateToChange(date.endOf('day').toDate());
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiDatePicker className={classes.datePicker} variant="inline" format="MMM DD, yyyy" autoOk label="From" value={fromDate} onChange={setFromDate}/>
            <MuiDatePicker className={classes.datePicker} variant="inline" format="MMM DD, yyyy" autoOk label="To" value={toDate} onChange={setToDate}/>
        </MuiPickersUtilsProvider>
    );
}

export default DatePicker;