import { Radio, RadioGroup } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { Controller, useFormContext } from "react-hook-form";

const Toggles = () => {
    const { control } = useFormContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Controller
                    name="college"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                control={<Radio value="CCFP" color="primary" />}
                                label={
                                    <Tooltip title="Physician is part of the College of Family Physicians of Canada. Physicians can only have one college type">
                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>CCFP Consultant</Grid>
                                        </Grid>
                                    </Tooltip>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Radio value="FRCPC" color="primary" />
                                }
                                label={
                                    <Tooltip title="Physician is part of the Fellow of the Royal College of Physicians of Canada. Physicians can only have one college type">
                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                FRCPC/FRCSC Specialists
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                }
                            />
                        </RadioGroup>
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default Toggles;

