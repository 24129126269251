import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { Controller, useFormContext } from "react-hook-form";

interface AcceptanceTogglesProps {}

const iconStyle = { width: "2em", height: "2em" };

const AcceptanceToggles: React.FC<AcceptanceTogglesProps> = () => {
    const { control, setValue } = useFormContext();

    const handleToggle = (field: string, checked: boolean) => {
        setValue(field, checked);
        if (field === "acceptanceFlags.acceptAll") {
            setValue("acceptanceFlags.acceptExisting", !checked);
        } else if (field === "acceptanceFlags.acceptExisting") {
            setValue("acceptanceFlags.acceptAll", !checked);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Controller
                    name="acceptanceFlags.acceptAll"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) =>
                                        handleToggle(
                                            "acceptanceFlags.acceptAll",
                                            e.target.checked
                                        )
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                <Tooltip title="Will accept eConsults for all patients">
                                    <Grid
                                        container
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        {/* Hide icon for now */}
                                        {/* <Grid item>
                                            <img
                                                src="/acceptance-icons/icon-green-chat.svg"
                                                alt="Accept All"
                                                style={iconStyle}
                                            />
                                        </Grid> */}
                                        <Grid item>All patients</Grid>
                                    </Grid>
                                </Tooltip>
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="acceptanceFlags.acceptExisting"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) =>
                                        handleToggle(
                                            "acceptanceFlags.acceptExisting",
                                            e.target.checked
                                        )
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                <Tooltip title="Will only accept eConsults for existing patients">
                                    <Grid
                                        container
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <img
                                                src="/acceptance-icons/icon-orange-chat.svg"
                                                alt="Accept Existing"
                                                style={iconStyle}
                                            />
                                        </Grid>
                                        <Grid item>Existing patients only</Grid>
                                    </Grid>
                                </Tooltip>
                            }
                        />
                    )}
                />
            </Grid>
            <Grid item xs={4}>
                <Controller
                    name="acceptanceFlags.acceptReferral"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) =>
                                        field.onChange(e.target.checked)
                                    }
                                    color="primary"
                                />
                            }
                            label={
                                <Tooltip title="Will accept in-person referrals if necessary through Alethea eConsults">
                                    <Grid
                                        container
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <img
                                                src="/acceptance-icons/icon-stethoscope.svg"
                                                alt="Accept Referral"
                                                style={iconStyle}
                                            />
                                        </Grid>
                                        <Grid item>In-person referrals</Grid>
                                    </Grid>
                                </Tooltip>
                            }
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default AcceptanceToggles;

