

const sharedStyles = (theme: any) => {
    const disabledTextColor = "#495057";
    const disabledBGColor = "#E9ECEF";
    return {
      canDisable: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: disabledTextColor,
            backgroundColor: disabledBGColor
        }
      }
    };
};

export default sharedStyles;