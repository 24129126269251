import app from "../../firebase";
import { Specialist, UserProfile } from "@alethea-medical/aletheamd-types";
import Grid from "@material-ui/core/Grid";
import useUserDropdownV2 from "../User/useUserDropdownV2";
import EditSpecialistForm from "./EditSpecialistForm";
import { useEffect, useState } from "react";
import {
    useProcessState,
    ProcessState,
    ProcessStatus,
} from "@alethea-medical/alethea-components";

interface SpecialistUser {
    id: string;
    userProfile: UserProfile;
    specialistProfile: Specialist.Profile | undefined;
}

const EditSpecialistProfile = () => {
    const getDoc = app.functions().httpsCallable("portal-getDoc");

    const [selectedSpecialistProfile, setSelectedSpecialistProfile] = useState<
        Specialist.Profile | undefined
    >(undefined);

    const [selectedUserProfile, setSelectedUserProfile] = useState<
        UserProfile | undefined
    >(undefined);

    const { createDropdown, selectedUid } = useUserDropdownV2({
        typesenseFilterString: "userRoles: specialist",
    });

    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState({});

    const getSelectedSpecialistProfile = async () => {
        if (selectedUid === undefined) {
            setSelectedSpecialistProfile(undefined);
            return;
        }

        setProcessState(ProcessState.running);

        getDoc({ collection: "specialists", id: selectedUid })
            .then((result) => {
                setProcessState(ProcessState.idle);
                let specialistProfile = result.data as
                    | Specialist.Profile
                    | undefined
                    | null;
                if (specialistProfile === null) specialistProfile = undefined;
                if (specialistProfile === undefined) {
                    // There is no specialist profile, so grab the user profile since we need it for user ID
                    getDoc({ collection: "users", id: selectedUid })
                        .then((result) => {
                            setSelectedUserProfile(result.data as UserProfile);
                        })
                        .catch((error) => {
                            errorHandler({
                                error,
                                userMessage: "Error getting user profile.",
                            });
                        });
                }
                setSelectedSpecialistProfile(
                    result.data as Specialist.Profile | undefined
                );
            })
            .catch((error) => {
                errorHandler({
                    error,
                    userMessage: "Error getting specialist profile.",
                });
            });
    };

    useEffect(() => {
        getSelectedSpecialistProfile();
    }, [selectedUid]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {createDropdown()}
            </Grid>
            <Grid item xs={12}>
                <ProcessStatus
                    state={processState}
                    errorMessage={processErrorMessage}
                    loadingMessage="Getting user profile..."
                />
            </Grid>
            <Grid item xs={12}>
                {selectedUid !== undefined &&
                    (selectedSpecialistProfile || selectedUserProfile) && (
                        <EditSpecialistForm
                            selectedUserProfile={selectedUserProfile}
                            selectedUserId={selectedUid}
                            specialist={selectedSpecialistProfile}
                        />
                    )}
            </Grid>
        </Grid>
    );
};

export default EditSpecialistProfile;
