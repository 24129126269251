import { Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { PaymentStatusReport } from '../../../shared/types';

interface PaymentStatusProps {
    paymentStatusReport: PaymentStatusReport | null,
}

const PaymentStatus = ({ 
    paymentStatusReport, 
} : PaymentStatusProps) => {

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>                
                            <Typography variant="h5">Billing Status</Typography>
                        </TableCell>
                        <TableCell align="right">Billing Records</TableCell>
                        <TableCell align="right">Doctors</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Total billing from AH</TableCell>
                        <TableCell align="right">{paymentStatusReport?.billing.billingRecordCount}</TableCell>
                        <TableCell align="right">{paymentStatusReport?.billing.doctorCount}</TableCell>
                        <TableCell align="right">${paymentStatusReport?.billing.amount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total owed to doctors</TableCell>
                        <TableCell align="right">{paymentStatusReport?.owed.billingRecordCount}</TableCell>
                        <TableCell align="right">{paymentStatusReport?.owed.doctorCount}</TableCell>
                        <TableCell align="right">${paymentStatusReport?.owed.amount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total pending submission to RBC</TableCell>
                        <TableCell align="right">{paymentStatusReport?.pending.billingRecordCount}</TableCell>
                        <TableCell align="right">{paymentStatusReport?.pending.doctorCount}</TableCell>
                        <TableCell align="right">${paymentStatusReport?.pending.amount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total submitted to RBC waiting for processing</TableCell>
                        <TableCell align="right">{paymentStatusReport?.submitted.billingRecordCount}</TableCell>
                        <TableCell align="right">{paymentStatusReport?.submitted.doctorCount}</TableCell>
                        <TableCell align="right">${paymentStatusReport?.submitted.amount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total payments failed</TableCell>
                        <TableCell align="right">{paymentStatusReport?.failed.billingRecordCount}</TableCell>
                        <TableCell align="right">{paymentStatusReport?.failed.doctorCount}</TableCell>
                        <TableCell align="right">${paymentStatusReport?.failed.amount.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total paid out to doctors</TableCell>
                        <TableCell align="right">{paymentStatusReport?.paid.billingRecordCount}</TableCell>
                        <TableCell align="right">{paymentStatusReport?.paid.doctorCount}</TableCell>
                        <TableCell align="right">${paymentStatusReport?.paid.amount.toFixed(2)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default PaymentStatus;