const firebaseConfigProd = {
    apiKey: "AIzaSyCaAtmmUmuLUKGOVYmfWgRqDNj_bRUwQwM",
    authDomain: "adminportal-a905b.firebaseapp.com",
    databaseURL: "https://adminportal-a905b.firebaseio.com",
    projectId: "adminportal-a905b",
    storageBucket: "adminportal-a905b.appspot.com",
    messagingSenderId: "451389774401",
    appId: "1:451389774401:web:d310068ae81e2e746844c0",
    measurementId: "G-TTR25EJ0PS"
};

const firebaseConfigStaging = {
    apiKey: "AIzaSyAVy93Ijx64O71FkU18EJAeZi4J6RJd4A8",
    authDomain: "adminportal-staging-a905b.firebaseapp.com",
    projectId: "adminportal-staging-a905b",
    storageBucket: "adminportal-staging-a905b.appspot.com",
    messagingSenderId: "129555361199",
    appId: "1:129555361199:web:f5c82540d87d8b6050935a",
    measurementId: "G-6RP8NC38LB"
};

const firebaseConfigDev = {
    apiKey: "AIzaSyAjjCwdgFwNiE611qm3F2oinvFwOouK4L8",
    authDomain: "adminportal-dev-a905b.firebaseapp.com",
    projectId: "adminportal-dev-a905b",
    storageBucket: "adminportal-dev-a905b.appspot.com",
    messagingSenderId: "225689756116",
    appId: "1:225689756116:web:e76a093f60e9f7f3e6f641",
    measurementId: "G-SWQTFQ5WZB"
};

const firebaseConfigDemo = {
    apiKey: "AIzaSyChpxdBXFfpZzKcrlqbptXLFlB23lKS6QU",
    authDomain: "adminportal-demo-a905b.firebaseapp.com",
    projectId: "adminportal-demo-a905b",
    storageBucket: "adminportal-demo-a905b.appspot.com",
    messagingSenderId: "295098475179",
    appId: "1:295098475179:web:269b1417037dfa12ee4dee",
    measurementId: "G-QJ4LM6EWDP"
};

export { firebaseConfigProd, firebaseConfigStaging, firebaseConfigDev, firebaseConfigDemo };