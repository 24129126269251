import { ProcessState, useProcessState } from "@alethea-medical/alethea-components";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { Clinic } from "@alethea-medical/aletheamd-types";
import { useEffect, useState } from "react";
import app from "../../firebase";

export interface ClinicItem {
    id: string,
    data: Clinic.Clinic
}


export const newClinicId = "new_clinic_id"// Use as new clinic placeholder ID

const EditClinicsController = () => {
    
    const getDoc = app.functions().httpsCallable("portal-getDoc");


    const [clinics, setClinics] = useState<ClinicItem[]>([])
    const [selectedClinic, setSelectedClinic] = useState<ClinicItem>()

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success
    }

    const refreshClinicDropdown = () => {
        setProcessState(ProcessState.running);
        getDoc({ collection: dbNames.clinics })
            .then((result) => {
                setProcessState(ProcessState.idle);
                result.data.sort(sortClinics)
                setClinics(result.data)
                
                // If previously selected clinic was deleted, then unselect clinic
                if(!result.data.find((clinic: {id: string}) => clinic.id === selectedClinic?.id)) {
                    setSelectedClinic(undefined)
                }
                // If previously selected clinic was newly created, unselect it since the ID has now changed
                if(selectedClinic?.id === newClinicId) {
                    setSelectedClinic(undefined)
                }
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: error.message
                })
            })
    }

    useEffect(() => {
        refreshClinicDropdown()
    }, []);

    const sortClinics = (a: ClinicItem, b: ClinicItem) => {
        return `${a.data.clinicName} ${a.id}`.toLowerCase() > `${b.data.clinicName} ${b.id}`.toLowerCase() ? 1 : -1;
    }

    const onSelectClinic = (clinicId: string) => {
        setSelectedClinic(clinics.find((g) => g.id === clinicId))
    }

    const createClinicHandler = () => {
        if(clinics.find((g) => g.id === newClinicId)) {
            errorHandler({
                userMessage: "Save the new clinic before creating a new one."
            })
            return
        }

        const newClinic: ClinicItem = {
            id: newClinicId,
            data: {
                clinicName: "New Clinic",
                moas: [],
                physicians: [],
                users: [],
            }
                
        }

        setClinics([...clinics, newClinic])
        setSelectedClinic(newClinic)
    }

    return {
        clinics, 
        selectedClinic, setSelectedClinic,
        createClinicHandler,
        onSelectClinic, refreshClinicDropdown,
        processState, processErrorMessage, isDisabled: isDisabled()
    }
}

export default EditClinicsController;