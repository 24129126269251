import { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import EditIcon from "@material-ui/icons/Edit"
import PermissionCard from "./PermissionCard";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { resourceKeys } from '@alethea-medical/aletheamd-db-keys';
import { RolePermissionsWithKey } from './EditRoles';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        roleContainer: {
            padding: theme.spacing(1)
        },
        header: {
            fontSize: "1.4em",
            "-webkit-user-select": "none", /* Safari */
            "-ms-user-select": "none", /* IE 10 and IE 11 */
            "user-select": "none" /* Standard syntax */
        },
        expandButton: {
            padding: theme.spacing(1)
        }
    })
)

interface RoleRowProps {
    role: RolePermissionsWithKey,
    startEditingHandler: (id: string) => void
}

const RoleRow = ({ role, startEditingHandler }: RoleRowProps) => {
    const classes = useStyles();

    const [expand, setExpand] = useState(false)
    

    return (
        <Paper>
            <Grid container className={classes.roleContainer}>
                <Grid item xs={6}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={2}>
                            <Button startIcon={<EditIcon color="primary"/>} variant="text" onClick={() => { startEditingHandler(role.id) }}>
                                Edit
                            </Button>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography className={classes.header}>{role.data.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">ID: {role.id}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1">Description: {role.data.description}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container alignItems="center">
                        <Grid item onClick={() => {setExpand(!expand)}}>
                            <Typography className={classes.header}>Permissions</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => {setExpand(!expand)}} className={classes.expandButton}>
                                {expand ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={expand}>
                                <Grid container spacing={1}>
                                    {Object.values(resourceKeys).map((resourceKey, index) => 
                                        <Grid item xs={6} key={`resource_permission_view_${resourceKey}_${index}`}>
                                            <PermissionCard resourceKey={resourceKey} greyOut={!role.data.permissions.includes(resourceKey)}/>
                                        </Grid>
                                    )}
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default RoleRow;