import { Fcm } from "@alethea-medical/aletheamd-types";
import { useState } from "react";
import { CampaignItem } from "./GetCampaigns";
import CampaignForm from "./CampaignForm";
import { PaperModal } from "@alethea-medical/alethea-components";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            maxHeight: "90vh",
            overflowY: "auto",
        }
    })
)

interface EditCampaignProps {
    updateCampaign: (id: string, campaign: Partial<Fcm.Campaign>) => void

}

const EditCampaign = ({ updateCampaign }: EditCampaignProps) => {
    const classes = useStyles();
    const [campaign, setCampaign] = useState<CampaignItem | undefined>(undefined);

    return {
        setCampaign,
        renderModal: () => (
            <PaperModal show={campaign !== undefined} setShow={(show: boolean) => {
                if(show === false) setCampaign(undefined)
            }} containerClassName={classes.modal} flexWidth flexHeight enablePadding>
                {campaign === undefined ? null : 
                <>
                    <Typography variant="h5">{campaign.data.status === "completed" ? "View" : "Edit"} Campaign</Typography>
                    <CampaignForm mode={campaign.data.status === "completed" ? "view" : "edit"} campaignId={campaign.id} campaign={campaign.data} updateCampaign={updateCampaign}/>
                </>}
            </PaperModal>
        )
    }
}

export default EditCampaign;