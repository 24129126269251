import Grid from '@material-ui/core/Grid';
import FormTextField from '../../components/FormTextField';


interface FormBillingInputProps {
    control: any,
}

const FormBillingInput = ({ control }: FormBillingInputProps) => {


    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} lg={8}>
                <FormTextField
                control={control}
                name="legalName"
                label="Legal Name"
                rules={{
                    required: { value: true, message: 'Legal name is required' },
                    minLength: { value: 2, message: 'Legal name must be at least 2 characters' },
                    maxLength: { value: 30, message: 'Legal name cannot be more than 30 characters' },
                    validate: {
                        notOnlySpaces: (value: string) => /\S/.test(value) || 'Legal name cannot consist only of spaces',
                    },
                }}
                />
            </Grid>
            <Grid item xs={12} lg={8}>
                <FormTextField
                control={control}
                name="institutionNumber"
                label="Institution Number"
                rules={{
                    required: { value: true, message: 'Institution number is required'},
                    minLength: { value: 3, message: 'Institution number must be 3 characters'},
                    maxLength: { value: 3, message: 'Institution number must be 3 characters'},
                    validate: {
                        notOnlySpaces: (value: string) => /\S/.test(value) || 'Legal name cannot consist only of spaces',
                    },
                }}
                />
            </Grid>
            <Grid item xs={12} lg={8}>
                <FormTextField
                control={control}
                name="transitNumber"
                label="Transit Number"
                rules={{
                    required: { value: true, message: 'Transit number is required' },
                    minLength: { value: 5, message: 'Transit number must be 5 characters'},
                    maxLength: { value: 5, message: 'Transit number must be 5 characters'},
                    validate: {
                        notOnlySpaces: (value: string) => /\S/.test(value) || 'Legal name cannot consist only of spaces',
                    },
                }}
                />
            </Grid>
            <Grid item xs={12} lg={8}>
                <FormTextField
                control={control}
                name="accountNumber"
                label="Account Number"
                rules={{
                    required: { value: true, message: 'Account number is required' },
                    minLength: { value: 7, message: 'Account number must be at least 7 characters'},
                    maxLength: { value: 12, message: 'Account number must be at most 12 characters'},
                    validate: {
                        notOnlySpaces: (value: string) => /\S/.test(value) || 'Legal name cannot consist only of spaces',
                    },
                }}
                />
            </Grid>
        </Grid>
    );
}

export default FormBillingInput;