import React from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../../components/shared/types";
import TextField from '@material-ui/core/TextField';
import isEmail from "./isEmail";

interface EmailInputProps extends ControlledInputProps {
    rules?: any
}

const EmailInput = ({ name, control, defaultValue, rules, label, ...rest }: EmailInputProps) => {

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : ""}
            rules={Object.assign(rules ? rules : {}, { validate: {
                isEmail: isEmail
            }})}
            render={({field, fieldState}) =>        
                <TextField   
                    {...field}
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                        disableAnimation: true
                    }}
                />
            }
        />
    );
}

export default EmailInput;