import React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

type RemoveButtonProps = {
    handleRemove: (event: any) =>  void,
    text?: string,
    className?: string
}

const RemoveButton = (props: RemoveButtonProps) => {
    return(
        <button className={`button-material remove-button ${props.className}`} onClick={props.handleRemove}><HighlightOffIcon/>{props.text}</button>
    );
}

export default RemoveButton;