import React, { useState, useContext } from "react";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AuthContext } from "../../AuthProvider";
import Typography from '@material-ui/core/Typography';

const dangerzoneUIDs = ["easGw9uiYiTNSCSPQu5ghgrDXxz1", "bDp9OXDFCqSR9z6RS1xft8tn6WF2"]

interface DangerZoneProps {
    allowAnyUser?: boolean,
    label?: string
}

const DangerZone: React.FunctionComponent<DangerZoneProps> = ({ label, allowAnyUser, children }) => {

    const authContext = useContext(AuthContext);
    const [enableSubmit, setEnableSubmit] = useState(false);

    return (
        <>
        {(authContext.user?.uid && (allowAnyUser || dangerzoneUIDs.includes(authContext.user.uid))) &&
            <>
                <FormControlLabel
                    control={
                    <Checkbox   
                        checked={enableSubmit}
                        onChange={(e) => {setEnableSubmit(e.target.checked)}}
                        color="primary"
                    />
                    }
                    label= {label ? label : "I know what I'm doing"}
                />
                {enableSubmit && children}
            </>
        }
        {(!authContext.user?.uid || (!dangerzoneUIDs.includes(authContext.user.uid) && !allowAnyUser)) &&
            <>
                <Typography>Locked</Typography>
            </>
        }
        </>
    );
}

export default DangerZone;