import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PayrollRow, PayrollRows } from "./types";
import { parse } from "json2csv";
import { saveAs } from "file-saver";
import { ProcessState } from "@alethea-medical/react-components";

interface ExportCSVProps {
    fromDate: Date;
    toDate: Date;
    payrollRows: PayrollRows;
    loadingRecordsState: ProcessState;
}

const ExportCSV = ({
    fromDate,
    toDate,
    payrollRows,
    loadingRecordsState,
}: ExportCSVProps) => {
    const removeField = (
        fields: (keyof PayrollRow)[],
        fieldToRemove: keyof PayrollRow
    ) => {
        const index = fields.indexOf(fieldToRemove);
        if (index > -1) {
            fields.splice(index, 1);
        }
    };

    const formatDate = (date: Date): string => {
        return date
            .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
            })
            .toLowerCase()
            .replace(/ /g, "_");
    };

    const formatHeaderDate = (date: Date): string => {
        return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        });
    };

    const exportAsCSV = () => {
        if (Object.values(payrollRows).length > 0) {
            const fields = Object.keys(
                Object.values(payrollRows)[0]
            ) as (keyof PayrollRow)[];

            removeField(fields, "key");
            removeField(fields, "recordsToPay");

            let csv = parse(
                Object.values(payrollRows).sort((a, b) => {
                    return b.doctorName < a.doctorName ? 1 : -1;
                }),
                {
                    fields: fields,
                }
            );

            /* Create 2 rows with the structure
            | From: | <date> |
            | To: | <date> |

            then add the payroll stuff below it
            | <payroll stuff>... |
            */

            // Create header rows
            const headerRow = `From:,${formatHeaderDate(fromDate)}`;
            const headerRow2 = `To:,${formatHeaderDate(toDate)}`;
            csv = `${headerRow}\n${headerRow2}\n\n${csv}`;

            let fileName = `payroll_${formatDate(fromDate)}_to_${formatDate(
                toDate
            )}.csv`;

            // Create a blob of the data
            let fileToSave = new Blob([csv], {
                type: "text/plain;charset=utf-8",
            });
            saveAs(fileToSave, fileName);
        }
    };

    return (
        <div>
            <Tooltip title="Export as a .csv file">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<GetAppIcon />}
                    disabled={
                        Object.values(payrollRows).length === 0 ||
                        loadingRecordsState === ProcessState.running
                    }
                    onClick={exportAsCSV}
                >
                    Export
                </Button>
            </Tooltip>
        </div>
    );
};

export default ExportCSV;
