import React, { useContext } from "react";
import {Route, Switch } from "react-router-dom";
import BillingTable from "../views/Billing";
import Dashboard from "../views/Dashboard";
import EditUsers from "../views/User/EditUsers";
import CreateUser from "../views/User/CreateUser";
import EditSpecialists from "../views/EditSpecialists";
import EditSpecialties from "../views/EditSpecialties";
import QueueResend from "../views/QueueResend";
import Payroll from "../views/Payroll";
import { AuthContext } from "../AuthProvider";
import EditRoles from "../views/EditRoles";
import UserMetadata from "../views/UserMetadata";
import Reports from '../views/Reports';
import AutoPayments from "../views/AutoPayments";
import EditClinics from "../views/EditClinics";
import FcmCampaigns from "../views/FcmCampaigns/FcmCampaigns";

const DashboardRoutes = () => {
    const authContext = useContext(AuthContext);

    return (
        <Dashboard>
            <>
                {/* Switches needed in each section since it breaks when at the top level */}
                {authContext?.profile?.roles?.billingAdmin === true && 
                    <Switch>
                        <Route path="/dashboard/billing" component={BillingTable} />
                    </Switch>
                }
                {(authContext?.profile?.roles?.billingViewer === true || authContext?.profile?.roles?.billingAdmin === true) && 
                    <Switch>
                        <Route path="/dashboard/billing-summary" component={Payroll} />
                        <Route path="/dashboard/autopayments" component={AutoPayments} />
                        <Route path="/dashboard/reports" component={Reports} />
                    </Switch>
                }
                {authContext?.profile?.roles?.accountManager === true && 
                    <Switch>
                        <Route path="/dashboard/edit-users" component={EditUsers} />
                        <Route path="/dashboard/edit-roles" component={EditRoles} />
                        <Route path="/dashboard/create-user" component={CreateUser} />
                        <Route path="/dashboard/edit-specialists" component={EditSpecialists} />
                        <Route path="/dashboard/edit-specialties" component={EditSpecialties} />
                        <Route path="/dashboard/edit-clinics" component={EditClinics} />
                        <Route path="/dashboard/view-user-metadata" component={UserMetadata} />
                    </Switch> 
                }
                {authContext?.profile?.roles?.campaignAdmin === true && 
                    <Switch>
                        <Route path="/dashboard/notification-campaigns" component={FcmCampaigns} />
                    </Switch> 
                }
                {authContext?.profile?.roles?.operations === true && 
                    <Switch>
                        <Route path="/dashboard/queue-resend" component={QueueResend} />            
                    </Switch>
                }
            </>
        </Dashboard>
    );
}

export default DashboardRoutes;