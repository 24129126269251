import React, { useState, useContext, useEffect } from "react";
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../AuthProvider";
import { Layout } from "../../Global/components";
import auth from "../../../firebase/auth";
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import versionStrings from "../../../versionStrings";
import { projectEnv } from "../../../firebase";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        versionString: {
            marginTop: theme.spacing(2),
            color: theme.palette.primary.main,
            fontSize: "0.75em"
        },
        gridItem: {
            textAlign: 'center'
        },
    })
)
interface UserData {
    email: string;
    password: string;
}

const defaultPage = '/dashboard/billing';


const Login = ({location}: any) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [values, setValues] = useState<UserData>({
        email: "",
        password: ""
    } as UserData);

    useEffect(() => {
        auth.onAuthStateChanged((user: any) => {
            if(user !== null)
            {
                redirectToTargetPage();
            }
        });
    }, []);



    const redirectToTargetPage = () => {
        const state = location.state as any;
        if(state?.prevPath?.location?.pathname)
        {
            const targetPage = `${state.prevPath.location.pathname}${state.prevPath.location.search}`;            
            console.log(`Redirect to previous ${targetPage}`);
            history.push(targetPage);
        }
        else if(location.pathname !== "/auth/login") {
            const targetPage = `${location.pathname}${location.search}`;            
            console.log(`Redirect to target ${targetPage}`);

            history.push(targetPage);
        }
        else {
            console.log("Redirect to default")

            history.push(defaultPage);
        }
    }


    const handleChange = (event: any) => {
        event.persist();
        setValues(values => ({
            ...values, 
            [event.target.name]: event.target.value
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();

        auth.signInWithEmailAndPassword(values.email, values.password)
        .then((res: any) => {
            authContext.setUser(res);
            console.log(res, 'res')
            history.push("/dashboard/billing");
        })
        .catch((error: any) => {
            console.log(error.message);
            alert(error.message);
        });
    }

    return (
        // Wrap in layout component to render header and footer
        <Layout>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} className={classes.gridItem}>
                    <TextField variant="outlined" margin="dense" type="email" name="email" value={values.email} placeholder="Enter your Email" onChange={handleChange}/>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                    <TextField variant="outlined" margin="dense" type="password" name="password" value={values.password} placeholder="Enter your Password" onChange={handleChange}/>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                    <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={clsx(classes.versionString, classes.gridItem)}>
                        Version {versionStrings.version}{projectEnv !== "prod" ? `, Build ${versionStrings.devBuild}` : ""}
                    </Typography>
                </Grid>
                {projectEnv !== "prod" && (
                    <Grid item xs={12}>
                        <Typography className={clsx(classes.versionString, classes.gridItem)}>
                            Environment: {projectEnv.toUpperCase()}
                        </Typography>
                    </Grid>
                )}
            </Grid>

        </Layout>
    );
}

export default Login;