import {Typography, Grid, IconButton, Tooltip} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import app from '../../firebase';
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { useEffect, useState } from "react";
import MonthPicker from "../Payroll/MonthPicker";
import RefreshIcon from "@material-ui/icons/Refresh";
import { PaymentStatusReport } from '../../../shared/types';


import moment from "moment-timezone";

import PaymentStatus from './PaymentStatus';
import BillingInfoCheck from './BillingInfoCheck';
import Batches from "./Batches";
import Batch from "./Batch";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2)
        },
        paper: {
            margin: "2px",
            padding: theme.spacing(2),
            backgroundColor: "white",
            border: "1px solid " + theme.palette.grey[300],
            borderRadius: "4px",
        },
        title: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            margin: theme.spacing(2),
        },
    }), 
);

const AutoPayment = () => {

    const classes = useStyles();

    const getPaymentStatus = app.functions().httpsCallable("getPaymentStatus");
    const getPaymentBatches = app.functions().httpsCallable("getPaymentBatches");


    const [ fromDate, setFromDate ] = useState<Date>(moment(new Date()).startOf('month').toDate());
    const [ toDate, setToDate ] = useState<Date>(moment(new Date()).endOf('month').toDate());
    const [ paymentStatusReport, setPaymentStatusReport ] = useState<PaymentStatusReport | null>(null);
    const [ paymentBatches, setPaymentBatches ] = useState<any[]>([]);

    const [ successMessage, setSuccessMessage ] = useState("");
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const handleMonthChange = (from: Date, to: Date) => {
        setFromDate(from);
        setToDate(to);
    }

    const [ generateButtonDisabled, setGenerateButtonDisabled ] = useState<boolean>(true);
    const [ currentBatch, setCurrentBatch ] = useState<any>(null);
      
    const getPaymentStatusHandler = async() => {
        setPaymentStatusReport(null);
        await getPaymentStatus({dateFrom: fromDate.getTime(), dateTo: toDate.getTime()})
            .then(response => JSON.parse(response.data))
            .then(response => {
                setPaymentStatusReport(response);
            })
            .catch(error => {
                errorHandler({error});
            })
    }

    const loadPaymentBatches = async() => {
        await getPaymentBatches({dateTo: toDate.getTime()})
            .then(response => JSON.parse(response.data))
            .then(response => {
                setPaymentBatches(response);

                if (currentBatch) {
                    const batch = response.find((batch: any) => batch.batchNumber === currentBatch.batchNumber);
                    if (batch) {
                        setCurrentBatch(batch);
                    }else{
                        setCurrentBatch(null);
                    }
                }

            })
            .catch(error => {
                errorHandler({error});
            })
    }
        

    const refresh = async(message: string) => {
        reset();
        setProcessState(ProcessState.running);
        await loadPaymentBatches();
        await getPaymentStatusHandler();
        setSuccessMessage(message);
        setProcessState(ProcessState.success);
        setTimeout(() => {
            setProcessState(ProcessState.idle);        
        }, 2500)
    }

    const reset = async() => {
        setPaymentStatusReport(null);
        setPaymentBatches([]);
        setProcessState(ProcessState.idle);
    }

    useEffect(() => {
        reset();
    }, [fromDate, toDate])

    return (
        <div className={classes.root}>

                <Grid container justifyContent="space-between" spacing={3}>
                    <Grid item xs={6}>
                        <Typography variant="h4">RBC Auto Payment Manager</Typography>
                    </Grid>
                    
                    <Grid item xs={6} container justifyContent="flex-end">
                        <MonthPicker label="Payment Period" fromDate={fromDate} setMonth={handleMonthChange}/>  
                        <Tooltip title="Fetch data for the period">
                            <IconButton onClick={() => refresh("Batches loaded successfully")}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={successMessage} useSnackbar setState={setProcessState}/>
                    </Grid>
                </Grid>
            
            <Grid container justifyContent="space-between" >
                <Grid item xs={12}>
                    <div className={classes.paper}>
                        <PaymentStatus paymentStatusReport={paymentStatusReport}/>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.paper}>
                        <BillingInfoCheck setGenerateButtonDisabled={setGenerateButtonDisabled} dateFrom={fromDate} dateTo={toDate}/>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.paper}>                        
                        <Batches 
                            fromDate={fromDate}
                            toDate={toDate}
                            generateButtonDisabled={generateButtonDisabled}
                            setGenerateButtonDisabled={setGenerateButtonDisabled}
                            refresh={refresh}
                            paymentBatches={paymentBatches}
                            currentBatch={currentBatch}
                            setCurrentBatch={setCurrentBatch} 
                            />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {currentBatch && 
                        <Batch 
                            batch={currentBatch} 
                            classes = {classes}
                            refresh={refresh}
                            />
                    }

                </Grid>
            </Grid>

        </div>
    )
}

export default AutoPayment;