import { PaperModal } from '@alethea-medical/alethea-components';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import CampaignForm from './CampaignForm';
import { Fcm } from '@alethea-medical/aletheamd-types';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            maxHeight: "90vh",
            overflowY: "auto",
        }
    })
)
interface CreateCampaignProps {
    updateCampaign: (id: string, campaign: Partial<Fcm.Campaign>) => void
}

const CreateCampaign = ({ updateCampaign }: CreateCampaignProps) => {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button onClick={() => {setShowModal(true)}} variant="contained" color="primary" startIcon={<AddAlertIcon/>}>
                New Campaign
            </Button>  
            <PaperModal show={showModal} setShow={setShowModal} containerClassName={classes.modal} flexWidth flexHeight enablePadding>
                <Typography variant="h5">New Campaign</Typography>
                <CampaignForm mode="create" campaignId={undefined} campaign={undefined} updateCampaign={updateCampaign}/>
            </PaperModal>
        </>
    );
}

export default CreateCampaign;