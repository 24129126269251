import React from "react";
import { Col, Form, Card } from "react-bootstrap";

type SectionCardProps = {
    title: string,
    md?: string,
    children: JSX.Element[] | JSX.Element,
    className?: string
}

const FormCard = ({title, md, children, className}: SectionCardProps) => {
    
    const renderChildren = () => {
        if(Array.isArray(children)){
            return [...children]
        }
        else {
            return children
        }
    }
    return (
        <Form.Row className={className}>
            <Form.Group as={Col} md={md ? md : "12"} controlId={title}>
                <Card>
                    <Card.Body>
                        {title && <Form.Label><div className="text-muted">{title}</div></Form.Label>}
                        {renderChildren()}
                    </Card.Body>                
                </Card>
            </Form.Group>
        </Form.Row>
    );

}

export default FormCard;