import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { UserProfile } from '@alethea-medical/aletheamd-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            color: "white",
            padding: theme.spacing(0, 1),
            backgroundColor: theme.palette.primary.main
        }
    })
)
interface ClinicUserRowProps {
    userId: string,
    user: UserProfile,
    isMoa: boolean,
    isPhysician: boolean,
    physicianHandler: (uid: string, checked: boolean) => void,
    moaHandler: (uid: string, checked: boolean) => void
    removeUserHandler: (uid: string) => void
}

const ClinicUserRow = ({ userId, user, isPhysician, isMoa, physicianHandler, moaHandler, removeUserHandler }: ClinicUserRowProps) => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={1} alignItems="center" justifyContent='space-between'>
                <Grid item xs={3}>
                    <Typography>{user.firstName} {user.lastName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        label="Doctor"
                        onChange={(_, checked) => { physicianHandler(userId, checked)}}
                        checked={isPhysician}
                        control={
                            <Checkbox 
                                disableRipple  
                            />
                        }
                    />
        
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        label="MOA"
                        onChange={(_, checked) => { moaHandler(userId, checked)}}
                        checked={isMoa}
                        control={
                            <Checkbox 
                                disableRipple  
                            />
                        }
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => removeUserHandler(userId)}>
                        <RemoveCircleOutlineIcon style={{color: "white"}}/>
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>

    );
}

export default ClinicUserRow;