import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperPage, ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import app from '../../firebase';
import { RolePermissions } from '@alethea-medical/aletheamd-types';
import EditRoleModal from "./EditRoleModal";
import CreateRole from "./CreateRole";
import sortRolesInPlace from "../../shared/sortRolesInPlace";
import RoleRow from "./RoleRow";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
    }),
);


export interface RolePermissionsWithKey {
    id: string,
    data: RolePermissions
}

const EditRoles = () => {
    const classes = useStyles();

    const getDoc = app.functions().httpsCallable("portal-getDoc");
    
    const [roles, setRoles] = useState<RolePermissionsWithKey[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [roleToEdit, setRoleToEdit] = useState<RolePermissionsWithKey>();
    const [showEditor, setShowEditor] = useState(false);

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    useEffect(() => {
        setProcessState(ProcessState.running);
        getDoc({collection: dbNames.rolePermissions})
        .then((result) => {
            sortRolesInPlace(result.data)
            setRoles(result.data);
            setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error fetching roles"
            })
        })
    }, [])

    const startEditingHandler = (roleKey: string) => {
        setRoleToEdit(roles.find((r) => r.id === roleKey));
        setShowEditor(true);
    }

    const endEditHandler = () => {
        setRoleToEdit(undefined);
        setShowEditor(false);
    }

    const updateRole = (roleId: string, data: RolePermissions, addRole?: boolean) => {
        const newRoles = [...roles];

        //A new role was created, add to list
        if(addRole) {
            newRoles.push({
                id: roleId,
                data: data
            })
        }
        else {//Update pre-existing role in list
            const idx = roles.findIndex((r) => r.id === roleId);
            if(idx !== -1) {
                newRoles[idx] = {
                    id: roleId,
                    data: data
                }
            }
        }
        sortRolesInPlace(newRoles);
        setRoles(newRoles);
    }

    const handleSearch = (e: any) => {
        const searchStr = e.target.value
        setSearchTerm(searchStr)
    }

    const filterBySearchTerm = (role: RolePermissionsWithKey): boolean => {
        return role.data.name.toLowerCase().includes(searchTerm.toLowerCase())
    }
    
    //TODO: Add delete roles. Don't allow default roles to be deleted

    return (
        <>
            <PaperPage>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Edit Roles</Typography>
                        </Grid>          
                        <Grid item xs={12}>
                            <TextField
                                label="Search"
                                onChange={handleSearch}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CreateRole currentRoles={roles} updateRole={updateRole}/>
                        </Grid>
                        {roles.filter(filterBySearchTerm).map((role) => {
                            return (
                                <Grid item xs={12} key={`role_${role.id}`}>
                                    <RoleRow role={role} startEditingHandler={startEditingHandler}/>
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                        </Grid>
                    </Grid>
                </div>
            </PaperPage >
            <EditRoleModal show={showEditor} roleToEdit={roleToEdit} handleClose={endEditHandler} updateRole={updateRole}/>
        </>
    );
}

export default EditRoles;