import { ProcessStatus } from '@alethea-medical/alethea-components';
import Grid from '@material-ui/core/Grid';
import EditConsultantGroupsController from './EditConsultantGroupsController';
import EditGroup from './EditGroup';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const EditConsultantGroupsView = () => {
    
    const controller = EditConsultantGroupsController({})

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <Button variant="contained" onClick={controller.createGroupHandler} color="primary">Create New Group</Button>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Select Consultant Group"
                    select
                    value={controller.selectedGroup?.id ?? ""}
                    variant="outlined"
                    margin="dense"
                    fullWidth    
                    onChange={(e) => controller.onSelectGroup(e.target.value as string)}
                >
                    {controller.consultantGroups.map((group) => 
                        <MenuItem value={group.id} key={`group_select_${group.id}`}>{group.data.groupName}</MenuItem>
                    )}
                </TextField>
            </Grid>
            {controller.selectedGroup !== undefined && 
                <Grid item xs={12}>
                    <EditGroup group={controller.selectedGroup} refreshGroupDropdown={controller.refreshGroupDropdown}/>
                </Grid>
            }
            <Grid item xs={12}>
                <ProcessStatus state={controller.processState} errorMessage={controller.processErrorMessage} loadingMessage="Loading Consultant Groups..." successMessage='Consultant groups saved.'/>
            </Grid>
        </Grid>
    );
}

export default EditConsultantGroupsView;