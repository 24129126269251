import { 
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TableContainer,
    DialogActions,
    Button,
} from '@material-ui/core';
import { PaymentRecord } from '../../../../shared/types';
import { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DoneIcon from '@material-ui/icons/Done';
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import app from '../../../firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorSection: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2),
        },
        errorTable: {
            marginTop: theme.spacing(1),
        },
        paidIcon: {
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
        }
    })
);

interface PaymentBatchErrorsProps {
    errorRecords: PaymentRecord[];
    refresh: (message: string) => void,
    classes: any,
}

const PaymentBatchErrors = ({ 
    errorRecords,
    refresh,
    classes,
}: PaymentBatchErrorsProps) => {
    const localClasses = useStyles();

    const [ recordToPay, setRecordToPay ] = useState<PaymentRecord | null>(null);
    const [ showConfirmSubmit, setShowConfirmSubmit ] = useState<boolean>(false);

    const [successMessage, setSuccessMessage] = useState<string>("");
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const markAsPaid = app.functions().httpsCallable("markAsPaid");

    const handlePayRecord = (record: PaymentRecord) => {
        setRecordToPay(record);
        setShowConfirmSubmit(true);
    }

    const markRecordAsPaid = () => {

        setProcessState(ProcessState.running);
        updateRecordStatus().then(() => {
            setShowConfirmSubmit(false);
            setRecordToPay(null);
            refresh("Record paid successfully");

        }).catch((error) => {
            errorHandler(error);
            console.log(error)
        })
    }

    const updateRecordStatus = async () => {
        if (!recordToPay) return;

        console.log(await markAsPaid({recordToPay}));

        // // update status on frontend
        recordToPay.status = "paid";
    } 

    const getButton = (record: PaymentRecord) => {
        if (record.status === "paid") {
            return (
                <Tooltip title="Record paid" className={localClasses.paidIcon}>
                    <DoneIcon color="primary" />
                </Tooltip>
            )
        }

        if (record.status === "failed") {
            return (
                <Tooltip title="Pay record manually">
                    <IconButton onClick={() => handlePayRecord(record)}>
                        <AttachMoneyIcon color="primary" />
                    </IconButton>
                </Tooltip>
            )
        }
    }

    return (
        <>
            <div className={classes.paper}>

                <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={successMessage} useSnackbar setState={setProcessState}/>
                
                <Typography variant="h6" className={classes.title}>Payment Errors</Typography>

                {errorRecords.length === 0 && (
                    <Typography className={classes.title} variant="body1">No errors :)</Typography>
                )}

                {errorRecords.map((record, index) => (
                    <div key={index} className={localClasses.errorSection}>
                        <hr/>
                    
                        <Grid container justifyContent="space-between">
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">{record.legalName}</Typography>
                                <Typography variant="body2">{"Billing records: " + record.billingRecordIds.length}</Typography>
                                <Typography variant="body2">{"Amount due: $" + record.totalAmount.toFixed(2)}</Typography>
                            </Grid>
                            <Grid item xs={6} container justifyContent="flex-end">
                                {getButton(record)}
                            </Grid>
                        </Grid>


                        <Table size="small" className={localClasses.errorTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Error Code</TableCell>
                                    <TableCell align="right">Message</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {record.billingErrors && record.billingErrors.map(error => (
                                    <TableRow key={error.code}>
                                        <TableCell>{error.code}</TableCell>
                                        <TableCell align="right">{error.message}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                ))}
            </div>
            <Dialog 
                open={showConfirmSubmit}
                onClose={() => { setShowConfirmSubmit(false);}}
            >
                <DialogTitle id="alert-dialog-title">{`Mark ${recordToPay?.billingRecordIds.length} billing records as paid?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Mark the following records as paid
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow>
                                                <TableCell align={'left'} padding={'default'}>Billing ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {recordToPay?.billingRecordIds.map((uid) => {
                                        return (
                                            <TableRow
                                                key={`mark_paid_${uid}`}
                                                tabIndex={-1}
                                            >
                                                <TableCell className={classes.tableCell} align="left">{uid}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => { setShowConfirmSubmit(false);}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => {
                        setShowConfirmSubmit(false);
                        markRecordAsPaid();
                    }}>
                        Mark as Paid
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PaymentBatchErrors;