import React from 'react';
import { UserMetadataLoginData } from '@alethea-medical/aletheamd-types';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import parseJSONTimestamp from '../../helpers/parseJSONTimestamp';
import { UserMetadataItem } from './UserMetadataController';
import { firestore } from 'firebase';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        name: {
            color: theme.palette.primary.main,
            fontWeight: 500
        },
        item: {
            padding: theme.spacing(2),
            backgroundColor: "white"
        },
        timeBar: {
            margin: theme.spacing(1),
            borderRadius: "15px",
        }
    })
)

const red = "#f84c5a"
const yellow = "#f8d64c"
const green = "#68ff59"
const grey = "#777777"

interface UserMetadataPlatformProps {
    name: string, 
    platformData: UserMetadataLoginData | undefined
}

const dayInMilli = 
1000 * //1 second
60 * //1 minute
60 * //1 hour
24 //1 day
const oneWeekMillis = new Date().getTime() - dayInMilli * 7;
const oneMonthMillis = new Date().getTime() - dayInMilli * 30;

const UserMetadataPlatform = ({ name, platformData }: UserMetadataPlatformProps) => {
    const classes = useStyles();

    function getLoginAgeColor(lastLogin: firestore.Timestamp | undefined): string {
        const timestamp = parseJSONTimestamp(lastLogin)
        if(timestamp === undefined)
            return grey

        if(timestamp.toMillis() >= oneWeekMillis) {
            return green;
        }
        else if(timestamp.toMillis() < oneWeekMillis && timestamp.toMillis() >= oneMonthMillis) {
            return yellow;
        }
        else {
            return red;
        }
    }
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={4} style={{
                backgroundColor: getLoginAgeColor(platformData?.lastLogin)
            }} className={classes.timeBar}>
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Last Login</InputLabel>
            </Grid>
            <Grid item xs={6}>
                <Typography>{platformData?.lastLogin !== undefined ? parseJSONTimestamp(platformData.lastLogin)?.toDate().toLocaleString() :  "N/A"}</Typography>
            </Grid>
            <Grid item xs={6}>
                <InputLabel>Version</InputLabel>
            </Grid>
            <Grid item xs={6}>
                <Typography>{platformData?.version !== undefined ? platformData.version : "N/A"}</Typography>
            </Grid>
        </Grid>  
    );
}


interface UserMetadataItemProps {
    metadataItem: UserMetadataItem   
}

const UserMetadataListItem = ({ metadataItem }: UserMetadataItemProps) => {
    const classes = useStyles();
    return (
        <ListItem className={classes.item} divider>
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.name}>{metadataItem.userData.firstName} {metadataItem.userData.lastName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <UserMetadataPlatform name="Web" platformData={metadataItem.metadata?.web}/>
                </Grid>

                <Grid item xs={4}>
                    <UserMetadataPlatform name="iOS" platformData={metadataItem.metadata?.ios}/>
                </Grid>
                <Grid item xs={4}>
                    <UserMetadataPlatform name="Android" platformData={metadataItem.metadata?.android}/>
                </Grid>
            </Grid>
        </ListItem>
    );
}

export default UserMetadataListItem;