import React, { useEffect, useMemo, useState } from 'react';
import { PaperModal, useProcessState, ProcessState, ProcessStatus } from "@alethea-medical/alethea-components";
import { RolePermissionsWithKey } from './EditRoles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Control, FieldValues, useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import FormTextField from '../../components/FormTextField';
import { RolePermissions } from '@alethea-medical/aletheamd-types';
import FormTextArea from '../../components/FormTextArea';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { dbNames, resourceKeys } from '@alethea-medical/aletheamd-db-keys';
import app from '../../firebase';
import resourceKeyToText from "./resourceKeyToText";

interface EditRoleModalProps {
    show: boolean,
    roleToEdit: RolePermissionsWithKey | undefined,
    handleClose: () => void,
    updateRole: (roleId: string, data: RolePermissions) => void
}

const EditRoleModal = ({ show, roleToEdit, handleClose, updateRole }: EditRoleModalProps) => {
    
    const updateDoc = app.functions().httpsCallable("portal-updateDoc");

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const [permissions, setPermissions] = useState<string[]>([]);

    const getRoleValues = (data?: Partial<RolePermissions>) => {
        let roleData: Partial<RolePermissions> = {}
        if(data !== undefined)
            roleData = data;//Use provided data
        else if(roleToEdit !== undefined)
            roleData = roleToEdit.data;//Otherwise, use default user from props

        
        const newRoles: RolePermissions = {
            name: roleData?.name !== undefined ? roleData.name : "",
            description: roleData?.description !== undefined ? roleData.description : "",
            permissions: roleData?.permissions !== undefined ? roleData.permissions : []
        }

        //Using state here, since react hook form really doesn't like working with arrays that aren't made of objects
        setPermissions(newRoles.permissions);

        return newRoles;
    }

    const { handleSubmit, control, reset } = useForm({ mode: "onTouched", defaultValues: useMemo(getRoleValues, [roleToEdit]) });

    useEffect(() => {
        reset(getRoleValues(roleToEdit?.data));
    }, [roleToEdit]);



    const showHandler = (show: boolean) => {
        if(!show) {
            handleClose();
        }
    }

    const onSubmit = (data: any) => {
        if(roleToEdit !== undefined) {
            setProcessState(ProcessState.running);
            
            //Add permissions state to form data
            data.permissions = permissions;
    
            updateDoc({ collection: dbNames.rolePermissions, id: roleToEdit.id, data: data })
                .then(() => {
                    updateRole(roleToEdit.id, data);
                    setProcessState(ProcessState.success);
                    setTimeout(() => {
                        setProcessState(ProcessState.idle);
                    }, 1000)
                })
                .catch((error: Error) => {
                    errorHandler({
                        userMessage: error.message,
                        error: error
                    })
                })
        }
        else {
            errorHandler({
                userMessage: "An error occurred. Please refresh the page."
            })
        }
    }

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors."
        })
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    return (
        <PaperModal show={show} setShow={showHandler} enablePadding flexHeight>
            {roleToEdit !== undefined && (
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <fieldset disabled={isDisabled()} >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Editing: {roleToEdit.id}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField
                                    control={control as unknown as Control<FieldValues>}
                                    name="name"
                                    label="Name"
                                    rules={{ required: { value: true, message: "Name is required" } }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextArea
                                    control={control as unknown as Control<FieldValues>}
                                    name="description"
                                    label="Description"
                                    initRows={3}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Permissions
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    options={Object.values(resourceKeys)}
                                    value={permissions}
                                    getOptionLabel={(option) => resourceKeyToText(option)}
                                    filterSelectedOptions
                                    onChange={(_, newValues: string[]) => {
                                        setPermissions(newValues.sort((a, b) => {
                                            if(a < b)
                                                return -1;
                                            else if(a > b)
                                                return 1;
                                            return 0;
                                        }))
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Add a permission"
                                            variant="outlined"
                                            size="small"
                                        />
                                    }
                                    disabled={isDisabled()}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={isDisabled()} variant="contained" color="primary" type="submit">Save</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={"Role updated"}/>
                            </Grid>
                        </Grid>
                    </fieldset>
                </form>
            )}
        </PaperModal>
    );
}

export default EditRoleModal;