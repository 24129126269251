import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { ClinicItem, newClinicId } from "./EditClinicsController";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button';
import { Clinic, User, UserProfile } from '@alethea-medical/aletheamd-types';
import app from '../../firebase';
import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import { dbNames } from '@alethea-medical/aletheamd-db-keys';
import { useEffect, useState } from 'react';
import ClinicUserRow from './ClinicUserRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'
import InputLabel from '@material-ui/core/InputLabel';
import DangerZone from '../Utilities/DangerZone';

import useUserDropdownV2 from "../User/useUserDropdownV2";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1)
        },
        userItem: {
            marginLeft: theme.spacing(2)
        },
        userList: {
            maxHeight: "500px",
            overflowY: "auto"
        }
    })
)

type ListTypes = Pick<Clinic.Clinic, "users" | "moas" | "physicians">
type ListKeys = keyof ListTypes;

interface EditClinicProps {
    clinic: ClinicItem,
    refreshClinicDropdown: () => void
}

const EditClinic = ({ clinic, refreshClinicDropdown }: EditClinicProps) => {
    const classes = useStyles();
    const getDoc = app.functions().httpsCallable("portal-getDoc");
    const setDoc = app.functions().httpsCallable("portal-setDoc");
    const deleteDoc = app.functions().httpsCallable("portal-deleteDoc");

    const getUserProfile = (uid: string) => {
        return getDoc({collection: "users", id: uid})
        .then((result) => {
            return {
                uid,
                data: result.data
            }
        })
    }

    const getClinicUserProfiles = (uids: string[]) => {
        setProcessState(ProcessState.running);
        return Promise.all(uids.map((uid) => getUserProfile(uid)))
        .then((result) => {
            setProcessState(ProcessState.idle);
            setClinicUserProfiles(result)
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error getting user profile."
            })
        })
    }

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success
    }

    const [selectedClinic, setSelectedClinic] = useState<Clinic.Clinic>(clinic.data)
    useEffect(() => {
        setSelectedClinic(clinic.data)
    }, [clinic.data])

    const [ clinicUserProfiles, setClinicUserProfiles ] = useState<{uid: string, data: UserProfile}[]>([]);
    
    useEffect(() => {
        getClinicUserProfiles(selectedClinic.users)
    }, [selectedClinic.users])

    const { selectedUid, createDropdown} = useUserDropdownV2({disabledUids: selectedClinic.users});

   
    const checkData = (newClinic: Clinic.Clinic) => {
        // Check that users list contains all physicians and moas
        return newClinic.physicians.every((p) => newClinic.users.includes(p)) && newClinic.moas.every((m) => newClinic.users.includes(m))
    }

    const saveHandler = () => {
        setProcessState(ProcessState.running);
        if(!checkData(selectedClinic)) {
            errorHandler({
                userMessage: "Data formatted incorrectly due to an error in the page. Please refresh and try again."
            })
            return;
        }

        setDoc({ collection: dbNames.clinics, id: clinic.id === newClinicId ? undefined : clinic.id, data: selectedClinic })
        .then(() => {
            refreshClinicDropdown()
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 1000);        
        })
        .catch((error: Error) => {
            errorHandler( {
                error: error,
                userMessage: "Error saving clinic"
            })
        })
    }

    const deleteHandler = () => {
        if(!window.confirm(`Are you sure you want to delete clinic ${selectedClinic.clinicName}?`))
            return;

        setProcessState(ProcessState.running);
        if(clinic.id === newClinicId) {
            errorHandler({ userMessage: "Cannot delete new clinic that hasn't been saved yet"})
            return
        }

        deleteDoc({ collection: dbNames.clinics, id: clinic.id})
        .then(() => {
            refreshClinicDropdown()
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 1000);        
        })
        .catch((error: Error) => {
            errorHandler( {
                error: error,
                userMessage: "Error deleting clinic"
            })
        })
    }

    const handleClinicNameChanged = (clinicName: string) => {
        setSelectedClinic({
            ...selectedClinic,
            clinicName
        })
    }

    const handleAddToList = (uid: string | undefined, list: ListKeys) => {
        if(uid === undefined) return
        if(selectedClinic[list].indexOf(uid) !== -1) return

        setSelectedClinic({
            ...selectedClinic,
            [list]: [...selectedClinic[list], uid],
        })
    }

    const handleRemoveFromList = (uid: string, list: ListKeys) => {
        const newList = [...selectedClinic[list]]
        const idx = newList.indexOf(uid)
        if(idx === -1)
            return;
        
        newList.splice(idx, 1)
        setSelectedClinic({
            ...selectedClinic,
            [list]: newList
        })
    }

    const handlePhysician = (uid: string, checked: boolean) => {
        if(checked) {
            handleAddToList(uid, "physicians")
        }   
        else {
            handleRemoveFromList(uid, "physicians")
        }
    }

    const handleMoa = (uid: string, checked: boolean) => {
        if(checked) {
            handleAddToList(uid, "moas")
        }   
        else {
            handleRemoveFromList(uid, "moas")
        }
    }


    const handleRemoveFromUsers = (uid: string) => {
        const user = clinicUserProfiles.find((user) => user.uid === uid)

        if(!window.confirm(`Remove ${user?.data.firstName} ${user?.data.lastName} from clinic?`))
            return

        const newUsers = spliceFromList(uid, [...selectedClinic.users])
        const newPhysicians = spliceFromList(uid, [...selectedClinic.physicians])
        const newMoas = spliceFromList(uid, [...selectedClinic.moas])

        setSelectedClinic({
            ...selectedClinic,
            users: newUsers,
            physicians: newPhysicians,
            moas: newMoas
        })
    }

    const spliceFromList = (uid: string, list: string[]) => {
        const idx = list.indexOf(uid)
        if(idx !== -1)
            list.splice(idx, 1)
        return list
    }

    const sortUsersAlphabetically = (a: string, b: string): number => {
        const userA = clinicUserProfiles.find((user) => user.uid === a)
        const userB = clinicUserProfiles.find((user) => user.uid === b)
        return `${userA?.data?.firstName} ${userA?.data?.lastName}`.localeCompare(`${userB?.data?.firstName} ${userB?.data?.lastName}`)
    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Clinic Name"
                        value={selectedClinic.clinicName}
                        onChange={(e) => handleClinicNameChanged(e.target.value)}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>All MOAs in a clinic will be able to view any Consult sent to any Doctor in the same clinic</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Add User to Clinic</InputLabel>
                    {createDropdown()}
                </Grid>
                <Grid item>
                    <Button disabled={selectedUid === undefined } onClick={() => handleAddToList(selectedUid, "users")} 
                        variant="contained" color="primary"
                        startIcon={<AddIcon/>}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Users</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.userList}>
                        {selectedClinic.users.sort(sortUsersAlphabetically).map((userId) =>  {
                            const user = clinicUserProfiles.find((user) => user.uid === userId)
                            if(user === undefined)
                                return null
                            return (
                                <Grid className={classes.userItem} item xs={12} key={`triager_${userId}`}>
                                    <ClinicUserRow 
                                        userId={userId}
                                        user={user.data} 
                                        isPhysician={selectedClinic.physicians.includes(userId)}
                                        isMoa={selectedClinic.moas.includes(userId)}
                                        removeUserHandler={(uid) => handleRemoveFromUsers(uid)} 
                                        physicianHandler={handlePhysician} 
                                        moaHandler={handleMoa}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isDisabled()} variant="contained" color="primary" onClick={saveHandler} startIcon={<SaveIcon/>}>Save</Button>
                </Grid>
                    <Grid item xs={12}>
                        <Typography>Delete Clinic (Don't delete a clinic that has been used before unless you know what you're doing!).</Typography>
                        <DangerZone allowAnyUser>
                            <Button disabled={isDisabled()} variant="outlined" color="primary" onClick={deleteHandler} startIcon={<DeleteIcon/>}>Delete Clinic</Button>
                        </DangerZone>
                    </Grid>
                <Grid item xs={12}>
                    <ProcessStatus state={processState} errorMessage={processErrorMessage} loadingMessage="Saving Clinic" successMessage='Clinic saved.'/>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default EditClinic;