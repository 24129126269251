import React, { useMemo, useEffect } from 'react';
import { User } from '@alethea-medical/aletheamd-types';
import { ProcessStatus, ProcessState } from "@alethea-medical/react-components";
import { useForm } from 'react-hook-form';
import app from '../../../firebase';
import useProcessState from '../../../components/useProcessState';
import FormLoginInput from '../FormLoginInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

interface EditLoginProps {
    user: User,
    refreshCurrentUser: () => void
}

const EditLogin = ({ user, refreshCurrentUser }: EditLoginProps) => {

    //Set default values here
    const getLoginValues = () => {
        return {
            email: user.email
        }
    }
    
    const { handleSubmit, control, reset } = useForm({ mode: "onTouched", 
        defaultValues: useMemo(getLoginValues, [user]) 
    });

    //Add what default values should reset to here
    useEffect(() => {
        reset(getLoginValues());
    }, [user]);

    const updateUser = app.functions().httpsCallable("portal-updateUser");
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState();

    const onSubmit = (data: any) => {
        setProcessState(ProcessState.running);
        updateUser({ uid: user.uid, email: data.email, password: data.password })
        .then(() => {
            refreshCurrentUser();
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 1000)
        })
        .catch((error: Error) => {
            errorHandler({
                userMessage: error.message,
                error: error
            })
        })
    }
        
    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors."
        })
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <fieldset disabled={isDisabled()} >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormLoginInput control={control} showUnchangedPassword />
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={isDisabled()} variant="contained" color="primary" type="submit">Save</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={"Login information updated"}/>
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </>
    );
}

export default EditLogin;