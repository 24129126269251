import React, { useEffect, useState } from "react";
import {
    TableRow,
    TableCell,
    Menu,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatStatus } from "./billingFunctions";
import { headCells } from "./billingTableHead";
import {
    Status,
    OOPData,
    FrontendData,
    BillingRecord,
} from "../../../shared/types";
import {
    Collapse,
    IconButton,
    ButtonGroup,
    Select,
    MenuItem,
    Tooltip,
    Table,
    TableBody,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import BillingTableTextfield from "./billingTableTextfield";
import BillingTableOOPData from "./billingTableOOPData";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import PublishIcon from "@material-ui/icons/Publish";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CheckIcon from "@material-ui/icons/Check";
import { firestore } from "firebase";
import isPHN from "../../components/isPHN";
import { BillingCodesDict } from "../../components/useBillingCodes/getAvailableBillingCodes";
import { BillingCodeInfoV2 } from "../../../shared/types";

//Rows with these statuses can have editable fields edited.
const canEditStatuses: Status[] = [
    "new",
    "onHold",
    "closed",
    "refused",
    "notBillable",
    "deleted",
];
const canDeleteStatuses: Status[] = [
    "pending",
    "submitted",
    "accepted",
    "paid",
];
const canMoveToPaidStatuses: Status[] = ["accepted"];
const canMoveToAcceptedStatuses: Status[] = ["paid"];
const canEditManually: Status[] = [
    "new",
    "onHold",
    "closed",
    "refused",
    "notBillable",
    "deleted",
    "submitted",
    "accepted",
    "refused",
];

interface BillingRowProps {
    data: FrontendData;
    billingCodes: BillingCodesDict;
    billingCodesV2: BillingCodeInfoV2;
    handleUpdateRecord: (data: Partial<BillingRecord>, index: number) => void;
    reload: boolean;
}

const useStyles = makeStyles((theme) => ({
    statusCell: {
        textAlign: "center",
    },
    tableCell: {
        overflow: "auto",
    },
    subTable: {
        overflow: "hidden",
    },
}));

const BillingTableRow = ({
    data: frontendData,
    billingCodes,
    billingCodesV2,
    handleUpdateRecord,
    reload,
}: BillingRowProps) => {
    const classes = useStyles();
    const canEdit = canEditStatuses.indexOf(frontendData.data.status) !== -1;
    const canDelete =
        canDeleteStatuses.indexOf(frontendData.data.status) !== -1;
    const canMoveToPaid =
        canMoveToPaidStatuses.indexOf(frontendData.data.status) !== -1;
    const canMoveToAccepted =
        canMoveToAcceptedStatuses.indexOf(frontendData.data.status) !== -1;
    const [openMore, setOpenMore] = useState<boolean>(false);

    const isBillingCode = (billingCode: string) => {
        // Check if there is a match in billing codes v2
        if (!billingCodes[billingCode] && !billingCodesV2[billingCode]) {
            return "Billing code not found";
        }
    };

    const updateRecordStatus = (newStatus: Status) => {
        const updatedRecord: Partial<BillingRecord> = {
            status: newStatus,
        };

        if (newStatus === "submitted") {
            updatedRecord.submittedAt = firestore.Timestamp.now();
        }

        if (newStatus === "accepted") {
            updatedRecord.hlinkResultReceivedAt = firestore.Timestamp.now();
            updatedRecord.assessedAmt = frontendData.data.expectedAssessedAmt;
        }

        if (newStatus === "refused") {
            updatedRecord.hlinkResultReceivedAt = firestore.Timestamp.now();
        }

        if (newStatus === "deleted") {
            updatedRecord.hlinkResultReceivedAt = firestore.Timestamp.now();
        }

        if (newStatus === "paid") {
            updatedRecord.paidAt = firestore.Timestamp.now();
        }

        handleUpdateRecord(updatedRecord, frontendData.index);
    };

    useEffect(() => {
        setOpenMore(false);
    }, [reload]);

    const StatusButton = ({ status, data: frontendData, children }: any) => {
        return (
            <Tooltip
                title={`Move to ${formatStatus(status)}`}
                disableFocusListener
                open={frontendData.data.status === status ? false : undefined}
            >
                <IconButton
                    color="primary"
                    disabled={frontendData.data.status === status}
                    onClick={() => {
                        updateRecordStatus(status);
                    }}
                >
                    {children}
                </IconButton>
            </Tooltip>
        );
    };

    const StatusCell = (frontendData: FrontendData) => {
        //Only allow user to move rows to onHold and closed
        return (
            <>
                <div className={classes.statusCell}>
                    {formatStatus(frontendData.data.status)}
                </div>

                {frontendData.data.province === "BC" ? (
                    <>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                        >
                            <StatusButton
                                status="submitted"
                                data={frontendData}
                            >
                                <PublishIcon />
                            </StatusButton>
                            <StatusButton status="accepted" data={frontendData}>
                                <DoneIcon />
                            </StatusButton>
                            <StatusButton status="refused" data={frontendData}>
                                <CloseIcon />
                            </StatusButton>
                        </ButtonGroup>
                        <Box display="flex" justifyContent="center">
                            <Select label="Other options" value={"label"}>
                                <MenuItem value={"label"} disabled>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Other options
                                    </Typography>
                                </MenuItem>
                                <MenuItem>
                                    <StatusButton
                                        status="new"
                                        data={frontendData}
                                    >
                                        <FiberNewIcon />
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            New
                                        </Typography>
                                    </StatusButton>
                                </MenuItem>
                                <MenuItem>
                                    <StatusButton
                                        status="onHold"
                                        data={frontendData}
                                    >
                                        <LowPriorityIcon />
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            On Hold
                                        </Typography>
                                    </StatusButton>
                                </MenuItem>
                                <MenuItem>
                                    <StatusButton
                                        status="closed"
                                        data={frontendData}
                                    >
                                        <NotInterestedIcon />
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            Closed
                                        </Typography>
                                    </StatusButton>
                                </MenuItem>
                                <MenuItem>
                                    <StatusButton
                                        status="deleted"
                                        data={frontendData}
                                    >
                                        <DeleteIcon />
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            Deleted
                                        </Typography>
                                    </StatusButton>
                                </MenuItem>
                                <MenuItem>
                                    <StatusButton
                                        status="paid"
                                        data={frontendData}
                                    >
                                        <AttachMoneyIcon />
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            Paid
                                        </Typography>
                                    </StatusButton>
                                </MenuItem>
                            </Select>
                        </Box>
                    </>
                ) : (
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group"
                    >
                        {canEdit && (
                            <>
                                <StatusButton status="new" data={frontendData}>
                                    <FiberNewIcon />
                                </StatusButton>
                                <StatusButton
                                    status="onHold"
                                    data={frontendData}
                                >
                                    <LowPriorityIcon />
                                </StatusButton>
                                <StatusButton
                                    status="closed"
                                    data={frontendData}
                                >
                                    <NotInterestedIcon />
                                </StatusButton>
                            </>
                        )}
                        {canDelete && (
                            <Tooltip
                                title={
                                    frontendData.data.submissionOptions
                                        ?.markedForDeletion === true
                                        ? "Undo mark for deletion"
                                        : "Mark for deletion"
                                }
                                disableFocusListener
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        const updateObj = {
                                            submissionOptions:
                                                frontendData.data
                                                    .submissionOptions
                                                    ?.markedForDeletion === true
                                                    ? firestore.FieldValue.delete()
                                                    : {
                                                          markedForDeletion:
                                                              true,
                                                      },
                                        } as Partial<BillingRecord>;
                                        handleUpdateRecord(
                                            updateObj,
                                            frontendData.index
                                        );
                                    }}
                                >
                                    {frontendData.data.submissionOptions
                                        ?.markedForDeletion === true ? (
                                        <DeleteIcon />
                                    ) : (
                                        <DeleteOutlineIcon />
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                        {canMoveToPaid && (
                            <Tooltip
                                title={`Move to ${formatStatus("paid")}`}
                                disableFocusListener
                                open={
                                    frontendData.data.status === "paid"
                                        ? false
                                        : undefined
                                }
                            >
                                <IconButton
                                    color="primary"
                                    onClick={() => {
                                        const updateObj: Partial<BillingRecord> =
                                            {
                                                status: "paid",
                                                paidAt: firestore.Timestamp.now(),
                                            };
                                        handleUpdateRecord(
                                            updateObj,
                                            frontendData.index
                                        );
                                    }}
                                >
                                    <AttachMoneyIcon />{" "}
                                </IconButton>
                            </Tooltip>
                        )}
                        {canMoveToAccepted && (
                            <StatusButton status="accepted" data={frontendData}>
                                <CheckIcon />
                            </StatusButton>
                        )}
                    </ButtonGroup>
                )}
            </>
        );
    };

    return (
        <>
            <TableRow tabIndex={-1}>
                <TableCell className={classes.tableCell} align="left">
                    {StatusCell(frontendData)}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    {new Date(
                        frontendData.data.created.toMillis()
                    ).toLocaleString()}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    {frontendData.data.lastUpdatedAt
                        ? new Date(
                              frontendData.data.lastUpdatedAt.toMillis()
                          ).toLocaleString()
                        : "N/A"}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    {frontendData.data.submittedAt
                        ? new Date(
                              frontendData.data.submittedAt.toMillis()
                          ).toLocaleString()
                        : "N/A"}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <BillingTableTextfield
                        placeholder="PHN"
                        initialText={frontendData.data.patientId}
                        handleUpdateText={(phn: string) => {
                            handleUpdateRecord(
                                {
                                    patientId: phn,
                                },
                                frontendData.index
                            );
                        }}
                        disabled={!canEdit}
                        validation={(text: string) => {
                            return (
                                frontendData.data.oop === true ||
                                isPHN(text, frontendData.data.province) ===
                                    undefined
                            );
                        }}
                        helperTextFn={(text: string) => {
                            return isPHN(text, frontendData.data.province);
                        }}
                        enterBlurs={true}
                        multiline={false}
                    />
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    align="left"
                >{`${frontendData.data.firstName} ${frontendData.data.lastName}`}</TableCell>
                <TableCell className={classes.tableCell} align="left">
                    {frontendData.data.type}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    {frontendData.data.subType}
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <BillingTableTextfield
                        placeholder="Billing code"
                        initialText={frontendData.data.billingCode}
                        handleUpdateText={(billingCode: string) => {
                            handleUpdateRecord(
                                {
                                    billingCode: billingCode,
                                },
                                frontendData.index
                            );
                        }}
                        disabled={!canEdit}
                        // TODO: Rules for billing codes? For now we know "X1" is a code so... anything goes I guess
                        validation={(text: string) => {
                            console.log(
                                `validating bill code (${text}), result: `,
                                isBillingCode(text) === undefined
                            );
                            return isBillingCode(text) === undefined;
                        }}
                        helperTextFn={(text: string) => {
                            console.log(
                                " -- helper text --, result: " +
                                    isBillingCode(text)
                            );
                            return isBillingCode(text);
                        }}
                        enterBlurs={true}
                        multiline={false}
                    />
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenMore(!openMore)}
                    >
                        {openMore ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                        {openMore ? (
                            <Typography variant="subtitle1">Hide</Typography>
                        ) : (
                            <Typography variant="subtitle1">Show</Typography>
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={headCells.length}
                >
                    <Collapse in={openMore} timeout="auto" unmountOnExit>
                        <Grid container>
                            <Grid item xs={6}>
                                <Table
                                    className={classes.subTable}
                                    aria-labelledby="tableTitle"
                                    size={"small"}
                                    stickyHeader
                                >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Province
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.province
                                                    ? frontendData.data.province
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Referring Province
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data
                                                    .referringProvince
                                                    ? frontendData.data
                                                          .referringProvince
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Specialist Province
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data
                                                    .specialistProvince
                                                    ? frontendData.data
                                                          .specialistProvince
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Claim Number
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.claimNum
                                                    ? frontendData.data.claimNum
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Batch Number
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.batchNum
                                                    ? frontendData.data.batchNum
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Submission Options
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data
                                                    .submissionOptions
                                                    ? JSON.stringify(
                                                          frontendData.data
                                                              .submissionOptions
                                                      )
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Action Code
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.actionCode
                                                    ? frontendData.data
                                                          .actionCode
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Expected Amount
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data
                                                    .expectedAssessedAmt
                                                    ? frontendData.data
                                                          .expectedAssessedAmt
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Pay to Doctor
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.payToDoctor
                                                    ? frontendData.data
                                                          .payToDoctor
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Assessed Amount
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.assessedAmt
                                                    ? frontendData.data
                                                          .assessedAmt
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Error
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.error &&
                                                frontendData.data.errorCode &&
                                                frontendData.data.error !==
                                                    "" &&
                                                frontendData.data.errorCode !==
                                                    ""
                                                    ? `${frontendData.data.errorCode}: ${frontendData.data.error}`
                                                    : "No errors"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Physician ID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <BillingTableTextfield
                                                    placeholder="Physician ID"
                                                    initialText={
                                                        frontendData.data
                                                            .referringPracId
                                                    }
                                                    handleUpdateText={(
                                                        referringPracId: string
                                                    ) => {
                                                        handleUpdateRecord(
                                                            {
                                                                referringPracId:
                                                                    referringPracId,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                    validation={(
                                                        text: string
                                                    ) => {
                                                        const pracIdPat =
                                                            /^\d{9}$/g;
                                                        return pracIdPat.test(
                                                            text
                                                        );
                                                    }}
                                                    helperText="Must be a 9 digit number"
                                                    enterBlurs={true}
                                                    multiline={false}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Specialist ID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <BillingTableTextfield
                                                    placeholder="Specialist ID"
                                                    initialText={
                                                        frontendData.data
                                                            .specialistPracId
                                                    }
                                                    handleUpdateText={(
                                                        specialistPracId: string
                                                    ) => {
                                                        handleUpdateRecord(
                                                            {
                                                                specialistPracId:
                                                                    specialistPracId,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                    validation={(
                                                        text: string
                                                    ) => {
                                                        console.log(
                                                            "validating spec ID"
                                                        );
                                                        if (text.length > 0) {
                                                            const pracIdPat =
                                                                /^\d{9}$/g;
                                                            return pracIdPat.test(
                                                                text
                                                            );
                                                        }
                                                        return true;
                                                    }}
                                                    helperText="Must be a 9 digit number"
                                                    enterBlurs={true}
                                                    multiline={false}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Out of Province
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <Checkbox
                                                    checked={
                                                        frontendData.data.oop
                                                            ? frontendData.data
                                                                  .oop
                                                            : false
                                                    }
                                                    color="primary"
                                                    onChange={(event: any) => {
                                                        handleUpdateRecord(
                                                            {
                                                                oop: event
                                                                    .target
                                                                    .checked,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Collapse
                                                    in={frontendData.data.oop}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <BillingTableOOPData
                                                        data={frontendData}
                                                        handleUpdateOOPData={(
                                                            oopData: OOPData,
                                                            index: number
                                                        ) => {
                                                            handleUpdateRecord(
                                                                {
                                                                    oopData:
                                                                        oopData,
                                                                },
                                                                index
                                                            );
                                                        }}
                                                        canEdit={canEdit}
                                                    />
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={6}>
                                <Table
                                    className={classes.subTable}
                                    aria-labelledby="tableTitle"
                                    size={"small"}
                                    stickyHeader
                                >
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Skill Code
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <BillingTableTextfield
                                                    placeholder="Skill Code"
                                                    initialText={
                                                        frontendData.data
                                                            .skillCode
                                                    }
                                                    handleUpdateText={(
                                                        skillCode: string
                                                    ) => {
                                                        handleUpdateRecord(
                                                            {
                                                                skillCode:
                                                                    skillCode,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                    multiline={false}
                                                    enterBlurs={true}
                                                />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Record ID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.uid}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                GP UID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.gpUid}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Specialist UID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.specialistUid
                                                    ? frontendData.data
                                                          .specialistUid
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Activity ID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data.activityId
                                                    ? frontendData.data
                                                          .activityId
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Patient Message ID
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                {frontendData.data
                                                    .patientMessageId
                                                    ? frontendData.data
                                                          .patientMessageId
                                                    : "N/A"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                DX Code
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <BillingTableTextfield
                                                    placeholder="Skill Code"
                                                    initialText={
                                                        frontendData.data.dxCode
                                                    }
                                                    handleUpdateText={(
                                                        dxCode: string
                                                    ) => {
                                                        handleUpdateRecord(
                                                            {
                                                                dxCode: dxCode,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                    multiline={false}
                                                    enterBlurs={true}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                BAN
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <BillingTableTextfield
                                                    placeholder="BAN"
                                                    initialText={
                                                        frontendData.data.ban
                                                    }
                                                    handleUpdateText={(
                                                        ban: string
                                                    ) => {
                                                        handleUpdateRecord(
                                                            {
                                                                ban: ban,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    disabled={!canEdit}
                                                    validation={(
                                                        text: string
                                                    ) => {
                                                        const banPat =
                                                            /^\d{7}$/g;
                                                        return banPat.test(
                                                            text
                                                        );
                                                    }}
                                                    helperText="Must be a 7 digit number"
                                                    enterBlurs={true}
                                                    multiline={false}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                                component="th"
                                                scope="row"
                                            >
                                                Comments
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                align="left"
                                            >
                                                <BillingTableTextfield
                                                    placeholder="Comments"
                                                    initialText={
                                                        frontendData.data
                                                            .comments
                                                    }
                                                    handleUpdateText={(
                                                        comments: string
                                                    ) => {
                                                        handleUpdateRecord(
                                                            {
                                                                comments:
                                                                    comments,
                                                            },
                                                            frontendData.index
                                                        );
                                                    }}
                                                    multiline={true}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default BillingTableRow;
