import { ProcessStatus, PaperPage } from '@alethea-medical/alethea-components';
import Grid from '@material-ui/core/Grid';
import EditClinicsController from './EditClinicsController';
import EditClinic from './EditClinic';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
    })
)

const EditClinicsView = () => {
    const classes = useStyles();
    const controller = EditClinicsController()

    return (
        <PaperPage>
            <div className={classes.root}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={controller.createClinicHandler} color="primary">Create New Clinic</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Select Clinic"
                            select
                            value={controller.selectedClinic?.id ?? ""}
                            variant="outlined"
                            margin="dense"
                            fullWidth    
                            onChange={(e) => controller.onSelectClinic(e.target.value as string)}
                        >
                            {controller.clinics.map((clinic) => 
                                <MenuItem value={clinic.id} key={`clinic_select_${clinic.id}`}>{clinic.data.clinicName}</MenuItem>
                            )}
                        </TextField>
                    </Grid>
                    {controller.selectedClinic !== undefined && 
                        <Grid item xs={12}>
                            <EditClinic clinic={controller.selectedClinic} refreshClinicDropdown={controller.refreshClinicDropdown}/>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <ProcessStatus state={controller.processState} errorMessage={controller.processErrorMessage} loadingMessage="Loading Clinics..." successMessage='Clinics saved.'/>
                    </Grid>
                </Grid>
            </div>
        </PaperPage>
    );
}

export default EditClinicsView;