import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { UserMetadata, UserProfile } from "@alethea-medical/aletheamd-types";
import { firestore } from "firebase";
import app from "../../firebase";
import compareUserNamesWithId from "../../helpers/compareUserNamesWithId";
import parseJSONTimestamp from "../../helpers/parseJSONTimestamp";
import { LoginPlatform, UserMetadataItem } from "./UserMetadataController";

const UserMetadataModel = () => {
    

    const getDoc = app.functions().httpsCallable("portal-getDoc");


    function getMostRecentPlatformLoginTimestamp(item: UserMetadataItem): firestore.Timestamp {
        const web = getMetadataPlatformLoginTimestamp(item, "web")
        const ios = getMetadataPlatformLoginTimestamp(item, "ios")
        const android = getMetadataPlatformLoginTimestamp(item, "android")
        if(web.toMillis() > ios.toMillis() && web.toMillis() > android.toMillis())
            return web;
        else if(ios.toMillis() > web.toMillis() && ios.toMillis() > android.toMillis())
            return ios;
        else
            return android;
    }
    function getMetadataPlatformLoginTimestamp(item: UserMetadataItem, platform: LoginPlatform): firestore.Timestamp {
        if(item.metadata === undefined)
            return firestore.Timestamp.fromMillis(0);

        const t = item.metadata[platform]?.lastLogin
        if(t === undefined)
            return firestore.Timestamp.fromMillis(0);

        const timestamp = parseJSONTimestamp(item.metadata[platform].lastLogin)
        if(timestamp === undefined)
            return firestore.Timestamp.fromMillis(0);

        return timestamp;
    }

    function getUserMetadata(): Promise<UserMetadataItem[]> {
        const list: UserMetadataItem[] = []
        return getDoc({
            collection: dbNames.users
        })
        .then((userResult) => {
            const users = userResult.data as { id: string, data: UserProfile }[];

            return getDoc({
                collection: dbNames.userMetadata,
            })
            .then((metadataResult) => {
                const metadata = metadataResult.data as { id: string, data: UserMetadata }[];

                users.forEach((user) => {
                    list.push({
                        id: user.id,
                        userData: user.data,
                        metadata: metadata.find((m) => m.id === user.id)?.data
                    })
                })
                list.sort((a, b) => {
                    //Sort by most recent login time first
                    if(a.metadata !== undefined && b.metadata === undefined) {
                        return -1;
                    }
                    else if(a.metadata === undefined && b.metadata !== undefined) {
                        return 1;
                    }
                    else if(a.metadata !== undefined && b.metadata !== undefined) {
                        const aTimestamp = getMostRecentPlatformLoginTimestamp(a)
                        const bTimestamp = getMostRecentPlatformLoginTimestamp(b)
                        return aTimestamp.toMillis() < bTimestamp.toMillis() ? 1 : -1
                        //fall through to compare names
                    }
                    //Then sort by name if both a and b have no metadata
                    return compareUserNamesWithId({id: a.id, data: a.userData}, {id: b.id, data: b.userData})
                });
                return list;
            })
        })
    }
    

    function getUserMetadataByLastLogin(userMetadata: UserMetadataItem[], platform: LoginPlatform, from: firestore.Timestamp, to: firestore.Timestamp): UserMetadataItem[] {
        return userMetadata.filter((item) => {

            const timestamp = getMetadataPlatformLoginTimestamp(item, platform)
            if(timestamp  === undefined)
                return false;

            return timestamp.toMillis() >= from.toMillis() && timestamp.toMillis() <= to.toMillis();
        }).sort((a, b) => {
            if(a.metadata !== undefined && b.metadata === undefined) {
                return -1;
            }
            else if(a.metadata === undefined && b.metadata !== undefined) {
                return 1;
            }
            else if(a.metadata !== undefined && b.metadata !== undefined) {
                //Sort by specified platform login time 
                const aTimestamp = getMetadataPlatformLoginTimestamp(a, platform)
                const bTimestamp = getMetadataPlatformLoginTimestamp(b, platform)
                return aTimestamp.toMillis() < bTimestamp.toMillis() ? 1 : -1
                //fall through to compare names
            }
            return compareUserNamesWithId({id: a.id, data: a.userData}, {id: b.id, data: b.userData})
        })
    }

    return {
        getUserMetadata,
        getUserMetadataByLastLogin,
    }

}

export default UserMetadataModel;
