import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperPage } from "@alethea-medical/alethea-components";
import { useState } from 'react';
import EditDxCodes from './EditDxCodes';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from '../../components/TabPanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        form: {
            width: '100%',
        },
        formItem: {
            marginTop: theme.spacing(2),
        },
        dangerButton: {
            backgroundColor: theme.palette.error.main,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.error.dark,
            },
        },
        tabPanel: {
            padding: theme.spacing(2),
        }
    })
);



const EditSpecialties = () => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);

    return (
        <PaperPage>
            <div className={classes.root}>
               <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Edit Specialties</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs value={tab} onChange={(e, newValue) => { setTab(newValue)}} variant="fullWidth">
                            <Tab label="Dx Codes"/>
                        </Tabs>
                        <TabPanel className={classes.tabPanel} value={tab} index={0}>
                            <EditDxCodes classes={classes}/>
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        </PaperPage>
    );
}

export default EditSpecialties;