import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";

const resourceKeyToTextDict = {
    [resourceKeys.aiPredictions]: "AI Predictions",
    [resourceKeys.addUserToActivity]: "Add User to Activity",
    [resourceKeys.billing]: "Billing",
    [resourceKeys.billAudiogram]: "Bill Audiogram",
    [resourceKeys.billSinusEndoscopy]: "Bill Sinus Endoscopy",
    [resourceKeys.billingCodeCustomization]: "Billing Code Customization",
    [resourceKeys.billingReport]: "Billing Report",
    [resourceKeys.cameraAndGallery]: "Camera and Gallery",
    [resourceKeys.consultSearchBar]: "Consult Search Bar",
    [resourceKeys.createAveiroCharge]: "Create Aveiro Charge",
    [resourceKeys.dentistOutcomes]: "Dentist Outcomes",
    [resourceKeys.dentalRouting]: "Dental Routing",
    [resourceKeys.editActivityStatus]: "Edit Activity Status",
    [resourceKeys.econsultAndSecureMessaging]: "Econsult and Secure Messaging",
    [resourceKeys.econsultOutcomes]: "Econsult Outcomes",
    [resourceKeys.forms]: "Forms",
    [resourceKeys.getAveiroItems]: "Get Aveiro Items",
    [resourceKeys.imageAnnotation]: "Image Annotation",
    [resourceKeys.longTermCareRouting]: "Long Term Care Routing",
    [resourceKeys.mdRouting]: "MD Routing",
    [resourceKeys.moaConsultInbox]: "MOA Consult Inbox",
    [resourceKeys.moaMessaging]: "MOA Messaging",
    [resourceKeys.oneWayMessaging]: "One Way Messaging",
    [resourceKeys.patientCC]: "Patient CC",
    [resourceKeys.phoneConsult]: "Phone Consult",
    [resourceKeys.phnLookup]: "PHN Lookup",
    [resourceKeys.pracIdValidationRequiredPage]: "Prac ID Validation Required",
    [resourceKeys.secureMessagingSendMessages]:
        "Secure Messaging Send Messages",
    [resourceKeys.specialistTemplates]: "Specialist Templates",
    [resourceKeys.specialistScheduleEditing]: "Specialist Schedule Editing",
    [resourceKeys.readActivityPayments]: "Read Activity Payments",
    [resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic]:
        "Send Message on Behalf of Physician or Clinic",
    [resourceKeys.forwardEconsult]: "Forward Econsult",
    [resourceKeys.approveEconsult]: "Approve Econsult",
    [resourceKeys.premiumForms]: "Premium Forms",
    [resourceKeys.premiumOneWayMessaging]: "Premium One-Way Messaging",
};

/**
 * Used to format resource keys into user friendly strings
 * For display purposes only
 * Falls back to resource key if there is no entry
 * @param resourceKey Key of the resource
 * @returns User-friendly string
 */
function resourceKeyToText(resourceKey: string): string {
    const text = resourceKeyToTextDict[resourceKey];
    return text !== undefined ? text : resourceKey;
}

export default resourceKeyToText;

