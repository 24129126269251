import React from "react";
import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PayrollRow } from "./types";
import Tooltip from '@material-ui/core/Tooltip';
import MarkAsPaidButton from "./MarkAsPaidButton";
import IconButton from '@material-ui/core/IconButton';
import PaymentIcon from '@material-ui/icons/Payment';

interface PayrollTableRowsProps {
    row: PayrollRow,
    fromDate: Date,
    province: string,
    refresh: () => void
}


const useStyles = makeStyles((theme) => ({
    statusCell: {
        textAlign: "center"
    },
    tableCell: {
        overflow: 'auto',
    },
    subTable: {
        overflow: 'hidden',
    },
}));

const PayrollTableRow = ({ row, fromDate, province, refresh }: PayrollTableRowsProps) => {
    const classes = useStyles();    

    return (
        <>
            <TableRow
                tabIndex={-1}
            >
                <TableCell className={classes.tableCell} align="center">
                    <Tooltip title={"View this doctor's accepted, refused, and paid records for this month in billing."}>
                        <IconButton
                            target="_blank"
                            href={`/dashboard/billing?fetchByPayroll=1&month=${fromDate.getMonth()}&year=${fromDate.getFullYear()}&doctorName=${row.doctorName}&province=${province}`}
                            rel="noopener noreferrer"
                        >
                            <PaymentIcon color="primary"/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{row.doctorName}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalEconsults}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalEconsultRejections}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalCommunications}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalCommunicationRejections}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalBilling.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalExpectedBilling.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalOwedToDoctor.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalPaidToDoctor.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalAmountPending.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalAmountSubmitted.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{row.totalAmountFailed.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="center"><MarkAsPaidButton row={row} refresh={refresh}/></TableCell>
            </TableRow>
        </>
    );
}

export default PayrollTableRow;