import React from "react";
import Button from "@material-ui/core/Button";
import app from "../../../firebase";
import DangerZone from "../../Utilities/DangerZone";
import Grid from "@material-ui/core/Grid";

interface ResetAvgResponseTimeButtonProps {
    selectedUserId: string;
    avgResponseTime: number | null; // Pass the avgResponseTime state
    setAvgResponseTime: React.Dispatch<React.SetStateAction<number | null>>;
}

const ResetAvgResponseTimeButton: React.FC<ResetAvgResponseTimeButtonProps> = ({
    selectedUserId,
    avgResponseTime,
    setAvgResponseTime,
}) => {
    const document = app.functions().httpsCallable("portalV2-document");
    const query = app.functions().httpsCallable("portalV2-query");

    // Resets the average response time
    const resetAvgResponseTime = () => {
        const specialistDocPath = `specialists/${selectedUserId}`;
        document({
            path: specialistDocPath,
            operation: "update",
            fieldsToDelete: ["avgResponseTime", "pastResponseTimes"],
        })
            .then(() => {
                console.log("Average response time reset");
                setAvgResponseTime(null);
            })
            .catch((error) => {
                console.error("Error resetting average response time", error);
            });

        // For each service where their users_id array contains the selectedUserId, update the econsultResponseTime to -1
        const options = {
            path: "services",
            where: {
                field: "serviceOwnerUid",
                operator: "==",
                value: selectedUserId,
            },
        };

        query(options)
            .then((response) => {
                const documents = response.data;
                documents.forEach((doc: any) => {
                    const docPath = `services/${doc.id}`;
                    const updateOptions = {
                        path: docPath,
                        operation: "update",
                        data: { econsultResponseTime: -1 },
                    };

                    document(updateOptions).catch((error) => {
                        console.error(
                            `Error updating document ${doc.id}: `,
                            error
                        );
                    });
                });
            })
            .catch((error) => {
                console.error("Error querying documents: ", error);
            });
    };

    // Formats the average response time
    const formatTime = (milliseconds: number | null) => {
        if (milliseconds == null) return "No responses yet";

        const years = Math.floor(milliseconds / (3600 * 24 * 365 * 1000));
        milliseconds -= years * 3600 * 24 * 365 * 1000;
        const months = Math.floor(milliseconds / (3600 * 24 * 30 * 1000));
        milliseconds -= months * 3600 * 24 * 30 * 1000;
        const weeks = Math.floor(milliseconds / (3600 * 24 * 7 * 1000));
        milliseconds -= weeks * 3600 * 24 * 7 * 1000;
        const days = Math.floor(milliseconds / (3600 * 24 * 1000));
        milliseconds -= days * 3600 * 24 * 1000;
        const hrs = Math.floor(milliseconds / (3600 * 1000));
        milliseconds -= hrs * 3600 * 1000;
        const mnts = Math.floor(milliseconds / (60 * 1000));
        milliseconds -= mnts * 60 * 1000;

        let result = "";
        if (years > 0) result += `${years} years, `;
        if (months > 0 || years > 0) result += `${months} months, `;
        if (weeks > 0 || months > 0 || years > 0) result += `${weeks} weeks, `;
        if (days > 0 || weeks > 0 || months > 0 || years > 0)
            result += `${days} days, `;
        if (hrs > 0 || days > 0 || weeks > 0 || months > 0 || years > 0)
            result += `${hrs} hours, `;
        result += `${mnts} minutes`;

        return result;
    };

    return (
        <DangerZone
            allowAnyUser={true}
            label={`I want to reset the Average Response Time for this specialist. This action cannot be undone.`}
        >
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={resetAvgResponseTime}
                >
                    Reset Time (Current Average Response Time is:{" "}
                    {formatTime(avgResponseTime)})
                </Button>
            </Grid>
        </DangerZone>
    );
};

export default ResetAvgResponseTimeButton;

