import { useState } from "react"
import FormNumberField from "../../../components/FormNumberField";
import { Control } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { periodToUnderscore } from "./locationBillingFunctions";
import { BillingCodeInfoEntry, Province } from "../../../../shared/types";
import useBillingCodes from "../../../components/useBillingCodes";

interface LocationBillingRowProps {
    control: Control,
    index: number,
    codeInfo: BillingCodeInfoEntry
}

const LocationBillingRow = ({ control, index, codeInfo }: LocationBillingRowProps) => {

    return (
        <>
            <Grid container>                
                <Grid item xs={12}>
                    <Typography>{codeInfo.code}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">{codeInfo.description}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Expected amount from HLink - {codeInfo.expectedAssessmentAmt}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormNumberField
                        // Replace . with _ so it can be used as a react hook form variable
                        name={`locations.${index}.billing.${periodToUnderscore(codeInfo.code)}.amount`}
                        {...{placeholder: `Default - ${codeInfo.payToDoctor}`}}
                        control={control}
                    />
                </Grid>
            </Grid>
        </>
    )
}

interface LocationBillingProps {
    control: Control,
    province: Province
    index: number,
}

const LocationBilling = ({ control, province, index }: LocationBillingProps) => {
    
    const [show, setShow] = useState(false)
    const { billingCodes } = useBillingCodes({ province })

    return (
        <Grid container spacing={1} alignItems='center'>
            <Grid item xs={"auto"}>
                <Typography>Custom Amount to Pay Doctor</Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={() => {setShow(!show)}}>{show ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</IconButton>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={show}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">(Leave blank to use default value)</Typography>
                        </Grid>
                        {Object.keys(billingCodes).sort().map((code) => 
                            <Grid item xs={12} key={`location_billing_${code}_${province}`}>
                                <LocationBillingRow control={control} index={index} codeInfo={billingCodes[code]}/>
                            </Grid>
                        )}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
}

export default LocationBilling