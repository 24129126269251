import React from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface FormCheckboxProps extends ControlledInputProps {

}

const FormCheckbox = ({ name, control, label, defaultValue, ...rest }: FormCheckboxProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue !== undefined ? defaultValue : false}
            render={({field}) =>
                <FormControlLabel
                    label={label}
                    {...field}
                    checked={field.value === true}
                    control={
                        <Checkbox 
                            {...rest}
                            disableRipple  
                        />
                    }
                />
            }
        />
    );
}

export default FormCheckbox;