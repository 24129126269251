import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { PaperPage } from '@alethea-medical/alethea-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import UserMetadataController, { LoginPlatform } from './UserMetadataController';
import DatePicker from '../Billing/DatePicker';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import UserMetadataListItem from './UserMetadataItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        datePicker: {
            paddingRight: theme.spacing(2)
        }   
    })
)

const UserMetadata = () => {
    const classes = useStyles();
    

    const { 
        userMetadataList,
        mode, setMode,
        dateFrom, dateTo, dateFromChangedHandler, dateToChangedHandler, 
        platform, setPlatform,
        usernameFilter, setUsernameFilter,
        createUserMetadataProcessState } = UserMetadataController();

    return (
        <>
            <PaperPage>
                <Grid container alignItems="center" className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Login Stats</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {createUserMetadataProcessState()}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={usernameFilter} onChange={(e) => { setUsernameFilter(e.target.value as string)}} variant="outlined" margin="dense" label="Filter by Name"/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel label="Filter by Date" control={
                            <Checkbox
                                value={mode === "lastLogin"}
                                onChange={(e) => {
                                    setMode(e.target.checked ? "lastLogin" : "all")
                                }}
                            />
                        }/>
                       </Grid>
                    <Grid item xs={12}>
                        <Collapse in={mode === "lastLogin"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DatePicker fromDate={dateFrom} toDate={dateTo} handleDateFromChange={dateFromChangedHandler} handleDateToChange={dateToChangedHandler}/>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">Platform</Typography>
                                </Grid>
                                <Grid item>
                                    <Select 
                                        value={platform}
                                        onChange={(e) => {
                                            setPlatform(e.target.value as LoginPlatform)
                                        }}
                                    >
                                        <MenuItem value="web">Web</MenuItem>
                                        <MenuItem value="ios">iOS</MenuItem>
                                        <MenuItem value="android">Android</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12}>
                        <Tooltip title={
                            <>
                                Colours indicate when user last logged in
                                <ul>
                                    <li>Green: Less than 7 days</li>
                                    <li>Yellow: 7 to 30 days</li>
                                    <li>Red: More than 30 days</li>
                                    <li>Grey: Never</li>
                                </ul>
                            </>
                        }>
                            <HelpIcon color="primary"/>
                        </Tooltip>

                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {userMetadataList.filter((item) => 
                                usernameFilter === "" || `${item.userData.firstName} ${item.userData.lastName}`.toLowerCase().includes(usernameFilter.toLocaleLowerCase()
                            )).map((item) => 
                                <React.Fragment key={`metadata_${item.id}`}>
                                    <UserMetadataListItem metadataItem={item}/>
                                </React.Fragment>
                            )}
                        </List>
                    </Grid>
                </Grid>

            </PaperPage>
        </>
    );
}

export default UserMetadata;    