import React from "react";
import { Controller } from "react-hook-form";
import  { ControlledInputProps } from "../shared/types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import sharedStyles from "../shared/sharedStyles";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>{
    return {
        ...sharedStyles(theme)
    }
});


interface FormSelectProps extends ControlledInputProps {
    options: any[],
    getOptionLabel?: (option: any) => string,
    getOptionValue?: (option: any) => any,
    disableWhenOne?: boolean,//Disable the field when only one option is available
    rules?: any,
    disabled?: boolean
}

const FormSelect = ({ name, control, options, getOptionLabel, getOptionValue, disableWhenOne, defaultValue, label, rules, disabled, ...rest }: FormSelectProps) => {
    const classes = useStyles();
    
    const tryGetOptionValue = (option: any) => {
        if(option !== undefined)
            return getOptionValue ? getOptionValue(option) : option
        else
            return ''
    }


    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ? defaultValue : tryGetOptionValue(options[0])}
            rules={rules}
            render={({field, fieldState}) => 
                <TextField 
                    {...field}
                    {...rest}
                    className={classes.canDisable}
                    disabled={(disableWhenOne && options.length <= 1) || disabled}
                    select
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={fieldState.error?.message}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                >
                    {options.map((option: any, index: number) => {
                        return <MenuItem key={`${option}_${index}`} value={tryGetOptionValue(option)}>{getOptionLabel ? getOptionLabel(option) : option}</MenuItem>
                    })}
                </TextField>
            }
        />
    );
}


export default FormSelect;