import { useState } from "react";
import { firestore } from "firebase";
import { useForm, useFieldArray } from "react-hook-form";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";

import FormTextField from "../../../components/FormTextField";
import FormProfileInput from "../FormProfileInput";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import { UserProfile } from "@alethea-medical/aletheamd-types";
import { PaperPage } from "@alethea-medical/react-components";

import app from "../../../firebase";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import FormLoginInput from "../FormLoginInput";
import FormClinicInput from "../Clinics/FormClinicInput";
import useProcessState from "../../../components/useProcessState";
import parseLocationBilling from "../Clinics/locationBillingFunctions";
import { defaultRoles } from "@alethea-medical/aletheamd-db-keys";
import FormRoleInput, { RoleAsObject } from "../FormRoleInput";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
        },
    })
);

const CreateUser = () => {
    const classes = useStyles();
    const createUser = app.functions().httpsCallable("portal-createUser");
    const setDoc = app.functions().httpsCallable("portal-setDoc");

    const { handleSubmit, control, setValue } = useForm({ mode: "onTouched" });
    const {
        fields: locations,
        append,
        remove,
    } = useFieldArray({ control, name: "locations", shouldUnregister: true });
    const {
        fields: userRoles,
        append: appendRoles,
        remove: removeRoles,
    } = useFieldArray({
        control,
        name: "userRolesObject",
        shouldUnregister: true,
    });

    const [showUid, setShowUid] = useState(false);
    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState();

    // onSubmit
    const onSubmit = (data: any) => {
        // If MD Specialist or Specialist role is selected, skill code can't be empty
        if (
            data.userRolesObject.some((r: RoleAsObject) =>
                [defaultRoles.specialist, defaultRoles.mdSpecialist].includes(
                    r.roleId
                )
            ) &&
            data.skillCode === ""
        ) {
            if (data.skillCode === "") {
                errorHandler({
                    userMessage:
                        "Skill code is required for specialists (user has Specialist role)",
                });
                return;
            }
        }

        if (data.locations.length === 0) {
            errorHandler({
                userMessage: "Add at least one clinic",
            });
            return;
        }

        setProcessState(ProcessState.running);

        //Get userRoles array out of the userRolesObject array
        data.userRoles = data.userRolesObject.map(
            (r: RoleAsObject) => r.roleId
        );

        //New User Profile
        const newProfile: UserProfile = {
            ban: data.ban,
            firstName: data.firstName,
            lastName: data.lastName,
            signupDate: firestore.Timestamp.now(),
            practiceId: data.practiceId,
            locations: data.locations.map(parseLocationBilling),
            billingPreferences: data.billingPreferences,
            preferences: data.preferences,
            userRoles: data.userRoles,
            //Keep resident for backwards compatibility in mobile app
            roles: {
                resident:
                    data.userRoles !== undefined
                        ? data.userRoles.includes(defaultRoles.resident)
                        : false,
            },
        };

        createUser({
            email: data.email,
            password: data.password,
            uid: data.uid,
        })
            .then((res: any) => {
                // Pass signupDate as key to be converted to timestamp (timestamp is set to raw JSON when sent through backend)
                return setDoc({
                    collection: "users",
                    id: res.data.uid,
                    data: newProfile,
                    timestampKeys: ["signupDate"],
                });
            })
            .then((res: any) => {
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                    window.location.reload();
                }, 1000);
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: error.message,
                });
            });
    };

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors.",
        });
    };

    const isDisabled = () => {
        return (
            processState === ProcessState.running ||
            processState === ProcessState.success
        );
    };

    return (
        <PaperPage>
            <div className={classes.root}>
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <fieldset disabled={isDisabled()}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5">
                                    Create User
                                </Typography>
                            </Grid>

                            {/* Login info */}
                            <Grid item xs={12}>
                                <FormLoginInput control={control} />
                            </Grid>

                            {/* UID */}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showUid}
                                            onChange={(event: any) => {
                                                setShowUid(
                                                    event.target.checked
                                                );
                                            }}
                                        />
                                    }
                                    label="Override UID (Don't check this unless you know what you're doing!)"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {showUid && (
                                    <FormTextField
                                        control={control}
                                        name="uid"
                                        label="UID (optional)"
                                    />
                                )}
                            </Grid>

                            {/*Profile*/}
                            <Grid item xs={12}>
                                <FormProfileInput
                                    control={control}
                                    setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormClinicInput
                                    control={control}
                                    locations={locations}
                                    append={append}
                                    remove={remove}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Force type because we don't have typing on this react hook form */}
                                <FormRoleInput
                                    control={control}
                                    userRoles={
                                        userRoles as unknown as RoleAsObject[]
                                    }
                                    append={appendRoles}
                                    remove={removeRoles}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    disabled={isDisabled()}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Create user
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <ProcessStatus
                                    state={processState}
                                    errorMessage={processErrorMessage}
                                    successMessage="User created"
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </form>
            </div>
        </PaperPage>
    );
};

export default CreateUser;
