import { Specialist, UserProfile } from "@alethea-medical/aletheamd-types";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormTextField from "../../components/FormTextField";
import app from "../../firebase";
import {
    FormCheckbox,
    NoReadWriteTextField,
} from "@alethea-medical/react-components";
import {
    ProcessStatus,
    useProcessState,
    ProcessState,
} from "@alethea-medical/alethea-components";
import EditSpecialty from "./EditSpecialty";
import { ConsultantGroupItem } from "./ConsultantGroups/EditConsultantGroupsController";
import SaveIcon from "@material-ui/icons/Save";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import MuiAlert from "@material-ui/lab/Alert";
import ResetAvgResponseTimeButton from "./SpecialistComponents/ResetAvgResponseTime";
import AcceptanceFlags from "./SpecialistComponents/AcceptanceFlags";
import CollegeFlags from "./SpecialistComponents/CollegeFlags";
import { Tooltip } from "@material-ui/core";

function validateSpecialties(
    specialties: Specialist.SpecialtyDict
): string | undefined {
    for (const specialty in specialties) {
        if (specialty === "") return `Specialty cannot be empty.`;
        for (const subsite in specialties[specialty]) {
            if (subsite === "")
                return `Subsite in specialty "${specialty}" cannot be empty.`;
        }
    }
    return undefined;
}

interface EditSpecialistFormProps {
    selectedUserId: string;
    specialist: Specialist.Profile | undefined;
    selectedUserProfile: UserProfile | undefined;
}

const EditSpecialistForm = ({
    selectedUserId,
    specialist,
    selectedUserProfile,
}: EditSpecialistFormProps) => {
    const setDoc = app.functions().httpsCallable("portal-setDoc");
    const updateDoc = app.functions().httpsCallable("portal-updateDoc");
    const getDoc = app.functions().httpsCallable("portal-getDoc");
    const getUser = app.functions().httpsCallable("portal-getUser");

    const document = app.functions().httpsCallable("portalV2-document");
    const [avgResponseTime, setAvgResponseTime] = useState<number | null>(null);

    const [specialties, setSpecialties] = useState<Specialist.SpecialtyDict>(
        specialist?.specialties ?? {}
    );

    const getSpecialistValues = (data?: Partial<Specialist.Profile>) => {
        const newSpecialist: Partial<Specialist.Profile> = {
            disabled: data?.disabled === true,
            enableEconsultDataCollection:
                data?.enableEconsultDataCollection === true,
            enablePhoneConsult: data?.enablePhoneConsult === true,
            skillCode:
                data?.skillCode ??
                (selectedUserProfile ? selectedUserProfile.skillCode : ""),
            specialties: data?.specialties ?? {},
            acceptanceFlags: {
                acceptAll: data?.acceptanceFlags?.acceptAll ?? false,
                acceptExisting: data?.acceptanceFlags?.acceptExisting ?? true,
                acceptReferral: data?.acceptanceFlags?.acceptReferral ?? false,
            },
            college: data?.college ?? "CCFP", // Default value set to CCFP
        };

        return newSpecialist;
    };

    const formMethods = useForm({
        mode: "onTouched",
        defaultValues: useMemo(
            () => getSpecialistValues(specialist),
            [specialist]
        ),
    });

    const handleSubmit = formMethods.handleSubmit;
    const control = formMethods.control;
    const reset = formMethods.reset;

    useEffect(() => {
        setSpecialties(specialist?.specialties ?? {});
        reset(getSpecialistValues(specialist));
    }, [specialist]);

    useEffect(() => {
        if (selectedUserId) {
            document({
                operation: "get",
                path: `specialists/${selectedUserId}`,
            })
                .then((response) => {
                    if (response.data) {
                        setAvgResponseTime(response.data.avgResponseTime);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedUserId]);

    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState({});

    const onSubmit = (data: any) => {
        setProcessState(ProcessState.running);
        const errorMessage = validateSpecialties(specialties);

        if (errorMessage) {
            errorHandler({ userMessage: errorMessage });
            return;
        }

        const newSpecialist = getSpecialistValues({
            ...data,
            specialties: specialties,
        });

        let updatePromise: Promise<any>;
        if (specialist)
            updatePromise = updateDoc({
                collection: "specialists",
                id: selectedUserId,
                data: newSpecialist,
            });
        else
            updatePromise = setDoc({
                collection: "specialists",
                id: selectedUserId,
                data: newSpecialist,
            });
        return updatePromise
            .then(() => {
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 1000);
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: "Error saving specialist",
                });
            });
    };

    const onError = () => {
        errorHandler({ userMessage: "Check form for errors." });
        setProcessState(ProcessState.error);
    };

    const isDisabled = () => {
        return (
            processState === ProcessState.running ||
            processState === ProcessState.success
        );
    };

    const isSkillCode = (value: string): string | undefined => {
        return /[A-Z\s]{4}/.test(value)
            ? undefined
            : "Skill code must be 4 characters long. Characters must be capital letters or spaces.";
    };

    const [consultantGroup, setConsultantGroup] =
        useState<ConsultantGroupItem>();
    const {
        processState: consultantGroupState,
        setProcessState: setConsultantGroupState,
        processErrorMessage: consultantGroupError,
        errorHandler: consultantGroupErrorHandler,
    } = useProcessState({});
    useEffect(() => {
        setConsultantGroupState(ProcessState.running);
        getDoc({
            collection: dbNames.consultantGroups,
            where: {
                field: "members",
                operator: "array-contains",
                value: selectedUserId,
            },
        })
            .then((result) => {
                setConsultantGroupState(ProcessState.idle);
                if (result.data.length > 0) setConsultantGroup(result.data[0]);
            })
            .catch((error: Error) => {
                consultantGroupErrorHandler({
                    error: error,
                    userMessage: "Error retrieving consultant group status",
                });
            });
    }, [selectedUserId]);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit, onError)}>
                <fieldset disabled={isDisabled()}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} md={6}>
                            {consultantGroupState === ProcessState.idle && (
                                <>
                                    {consultantGroup !== undefined ? (
                                        <NoReadWriteTextField
                                            label={"Consultant Group"}
                                            value={
                                                consultantGroup.data.groupName
                                            }
                                        />
                                    ) : (
                                        <MuiAlert severity="warning">
                                            User is not in a consultant group.
                                            User will not show in specialist
                                            dropdown unless they are part of a
                                            consultant group.
                                        </MuiAlert>
                                    )}
                                </>
                            )}
                            <ProcessStatus
                                state={consultantGroupState}
                                errorMessage={consultantGroupError}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NoReadWriteTextField
                                label={"UID"}
                                value={selectedUserId}
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <FormCheckbox
                                control={control}
                                name="disabled"
                                label="Disabled"
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <FormCheckbox
                                control={control}
                                name="enableEconsultDataCollection"
                                label="Enable eConsult Data Collection"
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <FormCheckbox
                                control={control}
                                name="enablePhoneConsult"
                                label="Enable for Phone Consults"
                            />
                        </Grid>
                        <Tooltip
                            title="Skill code is now maintained in the user profile. See 'Edit Users'"
                            placement="bottom-start"
                        >
                            <Grid item xs={12} lg={6}>
                                <FormTextField
                                    control={control}
                                    name="skillCode"
                                    label="Skill Code"
                                    rules={{
                                        required: {
                                            value: true,
                                            message:
                                                "Skill Code is required (Add it in the user profile under Edit User or Create User)",
                                        },
                                    }}
                                    disabled
                                />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={12}>
                            <AcceptanceFlags />
                        </Grid>
                        <Grid item xs={12}>
                            <CollegeFlags />
                        </Grid>
                        <Grid item xs={12}>
                            <ResetAvgResponseTimeButton
                                selectedUserId={selectedUserId}
                                avgResponseTime={avgResponseTime}
                                setAvgResponseTime={setAvgResponseTime}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EditSpecialty
                                specialties={specialties}
                                setSpecialties={setSpecialties}
                                isDisabled={isDisabled()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                disabled={isDisabled()}
                                variant="contained"
                                color="primary"
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus
                                state={processState}
                                errorMessage={processErrorMessage}
                                successMessage={
                                    "Specialist updated successfully"
                                }
                            />
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </FormProvider>
    );
};

export default EditSpecialistForm;

