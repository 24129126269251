import { Fcm } from "@alethea-medical/aletheamd-types";
import { useEffect, useState } from "react";
import app from "../../firebase";
import parseJSONTimestamp from "../../helpers/parseJSONTimestamp";
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";


interface CampaignDict {
    [key: string]: Fcm.Campaign
}

export interface CampaignItem {
    id: string,
    data: Fcm.Campaign
}

export const rowsPerPageOptions = [10, 25, 50, 100]

const GetCampaigns = () => {
    
    const fcmCampaign = app.functions().httpsCallable('portalV2-fcmCampaign');
    const [campaigns, setCampaigns] = useState<CampaignDict>({});
    const [sortedCampaigns, setSortedCampaigns] = useState<CampaignItem[]>([])
    const [cursors, setCursors] = useState<(string | undefined)[]>([undefined])
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [numPerPage, setNumPerPage] = useState<number>(rowsPerPageOptions[0])
    const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);

    
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ });

    useEffect(() => {
        // Initial load or when num per page changes
        refresh()
    }, [numPerPage])
    
    useEffect(() => {
        // Sort campaigns by created date to show in list
        setSortedCampaigns(Object.entries(campaigns).map(([key, campaign]) => ({ id: key, data: campaign})).sort((a, b) => b.data.created.toMillis() - a.data.created.toMillis()))
    }, [campaigns])

    const refresh = () => {
        setCampaigns({})
        setPageIndex(0)
        setCursors([undefined])
        loadCampaigns(undefined, "next")
    }

    const nextPage = () => {
        if(pageIndex >= cursors.length - 1)
            return;
        setPageIndex(pageIndex + 1)
        loadCampaigns(cursors[pageIndex + 1], "next")
    }

    const prevPage = () => {
        if(pageIndex === 0)
            return;
        setPageIndex(pageIndex - 1)
        loadCampaigns(cursors[pageIndex - 1], "prev")
    }

    const loadCampaigns = (fetchAfterId: string | undefined, direction: "next" | "prev") => {
        setProcessState(ProcessState.running)
        setDisableLoadMore(true)
        
        fcmCampaign({ method: "POST", options: { fetchAfterId, limit: numPerPage }})
        .then((result) => {
            let lastId = ""
            let lastCreated: number | undefined = undefined
            Object.keys(result.data).map((id) => {
                result.data[id].created = parseJSONTimestamp(result.data[id].created);
                if(result.data[id].sentAt)
                    result.data[id].sentAt = parseJSONTimestamp(result.data[id].sentAt);

                if(lastCreated === undefined) {
                    lastCreated = result.data[id].created.toMillis()
                    lastId = id
                }
                else if(result.data[id].created.toMillis() < lastCreated) {
                    lastCreated = result.data[id].created.toMillis()
                    lastId = id
                }
            })

            // Add last ID of the campaigns to cursor list
            if(direction === "next" && lastId !== "")
                setCursors([...cursors, lastId])
            if(direction === "prev" && lastId !== "") {
                // Remove the last two cursors (one for the page we just left, and one for the current page)
                const newCursors = cursors.slice(0, cursors.length - 2)
                // Then add the new last ID of the list to the current page. 
                // Normally this does nothing, but if the list changed since we last loaded the page, then we get the new correct cursor value.
                setCursors([...newCursors, lastId])
            }
            
            setDisableLoadMore(Object.keys(result.data).length < numPerPage)
            setCampaigns(result.data);
            setProcessState(ProcessState.idle)
        })
        .catch((error: Error) => {
            errorHandler({
                error,
                userMessage: "Error getting campaigns"
            })
            setDisableLoadMore(false)

        })
        
    }

    const updateCampaign = (id: string, campaign?: Partial<Fcm.Campaign>) => {
        if(campaign) {
            if(campaign.created)
                campaign.created = parseJSONTimestamp(campaign.created);
            if(campaign.sentAt)
                campaign.sentAt = parseJSONTimestamp(campaign.sentAt);
            const existing = campaigns[id] ?? {}
            setCampaigns({...campaigns, [id]: {...existing, ...campaign}})
        }
        else {
            // Delete
            const newCampaigns = {...campaigns}
            delete newCampaigns[id]
            setCampaigns(newCampaigns)
        }
    }

    return {
        campaigns,
        sortedCampaigns,
        updateCampaign,
        numPerPage, setNumPerPage,
        pageIndex,
        nextPage, prevPage,
        refresh,
        disableLoadMoreLoading: disableLoadMore && processState === ProcessState.running, 
        disableLoadMoreEndOfResults: disableLoadMore && processState !== ProcessState.running,
        renderGetState: () => (
            <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
        )
    }
}

export default GetCampaigns;