import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ApplicationRoutes from "./config/ApplicationRoutes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { baseTheme, Root } from "@alethea-medical/react-components";
import ServiceWorkerWrapper from "./config/ServiceWorkerWrapper";
import moment from "moment";
import defaultTimezoneString from "./helpers/defaultTimezoneString";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Globally set the default time zone in admin portal to America/Edmonton time for consistency in reporting numbers
moment.tz.setDefault(defaultTimezoneString);

const theme = createTheme({
    ...baseTheme,
    typography: {
        ...baseTheme.typography,
        h5: {
            //   color: "#999"
        },
        subtitle1: {
            color: "#888",
            fontSize: "1.0em",
        },
    },
});

function App() {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
                <ServiceWorkerWrapper />
                <CssBaseline />
                <Root>
                    <ApplicationRoutes />
                </Root>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;
