import { Location, LocationBilling } from "@alethea-medical/aletheamd-types";

function parseLocationBilling(location: Location)  {

    if(location.billing !== undefined) {
        // Remove entries in location.billing that are blank
        const billing = location.billing
        const newBilling: LocationBilling = {}
        Object.keys(billing).forEach((billingCode) => {
            if((billing[billingCode].amount as unknown as string) !== "") {
                //Replace _ with . to turn react hook form variable back into billing code
                newBilling[underscoreToPeriod(billingCode)] = {
                    amount: parseFloat((billing[billingCode].amount as unknown) as string)
                }
            }
        })
        location.billing = newBilling
    }

    // Delete field if it has no entries
    if(location.billing !== undefined && Object.keys(location.billing).length === 0) {
        delete location.billing
    }
     
    return location;
}

export function periodToUnderscore(code: string) {
    return code.replace(/\./, "_")
}
export function underscoreToPeriod(code: string) {
    return code.replace(/\_/, ".")
}

export default parseLocationBilling;