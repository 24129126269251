import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { ConsultantGroupItem, newGroupId } from "./EditConsultantGroupsController";
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import useUserDropdownV2 from '../../User/useUserDropdownV2';
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button';
import { Specialist, UserProfile } from '@alethea-medical/aletheamd-types';
import app from '../../../firebase';
import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import { dbNames, resourceKeys } from '@alethea-medical/aletheamd-db-keys';
import { useEffect, useState } from 'react';
import UserRow from './UserRow';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import resourceKeyToText from '../../EditRoles/resourceKeyToText';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1)
        },
        userItem: {
            marginLeft: theme.spacing(2)
        },
        userList: {
            maxHeight: "500px",
            overflowY: "auto"
        }
    })
)

type ListTypes = Pick<Specialist.ConsultantGroup, "members" | "consultants" | "triagers">
type ListKeys = keyof ListTypes;

interface EditGroupProps {
    group: ConsultantGroupItem,
    refreshGroupDropdown: () => void
}

const EditGroup = ({ group, refreshGroupDropdown }: EditGroupProps) => {
    const classes = useStyles();
    const getDoc = app.functions().httpsCallable("portal-getDoc");
    const setDoc = app.functions().httpsCallable("portal-setDoc");
    const deleteDoc = app.functions().httpsCallable("portal-deleteDoc");

    const getUserProfile = (uid: string) => {
        return getDoc({collection: "users", id: uid})
        .then((result) => {
            return {
                uid,
                data: result.data
            }
        })
    }

    const getGroupUserProfiles = (uids: string[]) => {
        setProcessState(ProcessState.running);
        return Promise.all(uids.map((uid) => getUserProfile(uid)))
        .then((result) => {
            setProcessState(ProcessState.idle);
            setGroupUserProfiles(result)
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error getting user profile."
            })
        })
    }

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success
    }

    const [consultantGroup, setConsultantGroup] = useState<Specialist.ConsultantGroup>(group.data)
    useEffect(() => {
        setConsultantGroup(group.data)
    }, [group.data])

    const [groupUserProfiles, setGroupUserProfiles] = useState<{uid: string, data: UserProfile}[]>([])
    useEffect(() => {
        getGroupUserProfiles(consultantGroup.members)
    }, [consultantGroup.members])

    const { selectedUid, createDropdown} = useUserDropdownV2({disabledUids: consultantGroup.members});

    const checkData = (newGroup: Specialist.ConsultantGroup) => {
        // Check that members list contains all consultants and triagers
        return newGroup.consultants.every((c) => newGroup.members.includes(c)) && newGroup.triagers.every((t) => newGroup.members.includes(t))
    }

    const saveHandler = () => {
        setProcessState(ProcessState.running);
        if(!checkData(consultantGroup)) {
            errorHandler({
                userMessage: "Data formatted incorrectly due to an error in the page. Please refresh and try again."
            })
            return;
        }

        setDoc({ collection: dbNames.consultantGroups, id: group.id === newGroupId ? undefined : group.id, data: consultantGroup })
        .then(() => {
            refreshGroupDropdown()
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 1000);        
        })
        .catch((error: Error) => {
            errorHandler( {
                error: error,
                userMessage: "Error saving consultant group"
            })
        })
    }

    const deleteHandler = () => {
        if(!window.confirm(`Are you sure you want to delete group ${consultantGroup.groupName}?`))
            return;

        setProcessState(ProcessState.running);
        if(group.id === newGroupId) {
            errorHandler({ userMessage: "Cannot delete new group that hasn't been saved yet"})
            return
        }

        deleteDoc({ collection: dbNames.consultantGroups, id: group.id})
        .then(() => {
            refreshGroupDropdown()
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 1000);        
        })
        .catch((error: Error) => {
            errorHandler( {
                error: error,
                userMessage: "Error deleting consultant group"
            })
        })
    }

    const handleGroupNameChanged = (groupName: string) => {
        setConsultantGroup({
            ...consultantGroup,
            groupName
        })
    }

    const handleRequiredResourcePermissionsChanged = (newResourceKey: string) => {
        const newConsultantGroup = {...consultantGroup}
        // If user selected none, then delete the field
        if(newResourceKey === "")
            delete newConsultantGroup.requiredResourcePermissions
        // Otherwise, set the field to the new value
        else
            newConsultantGroup.requiredResourcePermissions = newResourceKey
        setConsultantGroup(newConsultantGroup)
    }

    const handleDoTriageChecked = (checked: boolean) => {
        setConsultantGroup({
            ...consultantGroup,
            doTriage: checked
        })
    }


    const handleAddToList = (uid: string | undefined, list: ListKeys) => {
        if(uid === undefined) return
        if(consultantGroup[list].indexOf(uid) !== -1) return
    
        setConsultantGroup({
            ...consultantGroup,
            [list]: [...consultantGroup[list], uid],
        })
    }

    const handleRemoveFromList = (uid: string, list: ListKeys) => {
        const newList = [...consultantGroup[list]]
        const idx = newList.indexOf(uid)
        if(idx === -1)
            return;
        
        newList.splice(idx, 1)
        setConsultantGroup({
            ...consultantGroup,
            [list]: newList
        })
    }

    const handleConsultant = (uid: string, checked: boolean) => {
        if(checked) {
            handleAddToList(uid, "consultants")
        }   
        else {
            handleRemoveFromList(uid, "consultants")
        }
    }

    const handleTriager = (uid: string, checked: boolean) => {
        if(checked) {
            handleAddToList(uid, "triagers")
        }   
        else {
            handleRemoveFromList(uid, "triagers")
        }
    }


    const handleRemoveFromMembers = (uid: string) => {
        const user = groupUserProfiles.find((user) => user.uid === uid)

        if(!window.confirm(`Remove ${user?.data.firstName} ${user?.data.lastName} from group?`))
            return

        const newMembers = spliceFromList(uid, [...consultantGroup.members])
        const newConsultants = spliceFromList(uid, [...consultantGroup.consultants])
        const newTriagers = spliceFromList(uid, [...consultantGroup.triagers])

        setConsultantGroup({
            ...consultantGroup,
            members: newMembers,
            consultants: newConsultants,
            triagers: newTriagers
        })
    }

    const spliceFromList = (uid: string, list: string[]) => {
        const idx = list.indexOf(uid)
        if(idx !== -1)
            list.splice(idx, 1)
        return list
    }

    const sortMembersAlphabetically = (a: string, b: string): number => {
        const userA = groupUserProfiles.find((user) => user.uid === a)
        const userB = groupUserProfiles.find((user) => user.uid === b)
        return `${userA?.data?.firstName} ${userA?.data?.lastName}`.localeCompare(`${userB?.data?.firstName} ${userB?.data?.lastName}`)

    }



    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Group Name"
                        value={consultantGroup.groupName}
                        onChange={(e) => handleGroupNameChanged(e.target.value)}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">If Use Triaging is enabled, only triagers will be shown in the Consult specialist dropdown</Typography>
                    <FormControlLabel
                        label={"Use Triaging"}
                        control={
                            <Checkbox 
                                checked={consultantGroup.doTriage}
                                onChange={(e) => handleDoTriageChecked(e.target.checked)}
                                disableRipple  
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Required Resource Permission"
                        value={consultantGroup.requiredResourcePermissions}
                        onChange={(e) => handleRequiredResourcePermissionsChanged(e.target.value)}
                        variant="outlined"
                        margin="dense"
                        select
                        fullWidth
                        defaultValue={""}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {Object.values(resourceKeys).map((resourceKey, index) => 
                            <MenuItem key={`resource_permission_view_${resourceKey}_${index}`} value={resourceKey}>
                                {resourceKeyToText(resourceKey)}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Add User to Consultant Group</InputLabel>
                    {createDropdown()}
                </Grid>
                <Grid item>
                    <Button disabled={selectedUid === undefined } onClick={() => handleAddToList(selectedUid, "members")} 
                        variant="contained" color="primary"
                        startIcon={<AddIcon/>}
                    >
                        Add
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Members</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.userList}>
                        {consultantGroup.members.sort(sortMembersAlphabetically).map((memberId) =>  {
                            const user = groupUserProfiles.find((user) => user.uid === memberId)
                            if(!user?.data)
                                return null
                            return (
                                <Grid className={classes.userItem} item xs={12} key={`triager_${memberId}`}>
                                    <UserRow 
                                        userId={memberId}
                                        user={user.data} 
                                        doTriage={consultantGroup.doTriage}
                                        isConsultant={consultantGroup.consultants.includes(memberId)}
                                        isTriager={consultantGroup.triagers.includes(memberId)}
                                        removeUserHandler={(uid) => handleRemoveFromMembers(uid)} 
                                        consultantHandler={handleConsultant} 
                                        triagerHandler={handleTriager}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isDisabled()} variant="contained" color="primary" onClick={saveHandler} startIcon={<SaveIcon/>}>Save</Button>
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isDisabled()} variant="outlined" color="primary" onClick={deleteHandler} startIcon={<DeleteIcon/>}>Delete Group</Button>
                </Grid>
                <Grid item xs={12}>
                    <ProcessStatus state={processState} errorMessage={processErrorMessage} loadingMessage="Saving Consultant Group" successMessage='Consultant group saved.'/>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default EditGroup;