import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { PaymentRecord } from '../../../../shared/types';


interface PaymentRecordsTableProps {
    records: PaymentRecord[];
    classes: any;
}

const PaymentRecordsTable = ({
    records,
    classes
}: PaymentRecordsTableProps) => {

    return (
        <>
            <div className={classes.paper}>
                <Typography variant="h6" className={classes.title}>Batch Payment Records</Typography>
                <Table  className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Full Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((record, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}.</TableCell>
                                <TableCell>{record.legalName}</TableCell>
                                <TableCell>{record.status}</TableCell>
                                <TableCell align="right">{record.totalAmount.toFixed(2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )

}

export default PaymentRecordsTable;