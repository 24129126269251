import React, { useState } from 'react';
import db from "../../firebase/db";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DoneIcon from '@material-ui/icons/Done';
import { PayrollRow } from "./types";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ProcessState, ProcessStatus } from '@alethea-medical/react-components';
import { BillingRecord } from '../../../shared/types';
import { firestore } from 'firebase';
import BatchArray from '../../helpers/BatchArray';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submitStatus: {
            marginTop: theme.spacing(1)
        },
        tableCell: {
            overflow: 'auto',
        },
        paidButton: {
            padding: 0
        }
    }),
);

interface MarkAsPaidButtonProps {
    row: PayrollRow,
    refresh: () => void
}

const MarkAsPaidButton = ({ row, refresh }: MarkAsPaidButtonProps) => {

    const classes = useStyles();
    const [showConfirmSubmit, setShowConfirmSubmit] = useState<boolean>(false);
        
    const [submitState, setSubmitState] = useState(ProcessState.idle);
    const [submitError, setSubmitError] = useState('');

    const markAsPaid = () => {

        setSubmitState(ProcessState.running);
        const batchArray = new BatchArray(db);
        
        row.recordsToPay.forEach((id: string) => {
            const updateObj: Partial<BillingRecord> = {
                status: "paid",
                paidAt: firestore.Timestamp.now()
            }
            batchArray.operate("update", db.collection("billing").doc(id), updateObj)
        })

        return batchArray.commit()
        .then(() => {

            setSubmitState(ProcessState.success);
            refresh();

            //Allow db to propogate
            //Force refresh of billing records to get claim numbers and batch numbers
            setTimeout(() => {
                setSubmitState(ProcessState.idle);
            }, 2000)
        })
        .catch((error: Error) => {
            console.error(error);
            setSubmitError(error.message);
            setSubmitState(ProcessState.error);
        })
    }

    const getButton = () => {
        if(row.totalOwedToDoctor === 0 && row.totalPaidToDoctor > 0 && row.totalAmountPending === 0 && row.totalAmountSubmitted === 0 && row.totalAmountFailed === 0) {
            return (
                <DoneIcon color="secondary"/>
            )
        }
        else if(row.recordsToPay.length > 0 && row.totalAmountPending === 0 && row.totalAmountSubmitted === 0 && row.totalAmountFailed === 0) {
            return (//Enabled and color
                <IconButton      
                    className={classes.paidButton}                           
                    onClick={() => {setShowConfirmSubmit(true);}}
                    disabled={submitState !== ProcessState.idle}
                >
                    <AttachMoneyIcon color="primary"/>
                </IconButton>
            )
        }
        else {
            return (//Grayed out
                <AttachMoneyIcon color="disabled"/>
            )
        }
    }

    return (
        <>
            {getButton()}
            <div className={classes.submitStatus}>
                <ProcessStatus state={submitState} errorMessage={submitError} successMessage={`Marked as paid`} loadingMessage={"Submitting records..."} useSnackbar setState={setSubmitState}/>
            </div>   
            
            <Dialog 
                open={showConfirmSubmit}
                onClose={() => { setShowConfirmSubmit(false);}}
            >
                <DialogTitle id="alert-dialog-title">{`Mark ${row.recordsToPay.length} billing records as paid?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Mark the following records as paid
                            <TableContainer>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    stickyHeader
                                >
                                    <TableHead>
                                        <TableRow>
                                                <TableCell align={'left'} padding={'default'}>Billing ID</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {row.recordsToPay.map((uid) => {
                                        return (
                                            <TableRow
                                                key={`mark_paid_${uid}`}
                                                tabIndex={-1}
                                            >
                                                <TableCell className={classes.tableCell} align="left">{uid}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </Table>
                            </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => { setShowConfirmSubmit(false);}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => {
                        setShowConfirmSubmit(false);
                        markAsPaid();
                    }}>
                        Mark as Paid
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default MarkAsPaidButton;