import React from "react";
import { Content } from "@alethea-medical/react-components";
import Sidebar from "../../components/Sidebar"; 

const Dashboard = (props: any) => {
    return (
        <>
            <Sidebar />
            <Content>
                {props.children}
            </Content>
        </>
    );
}

export default Dashboard;