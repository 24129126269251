import { UserNotificationPreferences, UserProfile } from "@alethea-medical/aletheamd-types";

// Copied from physician portal
/** 
 * Return default fax prefs if isDefault is true. Default will use what is provided, but will default to deprecated values if the provided value doesn't exist 
 * ie secureMessage.outgoing.enabled will default to true if secureMessaging is true, or if receiveFaxes is true
 * 
 * If isDefault is false, will return fax prefs based on the form data
 */
function getFaxPrefs(data:  Partial<UserProfile>, isDefault?: boolean): UserNotificationPreferences["fax"] {
    if(isDefault) {
        const secureMsgFaxEnabled = data.preferences?.fax?.secureMessaging ?? data.preferences?.receiveFaxes ?? true;
        const formFaxEnabled = data.preferences?.receiveFaxes ?? true
        return {
            // If undefined, default to formFaxEnabled
            forms: data.preferences?.fax?.forms ?? formFaxEnabled,
            econsult: {
                // If undefined, default to secureMsgFaxEnabled
                outgoing: {
                    enabled: data.preferences?.fax?.econsult?.outgoing?.enabled ?? secureMsgFaxEnabled
                },
                incoming: {
                    enabled: data.preferences?.fax?.econsult?.incoming?.enabled ?? secureMsgFaxEnabled
                }
            },
            secureMessage: {
                outgoing: {
                    enabled: data.preferences?.fax?.secureMessage?.outgoing?.enabled ?? secureMsgFaxEnabled
                },
                incoming: {
                    options: {
                        // If undefined, default to false
                        onlyFirstConsultantMessage: data.preferences?.fax?.secureMessage?.incoming?.options?.onlyFirstConsultantMessage ?? false,
                    },
                    enabled: data?.preferences?.fax?.secureMessage?.incoming?.enabled ?? secureMsgFaxEnabled
                }
            },
            faxPdfSettings: {
                // if undefined, default to true
                includePdfs: data.preferences?.fax?.faxPdfSettings?.includePdfs ?? true, 
                includeImages: data.preferences?.fax?.faxPdfSettings?.includeImages ?? true,
            }
        }
        
    }
    else {
        return {
            /** Deprecated in Version 8.4.2 */
            // secureMessaging: userData.preferences?.fax?.secureMessaging === true
            forms: data.preferences?.fax?.forms === true,
            econsult: {
                outgoing: {
                    enabled: data.preferences?.fax?.econsult?.outgoing?.enabled === true
                },
                incoming: {
                    enabled: data.preferences?.fax?.econsult?.incoming?.enabled === true
                }
            },
            secureMessage: {
                outgoing: {
                    enabled: data.preferences?.fax?.secureMessage?.outgoing?.enabled === true
                },
                incoming: {
                    options: {
                        onlyFirstConsultantMessage: data.preferences?.fax?.secureMessage?.incoming?.options?.onlyFirstConsultantMessage === true,
                    },
                    enabled: data?.preferences?.fax?.secureMessage?.incoming?.enabled === true
                }
            },
            // If undefined, default to true (so old behaviour is unchanged)
            faxPdfSettings: {
                includePdfs: data.preferences?.fax?.faxPdfSettings?.includePdfs === true,
                includeImages: data.preferences?.fax?.faxPdfSettings?.includeImages === true
            }
        }
    }
}
export default getFaxPrefs