import React, { useEffect, useState } from 'react';
import { useProcessState, ProcessState, ProcessStatus } from '@alethea-medical/alethea-components';
import { UserMetadata, UserProfile } from '@alethea-medical/aletheamd-types';
import { firestore } from 'firebase';
import UserMetadataModel from './UserMetadataModel';
import moment from "moment";


export interface UserMetadataItem {id: string, metadata?: UserMetadata, userData: UserProfile}
export type MetadataMode = "lastLogin" | "all"
export type LoginPlatform = "web" | "ios" | "android"

const UserMetadataController = () => {

    //Contains all meta data
    const [userMetadata, setUserMetadata] = useState<UserMetadataItem[]>([]);

    //Contains filtered meta data based on mode and date range

    const [userMetadataList, setUserMetadataList] = useState<UserMetadataItem[]>([]);
    const [mode, setMode] = useState<MetadataMode>("all");

    //Use for lastLogin mode
    const [dateFrom, setDateFrom] = useState<Date>(moment(new Date()).startOf('month').toDate());
    const [dateTo, setDateTo] = useState<Date>(moment(new Date()).endOf('month').toDate());
    
    const [platform, setPlatform] = useState<LoginPlatform>("web");
    const [usernameFilter, setUsernameFilter] = useState<string>("")

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const { getUserMetadata, getUserMetadataByLastLogin } = UserMetadataModel()

    function getMetadataAll(newMetadata: UserMetadataItem[]) {
        setUserMetadataList(newMetadata);
    }

    function getMetadataByLastLoginHandler () {
        const data = getUserMetadataByLastLogin(userMetadata, platform, firestore.Timestamp.fromDate(dateFrom), firestore.Timestamp.fromDate(dateTo))
        setUserMetadataList(data);
    }

    function createUserMetadataProcessState () {
        return (
            <>
                <ProcessStatus state={processState} errorMessage={processErrorMessage} />
            </>
        )
    }

    //Run once to initialize user meta data list
    useEffect(() => {
        setProcessState(ProcessState.running);
        getUserMetadata()
        .then((data) => {
            setUserMetadata(data);
            //Initialize list
            getMetadataAll(data);
            setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
            console.error(error)
            errorHandler({
                error: error,
                userMessage: error.message
            })
        })
    }, [])

    //When user changes mode or date range, filter list appropriately
    useEffect(() => {
        switch(mode) {
            case "all":
                getMetadataAll(userMetadata);
                break;
            case "lastLogin":
                getMetadataByLastLoginHandler();
                break;
        }
    }, [platform, mode, dateFrom, dateTo])


    return {
        userMetadataList,
        mode, setMode,
        dateFrom,
        dateTo,
        dateFromChangedHandler: setDateFrom,
        dateToChangedHandler: setDateTo,
        platform,
        setPlatform,
        usernameFilter,
        setUsernameFilter,
        loading: processState === ProcessState.running,
        platformChangedHandler: setPlatform,
        createUserMetadataProcessState,
    }
}

export default UserMetadataController;