

import {ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { useEffect, useState } from "react";
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from "clsx";
import app from '../../firebase';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        batch: {
            cursor: "pointer",
            borderRadius: "5px",
            border: "0px solid black",
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            }
        },
        selectedBatch: {
            backgroundColor: theme.palette.grey[200],
        }
    }), 
);
 
interface BatchesProps {
    fromDate: Date,
    toDate: Date,
    generateButtonDisabled: boolean,
    setGenerateButtonDisabled: (disabled: boolean) => void,
    refresh: (message: string) => Promise<void>,
    paymentBatches: any[],
    currentBatch: any,
    setCurrentBatch : (batch: any) => void,
}

const Batches = ({
    fromDate,
    toDate,
    generateButtonDisabled,
    setGenerateButtonDisabled,
    refresh,
    paymentBatches,
    currentBatch,
    setCurrentBatch,
} : BatchesProps) => {

    const classes = useStyles();

    const generatePaymentBatches = app.functions().httpsCallable("generatePaymentBatches");
    const checkForResponseFile = app.functions().httpsCallable("checkForResponseFile");

    const [ dateRangeWarningDialog, setDateRangeWarning ] = useState(false);
    const [ openConfirmGenerateDialog, setConfirmGenerateDialog ] = useState(false);

    const [ checkResponseButtonDisabled, setCheckResponseButtonDisabled ] = useState<boolean>(false);

    const [ successMessage, setSuccessMessage ] = useState("");
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const generatePaymentBatchesHandler = () => {

        const currentDate = new Date();      
        if (toDate.getTime() > currentDate.getTime()) {
            setDateRangeWarning(true);
        } else {
            setConfirmGenerateDialog(true);
        }
    };

    const generatePaymentBatchesCall = () => {
        setConfirmGenerateDialog(false);
        setGenerateButtonDisabled(true);
        setProcessState(ProcessState.running);
        generatePaymentBatches({dateFrom: fromDate.getTime(), dateTo: toDate.getTime()})
            .then(response => {
                setGenerateButtonDisabled(false);
                setProcessState(ProcessState.idle);
                refresh("Payment batches generated successfully");
            })
            .catch(error => {   
                errorHandler({error});
                setGenerateButtonDisabled(false);
            })
    }

    const handleCheckForResponseFile = () => {
        setCheckResponseButtonDisabled(true);
        setProcessState(ProcessState.running)
        checkForResponseFile()
            .then(response => {
                setCheckResponseButtonDisabled(false);
                refresh(response.data)
            }
        ).catch(error => {
            errorHandler({error});
            setCheckResponseButtonDisabled(false);
        })  
    }

    // timestamp is in format: { _seconds: 1620000000, _nanoseconds: 0 }
    const timestampToDateAndTime = (timestamp : { _seconds : number, _nanoseconds: number} ) => {
        const date = new Date(timestamp._seconds * 1000);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    }

    const handleBatchSelection = (batch: any) => {
        if(batch.batchNumber !== currentBatch?.batchNumber) {
            setCurrentBatch(batch);
        }
        else{
            setCurrentBatch(null);
        }
    }

    useEffect(() => {
        setCurrentBatch(null);
    }, [fromDate, toDate])

    useEffect(() => {
        paymentBatches.find((batch) => batch.status === "submitted") ? setCheckResponseButtonDisabled(false) : setCheckResponseButtonDisabled(true);
    }, [refresh])

    return (
        <>
            
            <Grid container justifyContent="space-between" spacing={3}>
                <Grid item xs={12}>
                    <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={successMessage} useSnackbar setState={setProcessState}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Payment Batches</Typography>
                </Grid> 
                <Grid item xs={12}>
                    <Tooltip title={generateButtonDisabled ? "Check for missing billing information before generating payment batches" : "Generate payment batches for the period"}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            disabled={generateButtonDisabled} 
                            onClick={generatePaymentBatchesHandler}
                            >Generate Payment Batches
                        </Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date Created</TableCell>
                                    <TableCell>Creator</TableCell>
                                    <TableCell>Batch Number</TableCell>
                                    <TableCell>File Status</TableCell>
                                    <TableCell align="right">Submitted</TableCell>
                                    <TableCell align="right">Failed</TableCell>
                                    <TableCell align="right">Paid</TableCell>
                                    <TableCell align="right">Total Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentBatches.map((batch, index) => {
                                    return <TableRow 
                                                key={index}
                                                className = {clsx(classes.batch, (batch.batchNumber === currentBatch?.batchNumber) && classes.selectedBatch)}
                                                onClick={() => handleBatchSelection(batch)}
                                                >
                                                <TableCell>{timestampToDateAndTime(batch.dateCreated)}</TableCell>
                                                <TableCell>{batch.creatorName}</TableCell>
                                                <TableCell>{batch.batchNumber}</TableCell>
                                                <TableCell >{batch.status}</TableCell>
                                                <TableCell align="right">{batch.paymentRecords.length}</TableCell>
                                                <TableCell align="right">{batch.status === "processed" ? batch.errorRecords.length : "N/A"}</TableCell>
                                                <TableCell align="right">{batch.paymentRecords.reduce((i : number, e : {status : string}) => e.status === "paid" ? i + 1 : i, 0)}</TableCell>
                                                <TableCell align="right">{"$" + batch.totalAmount.toFixed(2)}</TableCell>
                                            </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>           
                <Grid item xs={12}>
                    <br/>
                    <Tooltip title="Fetch and process the response file if RBC has returned the file via SFTP. NOTE: This function runs daily after midnight" style={{marginLeft: "5px"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={checkResponseButtonDisabled}
                            onClick={() => handleCheckForResponseFile()}
                        >
                            Fetch Response File (Manual Check)
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <Dialog // date range warning
                open={dateRangeWarningDialog}
                onClose={() => setDateRangeWarning(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    You are trying to generate a report before the period has ended. Do you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDateRangeWarning(false)} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={() => {
                        setDateRangeWarning(false)
                        setConfirmGenerateDialog(true)
                    }} color="primary" autoFocus>
                    Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog // generate batch confirmation
                open={openConfirmGenerateDialog}
                onClose={() => setConfirmGenerateDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    You are about to generate payment batches for the selected period. Do you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmGenerateDialog(false)} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={generatePaymentBatchesCall} color="primary" autoFocus>
                    Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Batches;