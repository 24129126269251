import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Location } from "@alethea-medical/aletheamd-types";
import Clinic from './Clinic';
import Divider from '@material-ui/core/Divider';

interface FormClinicInputProps {
    control: any,
    locations: any[],
    append: (location: Location) => void,
    remove: (index?: number | number[] | undefined) => void,
}

const FormClinicInput = ({ control, locations, append, remove }: FormClinicInputProps) => {

    const addClinic = () => {
        append({
            clinicName: '',
            clinicPhone: '',
            fax: '',
            city: '',
            province: "AB",
            billable: true
        });
    }

    return (
        <>
            <Grid container spacing={1}>
                {locations.map((clinic: any, index: number) =>
                    <Grid item xs={12} key={clinic.id}>
                        <Clinic
                            control={control}
                            index={index}
                            remove={remove}
                        />
                        <Divider />
                    </Grid>
                )}
                <Grid item xs={12} >
                    <Button onClick={addClinic} variant="contained" color="primary" >
                        Add Clinic
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default FormClinicInput;