import React, { useState, useEffect } from "react";
import db from "../../firebase/db";
import { Toolbar } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { formatStatus } from "./billingFunctions";
import { Province, Status } from "../../../shared/types/index";
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

import { FetchMethod } from "./BillingTable";


interface BillingTableToolbarProps {
    selectedStatusGroup: Status[], 
    setSelectedStatusGroup: (statusGroup: Status[]) => void,
    showMarkedForDeletion: boolean, 
    setShowMarkedForDeletion: (showMarkedForDeletion: boolean) => void,
    setFetchMethod: (fetchMethod: FetchMethod) => void,
    province: Province
}

type StatusGroups = {
    [key: string]: Status[]
}

//To add another tab, make another entry in this list
const statusGroups: StatusGroups = {
    all: [],
    new : ["new"],
    submitted: ["submitted", "pending"],
    accepted: ["accepted"],
    paid: ["paid"],
    refused: ["refused"],
    onHold: ["onHold"],
    closed: ["closed", "notBillable", "deleted"],
    unconfirmed: ["unconfirmed"]
}
const statuses: Status[] = [
    "new", 
    "pending", 
    "submitted", 
    "accepted", 
    "paid", 
    "refused", 
    "onHold", 
    "closed", 
    "notBillable", 
    "deleted", 
    "unconfirmed",
    "paymentPending",
    "paymentSubmitted",
    "paymentFailed"
]


const useStyles = makeStyles((theme) => ({
    toolbarButtons: {
        margin: theme.spacing(1)
    },
}));

export const BillingTableToolbar = ( { selectedStatusGroup, setSelectedStatusGroup, showMarkedForDeletion, setShowMarkedForDeletion, setFetchMethod, province }: BillingTableToolbarProps) => {
    const classes = useStyles();

    const [numRejected, setNumRejected] = useState<number>(0);

    useEffect(() => {
        db.collection("billing").where("province", "==", province).where("status", "in", statusGroups.refused).get()
        .then((snapshot) => {
            setNumRejected(snapshot.size);
        })
    }, [province])

    const handleToggleButtonStatus = (newStatuses: Status[] | null) => {
        if(showMarkedForDeletion) {
            setShowMarkedForDeletion(false);
            setFetchMethod(FetchMethod.Created);
        }
        if(newStatuses !== null) {
            setSelectedStatusGroup(newStatuses);
        }
    }

    const getButtonContent = ( status: Status) => {
        if(status === "refused")
            return (
                <Badge badgeContent={numRejected} color="error" max={999}>
                    {formatStatus(status)}
                </Badge>
            );
        else 
            return (
                <>
                    {formatStatus(status)}
                </>
            );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Toolbar disableGutters>
                    <ToggleButton selected={selectedStatusGroup.length === 0 && !showMarkedForDeletion} onClick={() => {handleToggleButtonStatus([])}}>
                        All
                    </ToggleButton>
                    <ToggleButtonGroup
                        className={classes.toolbarButtons}
                        value={selectedStatusGroup}
                        onChange={(_, value) => {handleToggleButtonStatus(value)}}
                        aria-label="status groups"
                    >

                        {statuses.map((status: Status) => {
                            return (
                                <ToggleButton  
                                    key={`toggle_status_${status}`}
                                    value={status}
                                >
                                    {getButtonContent(status)}
                                </ToggleButton>
                            );
                        })}

                    </ToggleButtonGroup>
                    <ToggleButton selected={showMarkedForDeletion} onClick={() => {
                        setShowMarkedForDeletion(true);
                        setSelectedStatusGroup([]);
                        setFetchMethod(FetchMethod.All);
                    }}>
                        Marked For Deletion
                    </ToggleButton>
                </Toolbar>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={selectedStatusGroup.length > 0}>
                    <Button onClick={() => {
                        setSelectedStatusGroup([]);
                    }} variant="contained" color="secondary">
                        Clear Statuses
                    </Button>
                </Collapse>


            </Grid>
        </Grid>

    ); 
}
