import React, {useState, useEffect, useContext} from "react";
import {Header} from '../components';
import {Footer} from '../components';


//Component that renders header and footer for anything wrapped in the Layout component
const Layout = (props: any) => {
    return(
        <div>
          <Header />
             { props.children }
          <Footer />
       </div>
    )
}

export default Layout;