import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import app from "../../firebase";
import { firestore } from "firebase";
import 'firebase/firestore';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    stateInfo: {
        marginBottom: theme.spacing(0)
    }
}));
 

const db = app.firestore();



const BillingState = () => {

    const classes = useStyles();

    const [lastSubmission, setLastSubmission] = useState<firestore.Timestamp>();
    const [lastUpdated, setLastUpdated] = useState<firestore.Timestamp>();


    useEffect(() => {
        getState();
    }, []);

    const getState = () => {
        db.collection("billing_system").doc("state").get()
        .then((snapshot) => {
            
            const systemState = snapshot.data() as {
                lastSubmission: firestore.Timestamp,
                lastOUTBB: firestore.Timestamp,
                lastARD: firestore.Timestamp
            };
            if(systemState) {
                setLastSubmission(systemState.lastSubmission);
                setLastUpdated(systemState.lastARD.toMillis() > systemState.lastOUTBB.toMillis() ? systemState.lastARD : systemState.lastOUTBB);
            }

        })
        .catch((error: Error) => {
            console.error(error.message);
        })

    }
    return (
        <>
            <Grid
                container
            >
                <Typography className={classes.stateInfo} variant="subtitle1">Last Submission: {lastSubmission && new Date(lastSubmission.seconds * 1000).toLocaleString()}</Typography>
            </Grid>
            
            <Grid
                container
            >
                <Typography className={classes.stateInfo} variant="subtitle1">Last Updated: {lastUpdated && new Date(lastUpdated.seconds * 1000).toLocaleString()}</Typography>
            </Grid>
        </>
    );
}

export default BillingState;