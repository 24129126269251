import { useState, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { User, UserProfile } from "@alethea-medical/aletheamd-types";
import { PaperPage, ProcessStatus } from "@alethea-medical/react-components";
import UpdateBillingRecordBANs from "./UpdateBillingRecordBANs";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "../../../components/TabPanel";
import EditLogin from "./EditLogin";
import EditProfile from "./EditProfile";
import EditClinics from "./EditClinics";
import EditBillingInfo from "./EditBillingInfo";
import app from "../../../firebase";
import { AuthContext } from "../../../AuthProvider";
import useUserDropdown from "../useUserDropdownV2";
import {
    ProcessState,
    useProcessState,
} from "@alethea-medical/alethea-components";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
        },
        tabBox: {
            marginBottom: theme.spacing(3),
            borderColor: theme.palette.grey[300],
        },
    })
);

const EditUsers = () => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);

    const [tab, setTab] = useState(0);

    const getUser = app.functions().httpsCallable("portal-getUser");
    const getDoc = app.functions().httpsCallable("portal-getDoc");

    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState({});

    const [selectedUser, setSelectedUser] = useState<User | undefined>();
    const { selectedUid, createDropdown } = useUserDropdown({});

    const getSelectedUser = async () => {
        setProcessState(ProcessState.running);
        await getDoc({
            collection: "users",
            id: selectedUid,
        })
            .then(async (userDoc) => {
                const userData = await getUser({ uid: selectedUid });
                setSelectedUser({
                    uid: selectedUid,
                    email: userData.data.email,
                    ...userDoc.data,
                });
                setProcessState(ProcessState.idle);
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: error.message,
                });
            });
    };

    const refreshCurrentUser = () => {
        if (selectedUid === undefined) setSelectedUser(undefined);
        else getSelectedUser();
    };

    useEffect(() => {
        refreshCurrentUser();
    }, [selectedUid]);

    return (
        <PaperPage>
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Edit Users</Typography>
                    </Grid>
                    {/* Dropdown */}
                    <Grid item xs={12}>
                        {/* {createDropdown()} */}
                        {createDropdown()}
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus
                            state={processState}
                            errorMessage={processErrorMessage}
                            loadingMessage="Getting user profile..."
                        />
                    </Grid>

                    {selectedUser !== undefined && (
                        <>
                            <Grid item xs={12}>
                                <Box className={classes.tabBox} border={1}>
                                    <Tabs
                                        value={tab}
                                        onChange={(e, newValue) => {
                                            setTab(newValue);
                                        }}
                                        variant="fullWidth"
                                    >
                                        <Tab label="Profile" />
                                        <Tab label="Login" />
                                        <Tab label="Clinics" />
                                        <Tab label="Utilities" />
                                        {authContext?.profile?.roles
                                            ?.billingAdmin === true && (
                                            <Tab label="Billing Info" />
                                        )}
                                    </Tabs>
                                </Box>
                                <TabPanel
                                    className={classes.paper}
                                    value={tab}
                                    index={0}
                                >
                                    <EditProfile
                                        user={selectedUser}
                                        refreshCurrentUser={refreshCurrentUser}
                                    />
                                </TabPanel>
                                <TabPanel
                                    className={classes.paper}
                                    value={tab}
                                    index={1}
                                >
                                    <EditLogin
                                        user={selectedUser}
                                        refreshCurrentUser={refreshCurrentUser}
                                    />
                                </TabPanel>
                                <TabPanel
                                    className={classes.paper}
                                    value={tab}
                                    index={2}
                                >
                                    <EditClinics
                                        user={selectedUser}
                                        refreshCurrentUser={refreshCurrentUser}
                                    />
                                </TabPanel>
                                <TabPanel
                                    className={classes.paper}
                                    value={tab}
                                    index={3}
                                >
                                    <UpdateBillingRecordBANs
                                        user={selectedUser}
                                    />
                                </TabPanel>
                                {authContext?.profile?.roles?.billingAdmin ===
                                    true && (
                                    <TabPanel
                                        className={classes.paper}
                                        value={tab}
                                        index={4}
                                    >
                                        <EditBillingInfo
                                            user={selectedUser}
                                            refreshCurrentUser={
                                                refreshCurrentUser
                                            }
                                        />
                                    </TabPanel>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </div>
        </PaperPage>
    );
};

export default EditUsers;
