import { RolePermissionsWithKey } from "../views/EditRoles/EditRoles";

    const sortRolesInPlace = (rolesToSort: RolePermissionsWithKey[]) => {
        rolesToSort.sort((a: RolePermissionsWithKey, b: RolePermissionsWithKey) => {
            if(a.data.name.toLowerCase() < b.data.name.toLowerCase())
                return -1;
            else if(a.data.name.toLowerCase() > b.data.name.toLowerCase())
                return 1;
            return 0;
        })
    }

    export default sortRolesInPlace;