import { useMemo, useEffect } from "react";
import {
    LocationBilling,
    User,
    UserProfile,
} from "@alethea-medical/aletheamd-types";
import { ProcessStatus, ProcessState } from "@alethea-medical/react-components";
import { useForm, useFieldArray } from "react-hook-form";
import app from "../../../firebase";
import useProcessState from "../../../components/useProcessState";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormClinicInput from "../Clinics/FormClinicInput";
import parseLocationBilling, {
    periodToUnderscore,
} from "../Clinics/locationBillingFunctions";

interface EditClinicProps {
    user: User;
    refreshCurrentUser: () => void;
}

const EditClinics = ({ user, refreshCurrentUser }: EditClinicProps) => {
    //Set default values here
    const getClinicValues = () => {
        return {
            locations:
                user.locations !== undefined
                    ? user.locations.map((l) => {
                          if (l.billing) {
                              const billing = l.billing;
                              const newBilling: LocationBilling = {};
                              //Replace . with _ for react hook form variable
                              Object.keys(billing).forEach((billingCode) => {
                                  newBilling[periodToUnderscore(billingCode)] =
                                      billing[billingCode];
                              });
                              l.billing = newBilling;
                          }
                          return l;
                      })
                    : [],
        };
    };

    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
        defaultValues: useMemo(getClinicValues, [user]),
    });

    const {
        fields: locations,
        append,
        remove,
    } = useFieldArray({ control, name: "locations", shouldUnregister: true });

    //Add what default values should reset to here
    useEffect(() => {
        reset(getClinicValues());
    }, [user]);

    const updateDoc = app.functions().httpsCallable("portal-updateDoc");
    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState();

    const onSubmit = (data: any) => {
        try {
            // At most, 1 clinic can be the default
            if (data.locations.filter((l: any) => l.default).length > 1) {
                errorHandler({
                    userMessage: "Only one clinic can be the default.",
                });
                return;
            }

            if (data.locations.every((l: any) => l.disabled)) {
                errorHandler({
                    userMessage:
                        "All clinics are disabled. Enable at least one clinic to save.",
                });
                return;
            }

            // For each disabled clinic, if default flag is True switch it to False
            data.locations.forEach((l: any) => {
                if (l.disabled && l.default) {
                    l.default = false;
                }
            });

            // Loop through and trim input values
            data.locations.forEach((l: any) => {
                if (l.clinicName) l.clinicName = l.clinicName.trim();
                if (l.city) l.city = l.city.trim();
                if (l.emr) l.emr = l.emr.trim();
                if (l.division) l.division = l.division.trim();
            });

            const newProfile: Partial<UserProfile> = {
                locations: data.locations.map(parseLocationBilling),
            };
            setProcessState(ProcessState.running);
            updateDoc({ collection: "users", id: user.uid, data: newProfile })
                .then(() => {
                    refreshCurrentUser();
                    setProcessState(ProcessState.success);
                    setTimeout(() => {
                        setProcessState(ProcessState.idle);
                    }, 1000);
                })
                .catch((error: Error) => {
                    errorHandler({
                        userMessage: error.message,
                        error: error,
                    });
                });
        } catch (error) {
            errorHandler({
                userMessage:
                    "Please enter number values for custom billing amounts.",
            });
        }
    };

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors.",
        });
    };

    const isDisabled = () => {
        return (
            processState === ProcessState.running ||
            processState === ProcessState.success
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <fieldset disabled={isDisabled()}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormClinicInput
                                control={control}
                                locations={locations}
                                append={append}
                                remove={remove}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={isDisabled()}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus
                                state={processState}
                                errorMessage={processErrorMessage}
                                successMessage="Clinics updated"
                            />
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </>
    );
};

export default EditClinics;
