import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ProcessStatus, ProcessState } from '@alethea-medical/react-components';
import app from '../../../firebase';
import Typography from '@material-ui/core/Typography';
import { User } from '@alethea-medical/aletheamd-types';
import useProcessState from '../../../components/useProcessState';
import DangerZone from '../../Utilities/DangerZone';

interface UpdateBANButtonProps {
    user: User
}

const UpdateBillingRecordBANs = ({ user }: UpdateBANButtonProps) => {

    const updateBAN = app.functions().httpsCallable("billing-updateBAN");

    const { processState, setProcessState, processErrorMessage, errorHandler, processSuccessMessage, setProcessSuccessMessage } = useProcessState();

    const handleUpdateBAN = () => {
        setProcessState(ProcessState.running);
        updateBAN({
            uid: user.uid,
            ban: user.ban
        })
            .then((result) => {
                setProcessSuccessMessage(`Updated ${result.data} records.`);

                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle)
                }, 1000);
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: error.message
                })
            })
    }

    return (

        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5">{`BAN Update Tool`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>Updates the BAN on all of <b>{user.firstName} {user.lastName}'s</b> billing records to their current BAN ({user.ban})</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>and moves all of their <b>On Hold</b> billing records to <b>New</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">Will only update billing records with the following statuses: <b>Closed, New, Not Billable, On Hold, Refused, or Unconfirmed</b></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">Check the box below to enable the button.</Typography>
            </Grid>
            <Grid item xs={12}>
                <DangerZone label="I know what I'm doing." allowAnyUser>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleUpdateBAN}>Update BAN on Billing Records</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={processSuccessMessage} />
                        </Grid>
                    </Grid>
                </DangerZone>
            </Grid>
        </Grid>
    );
}

export default UpdateBillingRecordBANs;