import React, {useState} from "react";
import { TextField } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textfield: {
            margin: 0
        }
    }),
);

interface BillingTableTextfieldProps {
    label?: string,
    initialText?: string,
    placeholder?: string,
    handleUpdateText: (text: string) => void,
    validation?: (text: string) => boolean,
    helperText?: string,
    helperTextFn?: (text: string) => string | undefined,
    multiline?: boolean,
    disabled?: boolean,
    enterBlurs?: boolean
}

const BillingTableTextfield = (props: BillingTableTextfieldProps) => {
    const { label, placeholder, initialText, handleUpdateText, validation, helperText, helperTextFn, multiline, disabled, enterBlurs } = props;
    const classes = useStyles();

    const [text, setText] = useState<string>(initialText ? initialText : "");
    const [error, setError] = useState<boolean>(validation ? !validation(initialText ? initialText : "") : false);

    const blurOnEnterPressed = (event: any) => {
        if (event.keyCode === 13) {
          event.target.blur();
        }
    }
        
    //Update internal state
    const handleText = (event: any) => {
        const newText = event.target.value
        setText(newText);
        if(validation) {
            const valid = validation(newText);
            setError(!valid);   
        }
    }

    //When user clicks away, try and update firestore value
    const handleBlur = (event: any) => {
        if(!validation) {
            //If no validation, update the value
            handleUpdateText(text);
        }
        else if(validation(text)) {
            //If no errors, update the text
            handleUpdateText(text);
        }
        else {
            //If there is an error, reset the text when the user clicks away from the field
            setText(initialText ? initialText : "");
        }
        setError(false);
    }

    return (
        <>
            <TextField className={classes.textfield} label={label} placeholder={placeholder} multiline={multiline} disabled={disabled} value={text} 
            onKeyDown={enterBlurs ? blurOnEnterPressed : undefined} onChange={handleText} onBlur={handleBlur} error={error} helperText={error ? (helperTextFn ? helperTextFn(text) : (helperText ? helperText : "")) : ""}
            fullWidth margin="dense"/>
        </>
    );
}

export default BillingTableTextfield;