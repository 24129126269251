import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { PaperPage } from "@alethea-medical/alethea-components";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useState } from 'react';
import TabPanel from '../../components/TabPanel';
import EditSpecialistProfile from './EditSpecialistProfile';
import EditConsultantGroupsView from './ConsultantGroups/EditConsultantGroupsView';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        tabPanel: {
            padding: theme.spacing(2),
        }
    })
);



const EditSpecialists = () => {
    const classes = useStyles();
    const [tab, setTab] = useState(0);


    return (
        <PaperPage>
            <div className={classes.root}>
               <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Edit Specialists</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs value={tab} onChange={(e, newValue) => { setTab(newValue)}} variant="fullWidth">
                            <Tab label="Specialist Profiles"/>
                            <Tab label="Consultant Groups"/>
                        </Tabs>
                        <TabPanel className={classes.tabPanel} value={tab} index={0}>
                            <EditSpecialistProfile/>
                        </TabPanel>
                        <TabPanel className={classes.tabPanel} value={tab} index={1}>
                            <EditConsultantGroupsView/>
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        </PaperPage>
    );
}

export default EditSpecialists;