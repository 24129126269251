import { User } from '@alethea-medical/aletheamd-types';
import { Typography, Grid, Button } from '@material-ui/core';
import useProcessState from '../../../components/useProcessState';
import { ProcessStatus, ProcessState } from "@alethea-medical/react-components";
import { useForm } from 'react-hook-form';
import app from "../../../firebase";

import { useEffect } from 'react';

import FormBillingInput from '../FormBillingInput';


interface EditBillingInfoProps {
    user: User,
    refreshCurrentUser: () => void
}

const EditBillingInfo = ({ user, refreshCurrentUser }: EditBillingInfoProps) => {

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState();

    const getDoctorBillingInfo = app.functions().httpsCallable("getDoctorBillingInfo");
    const updateDoctorBillingInfo = app.functions().httpsCallable("updateDoctorBillingInfo");

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }


    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
        defaultValues: {
            legalName: "",
            institutionNumber: "",
            transitNumber: "",
            accountNumber: "",
        }
    });

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors."
        })
    }

    const onSubmit = (data: any) => {
        setProcessState(ProcessState.running);

        updateDoctorBillingInfo({uid: user.uid, billingInfo: data})
        .then(() => {
            refreshCurrentUser();
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
            }, 1000)
        })
    }

    useEffect(() => {
        setProcessState(ProcessState.running);
        getDoctorBillingInfo({uid: user.uid})
            .then((result) => {
                setProcessState(ProcessState.idle);
                let newBillingInfo = Object.keys(result.data).length !==0 ? result.data : {
                    legalName: "",
                    institutionNumber: "",
                    transitNumber: "",
                    accountNumber: "",
                };
                reset(newBillingInfo);
            })
            .catch((error: Error) => { 
                errorHandler({
                    userMessage: error.message,
                    error: error
                })
            })
    }, [user])


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <fieldset disabled={isDisabled()} >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">UID: {user.uid}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormBillingInput control={control} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={isDisabled()} variant="contained" color="primary" type="submit">Save</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={"Profile updated"} />
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </>
    )

}

export default EditBillingInfo

