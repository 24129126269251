import React from "react";
import { TableRow, TableCell, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { FrontendData, OOPData } from "../../../shared/types";
import BillingTableTextfield from "./billingTableTextfield";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";



interface BillingTableOOPDataProps {
    data: FrontendData,
    canEdit: boolean,
    handleUpdateOOPData: (oopData: OOPData, index: number) => void
}


const useStyles = makeStyles((theme) => ({
    tableCell: {
        overflow: 'auto'
    }
}));

const BillingTableOOPData = (props: BillingTableOOPDataProps) => {
    const { data: frontendData,  canEdit, handleUpdateOOPData } = props;

    const classes = useStyles(); 

    const initializeIfUndefined = (oopData: OOPData | undefined): OOPData => {
        if(oopData === undefined) {
            const newData: OOPData = {
                surname: "",
                firstName: "",
                birthDate: "",
                genderCode: "M",
                addressLine1: "",
                cityName: "",
                postalCode: "",
                provinceCode: "BC",
            }
            return newData;
        }
        else {
            return {...oopData};
        }
    }

    const makeTextRow = (props: BillingTableOOPDataProps, displayName: string, oopKey: keyof OOPData, maxLen: number, required: boolean) => {
        const { data: frontendData,  canEdit, handleUpdateOOPData } = props;
        
        return (
            <TableRow>
                <TableCell className={classes.tableCell} align="left" component="th" scope="row">{displayName}</TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <BillingTableTextfield disabled={!canEdit} initialText={frontendData.data.oopData && frontendData.data.oopData[oopKey] !== undefined ? frontendData.data.oopData[oopKey] : ""} 
                        handleUpdateText={(text: string) => {
                            const newOOPData = initializeIfUndefined(frontendData.data.oopData);
                            newOOPData[oopKey] = text;
                            handleUpdateOOPData(newOOPData, frontendData.index)
                        }} 
                        multiline={false}
                        validation={(text: string): boolean => {
                            return text.length <= maxLen && !(required && text.length === 0);
                        }}
                        helperText={`HLink requires this field to be not empty and no more than ${maxLen} characters.`}
                        enterBlurs
                    />
                </TableCell>
            </TableRow>
    
        );
    }
    
    return (
        <>
            <Typography variant="subtitle1">Patient Info</Typography>
            {makeTextRow(props, "First Name", "firstName", 12, true)}
            {makeTextRow(props,  "Last Name", "surname", 30, true)}

            <TableRow>
                <TableCell className={classes.tableCell} align="left" component="th" scope="row">Birth Date</TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker disabled={!canEdit} format="YYYY-MM-DD" type="date" value={frontendData.data.oopData ? frontendData.data.oopData.birthDate : null} 
                            autoOk
                            variant="inline"
                            onChange={(date: MaterialUiPickersDate) => {
                                if(date !== null) {
                                    const newOOPData = initializeIfUndefined(frontendData.data.oopData);
                                    newOOPData.birthDate = date.format("YYYYMMDD");
                                    handleUpdateOOPData(newOOPData, frontendData.index)
                                }
                            }}/>
                    </MuiPickersUtilsProvider>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell className={classes.tableCell} align="left" component="th" scope="row">Sex</TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <Select value={frontendData.data.oopData ? frontendData.data.oopData.genderCode : null}
                        onChange={(event: any) => {
                            const newOOPData = initializeIfUndefined(frontendData.data.oopData);
                            newOOPData.genderCode = event.target.value;
                            handleUpdateOOPData(newOOPData, frontendData.index)
                        }}
                        disabled={!canEdit}
                    >
                        <MenuItem value={"M"}>M</MenuItem>
                        <MenuItem value={"F"}>F</MenuItem>
                    </Select>
                </TableCell>
            </TableRow>
            <Tooltip title="Should contain non-address data (e.g. company
name) if applicable, otherwise the street or mailing
address should be here. The apartment or unit
number is to be placed at the end of the street
address. No symbols (#,-) are to be placed before
the number.">
                {makeTextRow(props,  "Address Line 1", "addressLine1", 25, true)}
            </Tooltip>
            {makeTextRow(props,  "Address Line 2", "addressLine2", 25, false)}
            {makeTextRow(props,  "Address Line 3", "addressLine3", 25, false)}
            {makeTextRow(props,  "City Name", "cityName", 30, true)}
            
            <TableRow>
                <TableCell className={classes.tableCell} align="left" component="th" scope="row">Postal Code</TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <BillingTableTextfield initialText={frontendData.data.oopData && frontendData.data.oopData.postalCode !== undefined ? frontendData.data.oopData.postalCode : ""} 
                        handleUpdateText={(text: string) => {
                            const newOOPData = initializeIfUndefined(frontendData.data.oopData);
                            newOOPData.postalCode = text;
                            handleUpdateOOPData(newOOPData, frontendData.index)
                        }} 
                        multiline={false}
                        validation={(text: string): boolean => {
                            return /^[A-Z]\d[A-Z]\d[A-Z]\d$/.test(text);
                        }}
                        helperText={"Enter a valid postal code. Do not include any spaces, and use capital letters."}
                        disabled={!canEdit}
                    />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell className={classes.tableCell} align="left" component="th" scope="row">Province</TableCell>
                <TableCell className={classes.tableCell} align="left">
                    <Select value={frontendData.data.oopData ? frontendData.data.oopData.provinceCode : null}
                        onChange={(event: any) => {
                            const newOOPData = initializeIfUndefined(frontendData.data.oopData);
                            newOOPData.provinceCode = event.target.value as string;
                            handleUpdateOOPData(newOOPData, frontendData.index)
                        }}
                        disabled={!canEdit}
                    >
                        <MenuItem value={"AB"}>AB</MenuItem>
                        <MenuItem value={"BC"}>BC</MenuItem>
                        <MenuItem value={"MB"}>MB</MenuItem>
                        <MenuItem value={"NB"}>NB</MenuItem>
                        <MenuItem value={"NL"}>NL</MenuItem>
                        <MenuItem value={"NT"}>NT</MenuItem>
                        <MenuItem value={"NS"}>NS</MenuItem>
                        <MenuItem value={"NU"}>NU</MenuItem>
                        <MenuItem value={"ON"}>ON</MenuItem>
                        <MenuItem value={"PE"}>PE</MenuItem>
                        <MenuItem value={"QC"} disabled>QC</MenuItem>
                        <MenuItem value={"SK"}>SK</MenuItem>
                        <MenuItem value={"YT"}>YT</MenuItem>
                    </Select>
                </TableCell>
            </TableRow>
        </>
    );
}

export default BillingTableOOPData;