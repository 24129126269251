import { Typography, Button, Grid, Tooltip } from "@material-ui/core";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import app from "../../firebase";
import {
    ProcessState,
    ProcessStatus,
    useProcessState,
} from "@alethea-medical/alethea-components";
import { parse } from "json2csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import { saveAs } from "file-saver";

interface BillingInfoCheckProps {
    setGenerateButtonDisabled: (disabled: boolean) => void;
    dateFrom: Date;
    dateTo: Date;
}

const BillingInfoCheck = ({
    setGenerateButtonDisabled,
    dateFrom,
    dateTo,
}: BillingInfoCheckProps) => {
    const [checkForInfoButtonDisabled, setCheckForInfoButtonDisabled] =
        useState<boolean>(false);

    const [missingBillingInfo, setMissingBillingInfo] = useState<any[]>([]);

    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState({});

    const getMissingBillingInfo = app
        .functions()
        .httpsCallable("getMissingBillingInfo");

    const getMissingBillingInfoHandler = async () => {
        setCheckForInfoButtonDisabled(true);
        setProcessState(ProcessState.running);

        getMissingBillingInfo({
            dateFrom: dateFrom.getTime(),
            dateTo: dateTo.getTime(),
        })
            .then((result) => {
                setMissingBillingInfo(result.data);
                setGenerateButtonDisabled(false);
                setCheckForInfoButtonDisabled(false);
                setProcessState(ProcessState.idle);
            })
            .catch((error) => {
                setCheckForInfoButtonDisabled(false);
                errorHandler({ error });
            });
    };

    const exportCSV = () => {
        if (missingBillingInfo.length === 0) {
            errorHandler({ userMessage: "No data to export" });
            return;
        }

        const report = missingBillingInfo.map((doctor) => {
            return {
                uid: doctor.uid,
                name: doctor.name,
                amountOwed: doctor.amountOwed.toFixed(2),
                institutionNumber: "",
                transitNumber: "",
                accountNumber: "",
            };
        });

        const csv = parse(report);
        const fileToSave = new Blob([csv], { type: "text/csv" });
        const fileName = `missing_billing_info_${dateFrom
            .toLocaleString("en-US", { month: "long" })
            .toLowerCase()}`;
        saveAs(fileToSave, fileName);
    };

    const reset = () => {
        setGenerateButtonDisabled(true);
        setCheckForInfoButtonDisabled(false);
        setMissingBillingInfo([]);
        setProcessState(ProcessState.idle);
    };

    useEffect(() => {
        reset();
    }, [dateFrom, dateTo]);

    return (
        <div>
            <Grid item xs={12}>
                <ProcessStatus
                    state={processState}
                    errorMessage={processErrorMessage}
                    useSnackbar
                    setState={setProcessState}
                />
            </Grid>

            <Grid container justifyContent="space-between">
                <Grid style={{ margin: "15px" }}>
                    <Typography variant="h5">Missing Billing Info</Typography>
                </Grid>
                <Grid style={{ margin: "15px" }}>
                    <Tooltip title="Export as a .csv file">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<GetAppIcon />}
                            disabled={missingBillingInfo.length === 0}
                            onClick={exportCSV}
                        >
                            Export
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>DoctorUid</TableCell>
                        <TableCell align="right">DoctorName</TableCell>
                        <TableCell align="right">Amount Owed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {missingBillingInfo.map((doctor) => (
                        <TableRow key={doctor.uid}>
                            <TableCell>{doctor.uid}</TableCell>
                            <TableCell align="right">{doctor.name}</TableCell>
                            <TableCell align="right">
                                ${doctor.amountOwed.toFixed(2)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Tooltip title="Check for doctors that are owed but are missing or have incorrect billing info">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={checkForInfoButtonDisabled}
                    onClick={getMissingBillingInfoHandler}
                    style={{ marginTop: "25px", marginRight: "15px" }}
                >
                    Check for Missing Billing Info
                </Button>
            </Tooltip>
        </div>
    );
};

export default BillingInfoCheck;

