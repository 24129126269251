//Code based on:
//https://codesandbox.io/s/8uyys?file=/demo.tsx:27-12340
import React, { useEffect, useState } from "react";
import db from "../../firebase/db";
import {
    Grid,
    Collapse,
    Table,
    TableBody,
    TableRow,
    TableContainer,
    TablePagination,
} from "@material-ui/core";

import { BillingTableHead } from "./billingTableHead";
import { BillingTableToolbar } from "./billingTableToolbar";
import BillingTableRow from "./billingTableRow";
import DatePicker from "./DatePicker";
import BillingState from "./billingState";
import { Order, SortableKeys } from "./types";
import {
    stableSort,
    getComparator,
    getFetchMethodText,
    formatStatus,
} from "./billingFunctions";

import { firestore } from "firebase";
import {
    BillingRecord,
    FrontendData,
    Status,
    Province,
    BillingCodeInfoV2,
} from "../../../shared/types";
import { PaperPage } from "@alethea-medical/react-components";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ExportCSV from "./ExportCSV";
import moment from "moment";
import Filters, { Filter, matchesFilter } from "./Filters";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconButton from "@material-ui/core/IconButton";

import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import SubmitButton from "./SubmitButton";
import TextField from "@material-ui/core/TextField";

import * as QueryString from "query-string";
import { withRouter } from "react-router-dom";
import useBillingCodes from "../../components/useBillingCodes";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        table: {
            overflow: "visible",
            marginTop: theme.spacing(2),
        },
        loadingStatus: {
            marginTop: theme.spacing(1),
        },
    })
);

export enum FetchMethod {
    Created,
    LastUpdated,
    HLinkResultReceivedAt,
    BatchNumber,
    All,
}

const BillingTable = withRouter(({ location }) => {
    const classes = useStyles();

    const rowsPerPageOptions = [10, 25, 50, 100];
    const [showSearchFields, setShowSearchFields] = useState(true);

    const [billingData, setBillingData] = useState<FrontendData[]>([]);
    const [filteredData, setFilteredData] = useState<FrontendData[]>([]);

    const [fetchMethod, setFetchMethod] = useState<FetchMethod>(
        FetchMethod.Created
    );

    const [fromDate, setFromDate] = useState<Date>(
        moment(new Date()).startOf("month").toDate()
    );
    const [toDate, setToDate] = useState<Date>(
        moment(new Date()).endOf("month").toDate()
    );
    const [batchNumberToFetch, setBatchNumberToFetch] = useState<number>(0);
    const [province, setProvince] = useState<Province>("AB");
    const [fetchByQuery, setFetchByQuery] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(false);

    const [loadingRecordsState, setLoadingRecordsState] =
        useState<ProcessState>(ProcessState.idle);
    const [loadingRecordsError, setLoadingRecordsError] = useState("");

    const [selectedStatusGroup, setSelectedStatusGroup] = useState<Status[]>([
        "new",
    ]);

    const [showMarkedForDeletion, setShowMarkedForDeletion] =
        useState<boolean>(false);

    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<SortableKeys>("created");

    const [enableFilter, setEnableFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState<Filter[]>([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [billingCodesV2, setBillingCodesV2] = useState<BillingCodeInfoV2>({});
    const [isLoadingBillingCodesV2, setIsLoadingBillingCodesV2] = useState<boolean>(true);

    const getBillingRecords = () => {
        setLoadingRecordsState(ProcessState.running);
        let query = db.collection("billing").where("province", "==", province);
        if (showMarkedForDeletion) {
            query = query.where(
                "submissionOptions.markedForDeletion",
                "==",
                true
            );
        } else {
            switch (fetchMethod) {
                case FetchMethod.Created:
                    query = query
                        .where(
                            "created",
                            ">=",
                            firestore.Timestamp.fromDate(fromDate)
                        )
                        .where(
                            "created",
                            "<=",
                            firestore.Timestamp.fromDate(toDate)
                        );
                    break;
                case FetchMethod.LastUpdated:
                    query = query
                        .where(
                            "lastUpdatedAt",
                            ">=",
                            firestore.Timestamp.fromDate(fromDate)
                        )
                        .where(
                            "lastUpdatedAt",
                            "<=",
                            firestore.Timestamp.fromDate(toDate)
                        );
                    break;
                case FetchMethod.HLinkResultReceivedAt:
                    query = query
                        .where(
                            "hlinkResultReceivedAt",
                            ">=",
                            firestore.Timestamp.fromDate(fromDate)
                        )
                        .where(
                            "hlinkResultReceivedAt",
                            "<=",
                            firestore.Timestamp.fromDate(toDate)
                        );
                    break;
                case FetchMethod.BatchNumber:
                    query = query.where("batchNum", "==", batchNumberToFetch);
                    break;
                case FetchMethod.All:
                    break;
            }
            if (selectedStatusGroup.length !== 0) {
                //Not all
                query = query.where("status", "in", selectedStatusGroup);
            }
        }

        query
            .get()
            .then(
                (snapshot: firestore.QuerySnapshot<firestore.DocumentData>) => {
                    const documents: FrontendData[] = [];
                    let index = 0;
                    snapshot.forEach((doc) => {
                        documents.push({
                            uid: doc.id,
                            index: index,
                            data: doc.data() as BillingRecord,
                        });
                        index++;
                    });

                    setPage(0);
                    setBillingData(documents);

                    if (fetchMethod === FetchMethod.Created)
                        setOrderBy("created");
                    else if (fetchMethod === FetchMethod.LastUpdated)
                        setOrderBy("lastUpdatedAt");

                    setLoadingRecordsState(ProcessState.success);
                    setTimeout(() => {
                        setLoadingRecordsState(ProcessState.idle);
                    }, 2000);
                }
            )
            .catch((error: Error) => {
                setLoadingRecordsState(ProcessState.error);
                setLoadingRecordsError(error.message);
                console.error(error.message);
            });
    };

    useEffect(() => {
        const params = QueryString.parse(location.search);

        if (
            params.fetchByPayroll &&
            params.month &&
            params.year &&
            params.doctorName &&
            params.province
        ) {
            const date = moment(new Date());
            date.set("month", parseInt(params.month as string));
            date.set("year", parseInt(params.year as string));
            setProvince(params.province as Province);
            setFromDate(date.startOf("month").toDate());
            setToDate(date.endOf("month").toDate());
            setEnableFilter(true);
            setFilters([
                {
                    field: {
                        key: "firstName",
                        display: "Doctor Name",
                    },
                    value: params.doctorName as string,
                },
            ]);
            setSelectedStatusGroup(["refused", "accepted", "paid"]);
            setFetchMethod(FetchMethod.HLinkResultReceivedAt);
            setShowSearchFields(false);
            //Set fetchByQuery so we wait for react state to propagate then run getBillingRecords on useEffect
            setFetchByQuery(true);
        } else {
            //Run initial fetch when page is opened
            getBillingRecords();
        }
    }, []);

    useEffect(() => {
        setFilteredData(
            billingData.filter((dataRow: FrontendData) => {
                if (
                    selectedStatusGroup.indexOf(dataRow.data.status) != -1 ||
                    selectedStatusGroup.length === 0 ||
                    showMarkedForDeletion
                ) {
                    //If empty then select all
                    if (!enableFilter)
                        //No filter, return all
                        return true;
                    else {
                        return matchesFilter(dataRow, filters);
                    }
                }
                return false;
            })
        );
    }, [billingData, filters, enableFilter]);

    useEffect(() => {
        setPage(0);
    }, [enableFilter]);

    useEffect(() => {
        //Clear when changing fetch options
        setBillingData([]);
    }, [
        selectedStatusGroup,
        fromDate,
        toDate,
        fetchMethod,
        showMarkedForDeletion,
    ]);

    useEffect(() => {
        if (fetchByQuery) getBillingRecords();
    }, [fetchByQuery]);

    // Get billing records v2
    useEffect(() => {
        const fetchBillingCodesV2 = async () => {
            const doc = await db
                .collection("billing_codes_v2")
                .doc(province)
                .get();
            if (doc.exists) {
                setBillingCodesV2(doc.data() as BillingCodeInfoV2);
            } else {
                setBillingCodesV2({});
            }
        };
        setIsLoadingBillingCodesV2(true);
        fetchBillingCodesV2();
        setIsLoadingBillingCodesV2(false);
    }, [province]);

    const { billingCodes, loading: isLoadingBillingCodes } = useBillingCodes({ province });

    const isFetchMethodDisabled = () => {
        return showMarkedForDeletion;
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: SortableKeys
    ) => {
        const isAsc = orderBy === property && order === "desc";
        setOrder(isAsc ? "asc" : "desc");
        setOrderBy(property);
    };

    const handleUpdateRecord = (
        updateObject: Partial<BillingRecord>,
        index: number
    ) => {
        db.collection("billing")
            .doc(billingData[index].uid)
            .update(updateObject)
            .then(() => {
                const newData = [...billingData];
                newData[index].data = Object.assign(
                    billingData[index].data,
                    updateObject
                );
                setBillingData(newData);
            })
            .catch((error: Error) => {
                console.error(error);
            });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const showDates = () => {
        return (
            fetchMethod === FetchMethod.Created ||
            fetchMethod === FetchMethod.LastUpdated ||
            fetchMethod === FetchMethod.HLinkResultReceivedAt
        );
    };

    const fetchButton = () => {
        return (
            <Button
                endIcon={<RefreshIcon />}
                variant="contained"
                color="secondary"
                onClick={() => {
                    getBillingRecords();
                    setReloadTrigger(!reloadTrigger);
                }}
            >
                Fetch
            </Button>
        );
    };

    return (
        <PaperPage className={classes.root}>
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="subtitle1">
                                Province
                            </Typography>
                            <Select
                                value={province}
                                onChange={(e: any) => {
                                    setProvince(e.target.value);
                                }}
                            >
                                <MenuItem value={"AB"}>AB</MenuItem>
                                <MenuItem value={"BC"}>BC</MenuItem>
                                <MenuItem value={"MB"}>MB</MenuItem>
                                <MenuItem value={"NB"}>NB</MenuItem>
                                <MenuItem value={"NL"}>NL</MenuItem>
                                <MenuItem value={"NT"}>NT</MenuItem>
                                <MenuItem value={"NS"}>NS</MenuItem>
                                <MenuItem value={"NU"}>NU</MenuItem>
                                <MenuItem value={"ON"}>ON</MenuItem>
                                <MenuItem value={"PE"}>PE</MenuItem>
                                <MenuItem value={"QC"}>QC</MenuItem>
                                <MenuItem value={"SK"}>SK</MenuItem>
                                <MenuItem value={"YT"}>YT</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item>
                            <BillingState />
                        </Grid>
                        <Grid item>
                            <ExportCSV
                                fromDate={fromDate}
                                toDate={toDate}
                                dataToExport={filteredData}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <IconButton
                                onClick={() =>
                                    setShowSearchFields(!showSearchFields)
                                }
                            >
                                {showSearchFields ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Collapse in={!showSearchFields}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            {showMarkedForDeletion
                                                ? "Marked For Deletion"
                                                : selectedStatusGroup.length > 0
                                                    ? selectedStatusGroup
                                                        .map((s) =>
                                                            formatStatus(s)
                                                        )
                                                        .join(", ")
                                                    : "All"}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            |
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            {getFetchMethodText(fetchMethod)}
                                        </Typography>
                                    </Grid>
                                    {fetchMethod ===
                                        FetchMethod.BatchNumber && (
                                            <Grid item>
                                                <Typography variant="subtitle1">
                                                    {batchNumberToFetch}
                                                </Typography>
                                            </Grid>
                                        )}
                                    {showDates() && (
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {fromDate.toLocaleDateString()}{" "}
                                                to {toDate.toLocaleDateString()}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {enableFilter && (
                                        <>
                                            <Grid item>
                                                <Typography variant="subtitle1">
                                                    |
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1">
                                                    {filters
                                                        .map((filter) => {
                                                            return `${filter.field.display}: ${filter.value}`;
                                                        })
                                                        .join(", ")}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Collapse>
                            <Collapse in={showSearchFields}>
                                <Typography variant="subtitle1">
                                    Hide Search
                                </Typography>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={showSearchFields}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <BillingTableToolbar
                                    selectedStatusGroup={selectedStatusGroup}
                                    setSelectedStatusGroup={
                                        setSelectedStatusGroup
                                    }
                                    showMarkedForDeletion={
                                        showMarkedForDeletion
                                    }
                                    setShowMarkedForDeletion={
                                        setShowMarkedForDeletion
                                    }
                                    setFetchMethod={setFetchMethod}
                                    province={province}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant={"subtitle1"}
                                                >
                                                    Fetch records by
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Select
                                                    value={fetchMethod}
                                                    onChange={(e: any) => {
                                                        setFetchMethod(
                                                            e.target.value
                                                        );
                                                    }}
                                                    disabled={isFetchMethodDisabled()}
                                                >
                                                    <MenuItem
                                                        value={
                                                            FetchMethod.Created
                                                        }
                                                    >
                                                        {getFetchMethodText(
                                                            FetchMethod.Created
                                                        )}
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={
                                                            FetchMethod.LastUpdated
                                                        }
                                                    >
                                                        {getFetchMethodText(
                                                            FetchMethod.LastUpdated
                                                        )}
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={
                                                            FetchMethod.HLinkResultReceivedAt
                                                        }
                                                    >
                                                        {getFetchMethodText(
                                                            FetchMethod.HLinkResultReceivedAt
                                                        )}
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={
                                                            FetchMethod.BatchNumber
                                                        }
                                                    >
                                                        {getFetchMethodText(
                                                            FetchMethod.BatchNumber
                                                        )}
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={FetchMethod.All}
                                                    >
                                                        {getFetchMethodText(
                                                            FetchMethod.All
                                                        )}
                                                    </MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Collapse in={showDates()}>
                                            <DatePicker
                                                fromDate={fromDate}
                                                toDate={toDate}
                                                handleDateFromChange={
                                                    setFromDate
                                                }
                                                handleDateToChange={setToDate}
                                            />
                                        </Collapse>
                                        <Collapse
                                            in={
                                                fetchMethod ===
                                                FetchMethod.BatchNumber
                                            }
                                        >
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                placeholder="Batch Number"
                                                onBlur={(e) => {
                                                    setBatchNumberToFetch(
                                                        parseInt(e.target.value)
                                                    );
                                                }}
                                            />
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid
                                    justifyContent="flex-start"
                                    container
                                    spacing={1}
                                >
                                    <Grid item>{fetchButton()}</Grid>
                                    <Grid item>
                                        <SubmitButton
                                            dataToSubmit={filteredData}
                                            showButton={
                                                selectedStatusGroup.indexOf(
                                                    "new"
                                                ) !== -1 &&
                                                selectedStatusGroup.length ===
                                                1 &&
                                                province === "AB"
                                            }
                                            refreshRecords={getBillingRecords}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Filters
                                    enableFilter={enableFilter}
                                    setEnableFilter={setEnableFilter}
                                    filters={filters}
                                    setFilters={setFilters}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            </Grid>
            <div className={classes.loadingStatus}>
                <ProcessStatus
                    state={loadingRecordsState}
                    errorMessage={loadingRecordsError}
                    successMessage={`Fetched ${billingData.length} records`}
                    useSnackbar
                    setState={setLoadingRecordsState}
                />
            </div>

            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"small"}
                    stickyHeader
                >
                    <BillingTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {filteredData.length > 0 && !isLoadingBillingCodes && !isLoadingBillingCodesV2 &&
                            stableSort(
                                filteredData,
                                getComparator(order, orderBy)
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((frontendData: FrontendData) => {
                                    return (
                                        <BillingTableRow
                                            data={frontendData}
                                            key={frontendData.uid}
                                            billingCodes={billingCodes}
                                            billingCodesV2={billingCodesV2}
                                            handleUpdateRecord={
                                                handleUpdateRecord
                                            }
                                            reload={reloadTrigger}
                                        />
                                    );
                                })}
                        {filteredData.length === 0 && (
                            <TableRow tabIndex={-1}>
                                Click the fetch button to fetch records
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component={"div"}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </PaperPage>
    );
});

export default BillingTable;
