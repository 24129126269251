import { BillingRecord } from "../../../shared/types";
import { PayrollRow, SortableKeys, Order } from "./types";


function descendingComparator(a: PayrollRow, b: PayrollRow, orderBy: SortableKeys) {
    const aOrder = a[orderBy]
    const bOrder = b[orderBy]

    if (bOrder < aOrder) {
        return -1;
    }
    if (bOrder > aOrder) {
        return 1;
    }
    return 0;
}


export function getComparator(order: Order, orderBy: SortableKeys): (a: PayrollRow, b: PayrollRow) => number {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array: PayrollRow[], comparator: (a: PayrollRow, b: PayrollRow) => number) {
    const stabilizedThis = array.map((el: PayrollRow, index: number) => [el, index] as [PayrollRow, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) 
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export function round(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100
}


export function createInitialPayrollRecord(key: string, record: BillingRecord): PayrollRow {
    return {
        key: key,
        doctorName: `${record.firstName} ${record.lastName}`,
        totalEconsults: 0,
        totalCommunications: 0,
        totalEconsultRejections: 0,
        totalCommunicationRejections: 0,
        totalBilling: 0,
        totalExpectedBilling: 0,
        totalOwedToDoctor: 0,
        totalPaidToDoctor: 0,
        totalAmountPending: 0,
        totalAmountSubmitted: 0,
        totalAmountFailed: 0,
        recordsToPay: []
    }
}

