import { getDocumentData } from "@alethea-medical/utilities"
import { BillingCodeInfo, BillingCodeInfoEntry, BillingCodeTable, BillingCodeUserTypes, Province } from "../../../shared/types"
import db from "../../firebase/db"

export type BillingCodesDict = {
    [key: string]: BillingCodeInfoEntry
}

type CodesProvinceDict = {
    [key: string]: BillingCodesDict
}

const codesDict: CodesProvinceDict = {}

function getAvailableBillingCodes(province: Province): Promise<BillingCodesDict> {
    if(codesDict[province] === undefined) {
        return db.collection("billing_codes").doc(province).get()
        .then(getDocumentData)
        .then((codes: BillingCodeTable) => {
            const newBillingDict: BillingCodesDict = {}
            Object.values(codes).forEach((billingCodeType) => {
                // billingCodeType = { specialist: billingCodeInfo, physician:...}
                Object.values(billingCodeType).forEach((billingCodeInfo: BillingCodeInfo) => {
                    return Object.values(billingCodeInfo).forEach((billingCodeInfoEntry: BillingCodeInfoEntry) => {
                        if(newBillingDict[billingCodeInfoEntry.code] === undefined)
                            newBillingDict[billingCodeInfoEntry.code] = billingCodeInfoEntry
                    })
                })
            })
            codesDict[province] = newBillingDict
            return codesDict[province]
        })
        .catch((error: Error) => {
            //If province doesn't exist, just return empty list
            console.error(error)
            codesDict[province] = {}
            return codesDict[province]
        })
    }
    else {
        // Lazy loading
        return Promise.resolve(codesDict[province])
    }
}

export default getAvailableBillingCodes