import React, { useEffect, useState } from 'react';
import { Control } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import { dbNames, resourceKeys } from '@alethea-medical/aletheamd-db-keys';
import { useProcessState, ProcessState, ProcessStatus } from "@alethea-medical/alethea-components";
import app from '../../firebase';
import { RolePermissions } from '@alethea-medical/aletheamd-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import sortRolesInPlace from '../../shared/sortRolesInPlace';
import PermissionCard from '../EditRoles/PermissionCard';
import { Typography } from '@material-ui/core';

//Need to put roles in an object so that react hook form can use it in a UseFieldArray (it doesn't work with string arrays for some dumb reason)
export interface RoleAsObject {
    roleId: string
}

interface RolePermissionsWithKey {
    id: string,
    data: RolePermissions
}

interface FormRoleInputProps {
    control: Control,
    userRoles: RoleAsObject[],
    append: (roleObj: RoleAsObject) => void,
    remove: (index?: number | number[] | undefined) => void,
}

const FormRoleInput = ({ control, userRoles, append, remove  }: FormRoleInputProps) => {
    const getDoc = app.functions().httpsCallable("portal-getDoc");
    
    const [availableRoles, setAvailableRoles] = useState<RolePermissionsWithKey[]>([]);
    const [totalUserResourceAccess, setTotalUserResourceAccess] = useState<string[]>([]);
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const sortStringsInPlace = (strArray: string[]) => {
        strArray.sort((a, b) => {
            if(a < b)
                return -1;
            else if(a > b)
                return 1;
            return 0;
        })
    }

    useEffect(() => {
        setProcessState(ProcessState.running);
        getDoc({collection: dbNames.rolePermissions})
        .then((result) => {
            sortRolesInPlace(result.data)
            setAvailableRoles(result.data);
            setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error,
                userMessage: "Error fetching roles"
            })
        })
    }, [])


    
    //Update user resource access (make sure it only contains one of each resource key)
    useEffect(() => {
        const newAccess: string[] = [];
        userRoles.forEach((roleObj) => {
            const role = availableRoles.find((r) => r.id === roleObj.roleId);
            if(role !== undefined) {
                role.data.permissions.forEach((resourceKey) => {
                    if(!newAccess.includes(resourceKey)) {
                        newAccess.push(resourceKey);
                    }
                })
            }
        })
        sortStringsInPlace(newAccess);
        setTotalUserResourceAccess(newAccess);
    }, [userRoles, availableRoles])


    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12}>
                <Typography>
                    Roles
                </Typography>
            </Grid>
            <Grid item xs={12}>              
                <Autocomplete
                    multiple
                    options={availableRoles.map((r) => r.id)}
                    value={userRoles.map((role: RoleAsObject) => role.roleId)}
                    getOptionLabel={(option) => {
                        const name = availableRoles.find((r) => r.id === option)?.data?.name
                        return name ? name : "";
                    }}
                    filterSelectedOptions
                    onChange={(_, newValues: string[]) => {
                        sortStringsInPlace(newValues)
                        newValues.forEach((newRole) => {
                            const roleIdx = userRoles.findIndex((r) => r.roleId === newRole)
                            if(roleIdx === -1) {
                                append({roleId: newRole})
                            }
                        })
                        userRoles.forEach((oldRole, index) => {
                            const roleIdx = newValues.findIndex((r) => r === oldRole.roleId)
                            if(roleIdx === -1) {
                                remove(index)
                            }
                        })
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Add a role"
                            variant="outlined"
                            size="small"
                        />
                    }
                />
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">Permissions Granted</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    {Object.values(resourceKeys).map((resourceKey, index) => 
                        <Grid item xs={6} key={`resource_permission_view_profile_${resourceKey}_${index}`}>
                            <PermissionCard resourceKey={resourceKey} greyOut={!totalUserResourceAccess.includes(resourceKey)}/>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
            </Grid>
        </Grid>
    );
}

export default FormRoleInput;