import React from "react";
import {Card } from "react-bootstrap";
import {Divider} from '@material-ui/core';

type BodyProps = {
    title?: string,
    subtitle?: Element,

}

const Body = (props: any) => {
    return(
        <Card>
            <Card.Body>
                <Card.Title className="mb-3 heading">{props.title}</Card.Title>
                <Card.Subtitle className="mb-3 text-muted heading">{props.subtitle}</Card.Subtitle>                   

                <div className="mb-4">
                    <Divider/>
                </div>
                {props.children}
            </Card.Body>                
        </Card>
    );
}

export default Body;