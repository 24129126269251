import firebase from "firebase/app";
import 'firebase/functions';
import { firebaseConfigProd, firebaseConfigStaging, firebaseConfigDev, firebaseConfigDemo } from "./firebase-config";

type EnvType = "local" | "dev" | "staging" | "prod" | "demo"

let projectEnv: EnvType

let config: any;
switch(window.location.hostname) {
	case 'admin.aletheamedical.com':
		console.log("Using production config");
		config = firebaseConfigProd;
		projectEnv = "prod"
		break;
	case 'adminportal-staging-a905b.web.app':
		console.log("Using staging config");
		config = firebaseConfigStaging;
		projectEnv = "staging"
		break;
	case 'adminportal-demo-a905b.web.app':
		console.log("Using demo config");
		config = firebaseConfigDemo;
		projectEnv = "demo"
		break;
	case 'adminportal-dev-a905b.web.app':
		console.log("Using dev config");
		config = firebaseConfigDev;
		projectEnv ="dev" 
		break;
	case 'localhost':
		console.log("Localhost: using dev config");
		config = firebaseConfigDev;
		projectEnv ="dev"
		break;
	default: 
		console.log("No matching hostname, falling back to dev config");
		config = firebaseConfigDev;
		projectEnv ="dev"
		break;
}

const app = firebase.initializeApp(config);
if(process.env.NODE_ENV === "development") {
    console.log("Using functions emulator");
    app.functions().useFunctionsEmulator("http://localhost:5001");
}


export { projectEnv };

export default app;