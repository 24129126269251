import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import resourceToKeyText from "./resourceKeyToText";
import clsx from "clsx";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        permissionContainer: {
            padding: theme.spacing(1, 2),
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px"
        },  
        closeIcon: {
            padding: 0,
            color: "white"
        },
        greyOut: {
            backgroundColor: theme.palette.grey[300],
            color: theme.palette.grey[600],
        }
    }),
);
interface PermissionCardProps {
    resourceKey: string,
    greyOut?: boolean
}

const PermissionCard = ({ resourceKey, greyOut  }: PermissionCardProps) => {

    const classes = useStyles();

    return (
        <Paper className={clsx(classes.permissionContainer, {
            [classes.greyOut]: greyOut,
        })}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    {resourceToKeyText(resourceKey)}
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PermissionCard;