import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ProcessState } from "@alethea-medical/alethea-components";
import app from "../../firebase";
import { useState } from "react";
import { parse } from "json2csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography, TextField, Chip, Box } from "@material-ui/core";
import { saveAs } from "file-saver";

interface UserTrackingReportProps {
    fromDate: Date;
    toDate: Date;
    setProcessState: (state: ProcessState) => void;
    errorHandler: (error: { error?: any; userMessage?: string }) => void;
}

const UserTrackingReport = ({
    fromDate,
    toDate,
    setProcessState,
    errorHandler,
}: UserTrackingReportProps) => {
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [communicationCodes, setCommunicationCodes] = useState<string[]>([
        "03.01AD",
        "03.01S",
    ]);
    const [consultCodes, setConsultCodes] = useState<string[]>([
        "03.01R",
        "03.01O",
    ]);
    const [communicationInput, setCommunicationInput] = useState<string>("");
    const [consultInput, setConsultInput] = useState<string>("");

    // Firebase function to generate the report
    const generateUserTrackingReport = app
        .functions()
        .httpsCallable("generateUserTrackingReport");

    // Handler for generating the report
    const generateUserTrackingReportHandler = () => {
        setProcessState(ProcessState.running);
        setButtonDisabled(true);
        generateUserTrackingReport({
            dateFrom: fromDate.getTime(),
            dateTo: toDate.getTime(),
            communicationCodes,
            consultCodes,
        })
            .then((response) => {
                setButtonDisabled(false);
                setProcessState(ProcessState.idle);
                reportToCSV(response.data);
            })
            .catch((error) => {
                setButtonDisabled(false);
                errorHandler({ error });
            });
    };

    // Convert report data to CSV and trigger download
    const reportToCSV = (report: { [key: string]: any }) => {
        if (report.length === 0) {
            errorHandler({ userMessage: "No data to export" });
            return;
        }

        const csv = parse(report);
        const fileToSave = new Blob([csv], { type: "text/csv" });
        const fileName = `user_tracking_report_${fromDate
            .toLocaleString("en-US", { month: "short", year: "numeric" })
            .toLowerCase()
            .replace(/\s/g, "_")}_to_${toDate
            .toLocaleString("en-US", { month: "short", year: "numeric" })
            .toLowerCase()
            .replace(/\s/g, "_")}.csv`;
        saveAs(fileToSave, fileName);
    };

    // Add a new code to the respective list if it doesn't already exist
    const handleAddCode = (type: "communication" | "consult") => {
        if (type === "communication" && communicationInput.trim()) {
            const newCode = communicationInput.trim();
            if (!communicationCodes.includes(newCode)) {
                setCommunicationCodes([...communicationCodes, newCode]);
            }
            setCommunicationInput("");
        } else if (type === "consult" && consultInput.trim()) {
            const newCode = consultInput.trim();
            if (!consultCodes.includes(newCode)) {
                setConsultCodes([...consultCodes, newCode]);
            }
            setConsultInput("");
        }
    };

    // Remove a code from the respective list
    const handleDeleteCode = (
        type: "communication" | "consult",
        codeToDelete: string
    ) => {
        if (type === "communication") {
            setCommunicationCodes(
                communicationCodes.filter((code) => code !== codeToDelete)
            );
        } else {
            setConsultCodes(
                consultCodes.filter((code) => code !== codeToDelete)
            );
        }
    };

    // Handle 'Enter' key press to add a new code
    const handleKeyPress = (
        event: React.KeyboardEvent,
        type: "communication" | "consult"
    ) => {
        if (event.key === "Enter") {
            handleAddCode(type);
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Typography variant="h6">User Tracking Report</Typography>
            </Grid>
            <Grid item xs style={{ margin: "0 20px" }}>
                <TextField
                    size="small"
                    label="Communication Codes"
                    value={communicationInput}
                    onChange={(e) => setCommunicationInput(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, "communication")}
                    fullWidth
                />
                <Box mt={1}>
                    {communicationCodes.map((code) => (
                        <Chip
                            key={code}
                            label={code}
                            onDelete={() =>
                                handleDeleteCode("communication", code)
                            }
                            size="small"
                            style={{ margin: "0 4px 4px 0" }}
                        />
                    ))}
                </Box>
            </Grid>
            <Grid item xs style={{ margin: "0 20px" }}>
                <TextField
                    size="small"
                    label="Consult Codes"
                    value={consultInput}
                    onChange={(e) => setConsultInput(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, "consult")}
                    fullWidth
                />
                <Box mt={1}>
                    {consultCodes.map((code) => (
                        <Chip
                            key={code}
                            label={code}
                            onDelete={() => handleDeleteCode("consult", code)}
                            size="small"
                            style={{ margin: "0 4px 4px 0" }}
                        />
                    ))}
                </Box>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={buttonDisabled}
                    onClick={generateUserTrackingReportHandler}
                    startIcon={<GetAppIcon />}
                    size="small"
                >
                    Generate Report CSV
                </Button>
            </Grid>
        </Grid>
    );
};

export default UserTrackingReport;
