import { Fcm } from "@alethea-medical/aletheamd-types";
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { TableCell, TableRow, Typography, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CampaignItem } from "./GetCampaigns";
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import app from "../../firebase";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        completed: {
            backgroundColor: theme.palette.grey[200],
        },
        draft:{
            backgroundColor: theme.palette.secondary.light,
        },
        failed: {
            backgroundColor: theme.palette.error.light,
        },
        noOverflowCol: {
            maxWidth: "200px",
            overflow: "hidden", 
        },
        bodyText: {
            maxWidth: "200px",
            overflow: "hidden",
            fontSize: "1.0em",
            color: theme.palette.grey[800],
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        }
    })
)

interface CampaignRowProps {
    id: string,
    campaign: Fcm.Campaign,
    setCampaign: (campaign: CampaignItem) => void,
    updateCampaign: (id: string, campaign?: Partial<Fcm.Campaign>) => void
}

const CampaignRow = ({ id, campaign, setCampaign, updateCampaign }: CampaignRowProps) => {
    const classes = useStyles();


    const fcmCampaign = app.functions().httpsCallable('portalV2-fcmCampaign');
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ });

    const editHandler = () => {
        setCampaign({
            id,
            data: campaign
        })
    }

    const viewHandler = () => {
        setCampaign({
            id,
            data: campaign
        })
    }

    const sendHandler = () => {
        if(window.confirm(`Are you sure you want to send campaign "${campaign.name}"`)) {
            setProcessState(ProcessState.running);
            fcmCampaign({ method: "SEND", campaignId: id })
            .then((result) => {
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 1000)
                updateCampaign(result.data.id, result.data.data)
            })
            .catch((error: Error) => {
                errorHandler({
                    error,
                    userMessage: "Failed to send campaign"
                })
            })
        }
    }

    const deleteHandler = () => {
        if(window.confirm(`Are you sure you want to delete campaign "${campaign.name}"`)) {
            setProcessState(ProcessState.running);
            fcmCampaign({ method: "DELETE", campaignId: id })
            .then((result) => {
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 1000)
                console.log(result)
                updateCampaign(result.data.id, undefined)
            })
            .catch((error: Error) => {
                errorHandler({
                    error,
                    userMessage: "Failed to delete campaign"
                })
            })
        }
    }

    return (
        <TableRow
            className={clsx({
                [classes.completed]: campaign.status === "completed",
                [classes.draft]: campaign.status === "draft",
                [classes.failed]: campaign.status === "failed",
            })}
        >
            <TableCell className={classes.noOverflowCol}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{campaign.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{campaign.title}</Typography>
                        <Typography className={classes.bodyText}>{campaign.body}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                {campaign.created.toDate().toLocaleString()}
            </TableCell>
            <TableCell>
                {campaign.sentAt ? campaign.sentAt.toDate().toLocaleString() : "Not Sent"}
            </TableCell>
            <TableCell>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2" style={{ textAlign: "center"}}>{campaign.status.slice(0, 1).toUpperCase() + campaign.status.slice(1)}</Typography>
                    </Grid>
                    {(campaign.status === "failed") && 
                        <Grid item xs={12}>
                            {campaign.error}
                        </Grid>
                    }
                    {(campaign.status === "completed") && (
                        <>
                            <Grid item>
                                <Tooltip
                                    title="View Campaign"
                                >
                                    <IconButton onClick={viewHandler} >
                                        <ViewIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                        </>
                    )}
                    {(campaign.status === "draft" || campaign.status === "failed") && (
                        <>
                            <Grid item>
                                <Tooltip
                                    title="Edit Campaign"
                                >
                                    <IconButton onClick={editHandler} >
                                        <EditIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    title="Delete Campaign"
                                >
                                    <IconButton onClick={deleteHandler}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    title="Send Campaign to Users"
                                >
                                    <IconButton onClick={sendHandler}>
                                        <SendIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                {campaign.sends}
            </TableCell>
            <TableCell>
                {campaign.opens}
            </TableCell>
            <TableCell>
                {campaign.sends > 0 ? Math.round(campaign.opens / campaign.sends * 100) : 0}%
            </TableCell>

        </TableRow>
    );
}

export default CampaignRow;