type Operation = "set" | "update" | "delete";

export default class BatchArray {

    db: firebase.firestore.Firestore;
    batchArray: firebase.firestore.WriteBatch[];
    batchIndex: number;
    operationCounter: number;
    totalOperations: number;

    constructor(db: firebase.firestore.Firestore) {
        this.db = db;
        this.batchArray = [db.batch()];
        this.batchIndex = 0;
        this.operationCounter = 0;
        this.totalOperations = 0;
    }

    operate(operation: Operation, doc: firebase.firestore.DocumentReference, updateObject: any) {
        switch(operation) {
            case "set":
                this.batchArray[this.batchIndex].set(doc, updateObject);
                break;
            case "update":
                this.batchArray[this.batchIndex].update(doc, updateObject);
                break;
            case "delete":
                this.batchArray[this.batchIndex].delete(doc);
                break;
        }

        this.operationCounter++;
        this.totalOperations++;
        if (this.operationCounter === 499) {
            console.log(`Batch limit reached, creating new batch: ${this.batchIndex}`);
            this.batchArray.push(this.db.batch());
            this.batchIndex++;
            this.operationCounter = 0;
        }
    }

    commit() {
        return Promise.all(this.batchArray.map((batch) => {
            return batch.commit();
        }))
    }
}