import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

interface InternalValueTextFieldProps {
    value: string,
    onValueChange: (value: string) => boolean | void
    label?: string,
    fullWidth?: boolean
}

const InternalValueTextField = ({ value, onValueChange, label, fullWidth }: InternalValueTextFieldProps) => {
    
    const [internalValue, setInternalValue] = useState<string>("");

    useEffect(() => {
        setInternalValue(value);
    }, [value])

    const blurOnEnterPressed = (event: any) => {
        if (event.keyCode === 13) {
          event.target.blur();
        }
    }

    return (
        <TextField 
            label={label}
            fullWidth={fullWidth}
            value={internalValue} 
            onChange={(e: any) => {
                setInternalValue(e.target.value);
            }} 
            onBlur={(e: any) => {
                const valid = onValueChange(e.target.value);
                if(valid === false)// If invalid operation, reset internal value
                    setInternalValue(value)
            }}  
            onKeyDown={blurOnEnterPressed}></TextField>
    );
}

export default InternalValueTextField;