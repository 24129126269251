import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { UserProfile } from '@alethea-medical/aletheamd-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            color: "white",
            padding: theme.spacing(0, 1),
            backgroundColor: theme.palette.primary.main
        }
    })
)
interface UserRowProps {
    userId: string,
    user: UserProfile,
    doTriage: boolean,
    isConsultant: boolean,
    isTriager: boolean,
    consultantHandler: (uid: string, checked: boolean) => void,
    triagerHandler: (uid: string, checked: boolean) => void
    removeUserHandler: (uid: string) => void
}

const UserRow = ({ userId, user, doTriage, isConsultant, isTriager, consultantHandler, triagerHandler, removeUserHandler }: UserRowProps) => {
    const classes = useStyles();
    const isSpecialist = user.userRoles.includes("specialist")
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={1} alignItems="center" justifyContent='space-between'>
                <Grid item xs={3}>
                    <Typography>{user.firstName} {user.lastName}</Typography>
                </Grid>
                <Grid item xs={4}>
                    {/* Only show checkbox if user is a specialist */}
                    {isSpecialist && 
                        <FormControlLabel
                            label="Consultant"
                            onChange={(_, checked) => { consultantHandler(userId, checked)}}
                            checked={isConsultant}
                            control={
                                <Checkbox 
                                    disableRipple  
                                />
                            }
                        />
                    }
                    {!isSpecialist && 
                        <Typography>Not a Specialist</Typography>
                    }
                </Grid>
                {doTriage && 
                    <Grid item xs={4}>
                        {isSpecialist && 
                            <FormControlLabel
                                label="Triager"
                                onChange={(_, checked) => { triagerHandler(userId, checked)}}
                                checked={isTriager}
                                control={
                                    <Checkbox 
                                        disableRipple  
                                    />
                                }
                            />
                        }
                    </Grid>
                }
                <Grid item xs={1}>
                    <IconButton onClick={() => removeUserHandler(userId)}>
                        <RemoveCircleOutlineIcon style={{color: "white"}}/>
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>

    );
}

export default UserRow;