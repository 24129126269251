import React from "react";
import { TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SortableKeys, Order } from "./types";


interface HeadCell {
	id: SortableKeys;
	label: string;
}


export const headCells: HeadCell[] = [
	{ id: 'status', label: 'Status' },
	{ id: 'created', label: 'Service Date' },
	{ id: 'lastUpdatedAt', label: 'Last Updated'},
	{ id: 'submittedAt', label: 'Submitted At' },
	{ id: 'patientId', label: 'PHN' },
	{ id: 'firstName',  label: 'Doctor Name' },	
	{ id: 'type',  label: 'Type' },
	{ id: 'subType', label: 'Sub-Type'},
	{ id: 'billingCode', label: 'Billing Code' },
];

interface BillingTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: SortableKeys) => void;
    order: Order,
    orderBy: string;
}

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	sortingBy: {
		color: theme.palette.primary.main
	},
	notSortingBy: {
		color: theme.palette.grey[600]
	}
}));

export const BillingTableHead = ({ order, orderBy, onRequestSort }: BillingTableHeadProps) => {
    const classes = useStyles(); 

    const createSortHandler = (property: SortableKeys) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };  


	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'left'}
						padding={'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							
							{orderBy === headCell.id ? (
								<>
									<span className={classes.sortingBy}>{headCell.label}</span>
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
								</>
							) : 
								<>
									<span className={classes.notSortingBy}>{headCell.label}</span>
								</>
							}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell
					key={'More Info'}
					align={'left'}
					padding={'normal'}
					className={classes.notSortingBy}
				>
					More Info
				</TableCell>
			</TableRow>
		</TableHead>
	);
}
