import React from "react";

const Header = () => {
    return (
    <header>
        <div className="row">
        <div className="offset-md-4 col-md-4 header-image mb-3">
                <img src="/logo_text.png" className="img-fluid" alt="Alethea Logo"/>
            </div>
        </div>
        <div className="row">
            <div className="offset-md-4 col-md-4 header-text mb-3">
                <h5>A D M I N &nbsp; P O R T A L</h5>
            </div>
        </div>

    </header>
    )
}

export default Header;