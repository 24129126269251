import { useProcessState } from "@alethea-medical/alethea-components";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import { Grid, InputLabel } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";
import app from "../../firebase";
import FormTextField from "../../components/FormTextField";
import FormTextArea from "../../components/FormTextArea";
import { Fcm } from "@alethea-medical/aletheamd-types";


interface CampaignFormProps<T> {
    mode: T,
    updateCampaign: (id: string, campaign: Partial<Fcm.Campaign>) => void
}

interface CampaignFormPropsCreate extends CampaignFormProps<"create"> {
    campaignId: undefined,
    campaign: undefined
}

interface CampaignFormPropsEdit extends CampaignFormProps<"edit" | "view"> {
    campaignId: string,
    campaign: Fcm.Campaign
}

const CampaignForm = ({ mode, campaignId, campaign, updateCampaign }: CampaignFormPropsCreate | CampaignFormPropsEdit) => {
    const fcmCampaign = app.functions().httpsCallable('portalV2-fcmCampaign');
    const { handleSubmit, control, reset } = useForm({ mode: "onTouched", defaultValues: {
        name: campaign?.name ?? "",
        title: campaign?.title ?? "",
        body: campaign?.body ?? "",
        route: campaign?.route ?? ""
    } });

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const onSubmit = (data: any) => {
        if(mode === "view")
            return

        setProcessState(ProcessState.running);
        const request: any = { 
            method: mode === "create" ? "PUT" : "PATCH",
            options: {
                name: data.name,
                title: data.title,
                body: data.body,
                route: data.route,
            }
        }
        if(mode === "edit")
            request.campaignId = campaignId;

        fcmCampaign(request)
            .then((result) => {
                if(mode === "create") reset();

                updateCampaign(result.data.id, result.data.data);
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 1000)
            })
            .catch((error: Error) => {
                errorHandler({
                    userMessage: error.message,
                    error: error
                })
            })
    }

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors."
        })
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success || mode === "view";
    }

    
    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <fieldset disabled={isDisabled()} >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormTextField control={control as any} name="title" label="Title" rules={{ required: { value: true, message: "Title is required" } }} disabled={isDisabled()}/>
                        <InputLabel>The title of the notification that is shown to the user.</InputLabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField control={control as any} name="name" label="Name (Defaults to Title if empty)" disabled={isDisabled()}/>
                        <InputLabel>Name is for admin use only. Use it to track which campaign is which.</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextArea control={control as any} name="body" label="Body" initRows={3} rules={{ required: { value: true, message: "Body is required" } }} disabled={isDisabled()}/>
                        <InputLabel>The body is shown when the user expands or views the notification.</InputLabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField control={control as any} name="route" label="Route" disabled={isDisabled()}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>If route is provided, the user will be sent to the provided route when the push notification is opened.</InputLabel>
                        <InputLabel>Example: /dashboard/gallery</InputLabel>
                    </Grid>
                    {mode !== "view" && 
                        <Grid item xs={12}>
                            <Button disabled={isDisabled()} variant="outlined" color="primary" type="submit">{mode === "create" ? "Create Draft" : "Save Changes"}</Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={`Campaign ${mode}d`} />
                    </Grid>
                </Grid>
            </fieldset>
        </form>
    );
}

export default CampaignForm;