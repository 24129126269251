import { Control, SetFieldValue, useWatch } from "react-hook-form";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import FormTextField from "../../components/FormTextField";
import FormNumberField from "../../components/FormNumberField";
import FormCheckbox from "../../components/FormCheckbox";
import EmailInput from "../../components/EmailInput";
import PhoneInput from "../../components/PhoneInput";
import { useEffect, useState } from "react";

interface ProfileInputProps {
    control: Control;
    setValue: SetFieldValue<any>;
}
//User profile text fields: first name, last name, preferences, is specialist.
const FormProfileInput = ({ control, setValue }: ProfileInputProps) => {
    const [showNotifSettings, setShowNotifSettings] = useState(false);

    const incomingSecureMessagesEnabled = useWatch({
        control,
        name: "preferences.fax.secureMessage.incoming.enabled",
    });

    useEffect(() => {
        if (incomingSecureMessagesEnabled !== true) {
            setValue(
                "preferences.fax.secureMessage.incoming.options.onlyFirstConsultantMessage",
                false
            );
        }
    }, [incomingSecureMessagesEnabled]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormTextField
                        control={control}
                        name="firstName"
                        label="First Name"
                        rules={{
                            required: {
                                value: true,
                                message: "First Name is required",
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        control={control}
                        name="lastName"
                        label="Last Name"
                        rules={{
                            required: {
                                value: true,
                                message: "Last Name is required",
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormNumberField
                        control={control}
                        name="practiceId"
                        label="Practice ID"
                        rules={{
                            required: {
                                value: true,
                                message: "Practice ID is required",
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        control={control}
                        name="ban"
                        label="BAN"
                        rules={{
                            required: {
                                value: true,
                                message: "Ban is required",
                            },
                        }}
                    />
                </Grid>
                <Tooltip title="Ex: MD, MSc, BSc" placement="bottom" arrow>
                    <Grid item xs={6}>
                        <FormTextField
                            control={control}
                            name="credentials"
                            label="Credentials"
                        />
                    </Grid>
                </Tooltip>
                <Tooltip
                    title="Ex: Adult Otolaryngologist"
                    placement="bottom"
                    arrow
                >
                    <Grid item xs={6}>
                        <FormTextField
                            control={control}
                            name="title"
                            label="Title"
                        />
                    </Grid>
                </Tooltip>
                <Tooltip
                    title="This will be synced up with the user's specialist profile"
                    placement="bottom"
                    arrow
                >
                    <Grid item xs={6}>
                        <FormTextField
                            control={control}
                            name="skillCode"
                            label="Skill Code"
                            rules={{
                                validate: {
                                    length: (value: any) =>
                                        value === "" ||
                                        value.length === 4 ||
                                        "Must be 4 characters",
                                    uppercaseOrSpace: (value: any) =>
                                        value === "" ||
                                        /^[A-Z ]+$/.test(value) ||
                                        "Must be all capital letters or spaces",
                                },
                            }}
                        />
                    </Grid>
                </Tooltip>
                <Grid item xs={12}>
                    <Button
                        onClick={() => setShowNotifSettings(!showNotifSettings)}
                        endIcon={
                            showNotifSettings ? (
                                <ExpandLessIcon />
                            ) : (
                                <ExpandMoreIcon />
                            )
                        }
                    >
                        Notification Settings
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={showNotifSettings}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Fax settings
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FormCheckbox
                                            name="preferences.fax.forms"
                                            control={control}
                                            label="Patient forms"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCheckbox
                                            name="preferences.fax.econsult.outgoing.enabled"
                                            control={control}
                                            label="Outgoing consults"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCheckbox
                                            name="preferences.fax.econsult.incoming.enabled"
                                            control={control}
                                            label="Incoming consults"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCheckbox
                                            name="preferences.fax.secureMessage.outgoing.enabled"
                                            control={control}
                                            label="Outgoing secure messages"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCheckbox
                                            name="preferences.fax.secureMessage.incoming.enabled"
                                            control={control}
                                            label="Incoming secure messages"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginLeft: "30px" }}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <FormCheckbox
                                                    name="preferences.fax.secureMessage.incoming.options.onlyFirstConsultantMessage"
                                                    control={control}
                                                    label="Only send a fax for the first specialist response on a consult where this user is the referrer"
                                                    disabled={
                                                        !incomingSecureMessagesEnabled
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCheckbox
                                        name="preferences.fax.faxPdfSettings.includeImages"
                                        control={control}
                                        label="Include attached images in faxes"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCheckbox
                                        name="preferences.fax.faxPdfSettings.includePdfs"
                                        control={control}
                                        label="Include attached PDFs in faxes"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Email Notifications
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.email.secureMessaging"
                                    control={control}
                                    label="Receive email notifications for new secure messages."
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.email.submittedPatientForms"
                                    control={control}
                                    label="Receive an email notification when a patient submits a form."
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.email.failedEmails"
                                    control={control}
                                    label="Receive email notifications for patient emails that have failed to send."
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.email.overdueSecureMessages"
                                    control={control}
                                    label="Receive email notifications for suggested eConsults to reply to (Specialists Only)"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Mobile Push Notifications
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.mobilePushNotifications.secureMessaging"
                                    control={control}
                                    label="Receive mobile push notifications for new messages."
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.mobilePushNotifications.econsult"
                                    control={control}
                                    label="Receive mobile push notifications for new eConsults."
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.mobilePushNotifications.aletheaNews"
                                    control={control}
                                    label="Receive mobile push notifications for Alethea News."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Secure Messaging Settings
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormCheckbox
                                    name="preferences.secureMessaging.nudgeOverdueMessages"
                                    control={control}
                                    label="Suggested eConsults to reply to appear at the top of your inbox (Specialists Only)"
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Phone Consult Settings</Typography>
                </Grid>
                <Grid item xs={6}>
                    <PhoneInput
                        name="preferences.phoneConsult.phoneNumber"
                        control={control}
                        label="Phone number for phone consults"
                    />
                </Grid>
                <Grid item xs={6}>
                    <EmailInput
                        name="preferences.phoneConsult.calendarEmail"
                        control={control}
                        label="Email to send phone consult calendar invites to"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Billing Preferences</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormCheckbox
                        name="billingPreferences.banNotReceived"
                        control={control}
                        label="BAN not received, move new billing records to On Hold automatically."
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormCheckbox
                        name="billingPreferences.useSEmailCode"
                        control={control}
                        label="Use 03.01S code for patient message instead of 03.01AD"
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default FormProfileInput;
