

// params:
// base filter conditions (for specialists)

import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import app from '../../firebase';

// return type (what data are we including and what type is it?) can be put as <T> generic
//  - this is diffucult to do without a callback function
//  - could consider just returning the user id and have the calling component get the user data <-- lets do this first

interface UserDropdownUser {
    id: string,
    firstName: string,
    lastName: string,
}

interface useUserDropdownProps {
    disabledUids?: string[],
    typesenseFilterString?: string,
}

function useUserDropdownV2({ disabledUids, typesenseFilterString }: useUserDropdownProps = {}) {

    const searchUsers = app.functions().httpsCallable("searchUsers");

    const [ users, setUsers ] = useState<UserDropdownUser[]>([]);
    const [ selectedUid, setSelectedUid ] = useState<string | undefined>();
    const [ inputValue, setInputValue ] = useState<string>("");
    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});

    const getUsers = () => {
        setProcessState(ProcessState.running);
        searchUsers({ q: inputValue, filterString: typesenseFilterString })
            .then(({ data }) => {
                setProcessState(ProcessState.idle);
                setUsers(data.hits.map((hit: any) => hit.document));
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: error.message
                })
            })
    }

    const isUserDisabled = (user: UserDropdownUser) : boolean => {
        return disabledUids?.includes(user.id) || user.id === selectedUid;
    }

    useEffect(() => {
        inputValue.length > 0 ? getUsers() : setUsers([]);
    }, [inputValue]);

    const createDropdown = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage} setState={setProcessState} loadingMessage="Searching..." hideProgressBar useSnackbar/>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={users}
                            getOptionLabel={(option: UserDropdownUser) => `${option.firstName} ${option.lastName}`}
                            onChange={(event: any, newValue: UserDropdownUser | null) => {
                                if (newValue !== null)
                                    setSelectedUid(newValue.id);
                                else
                                    setSelectedUid(undefined);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Type to search for a user"
                                    variant="outlined"
                                />
                            }
                            getOptionDisabled={isUserDisabled}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
        
    return {
        selectedUid,
        createDropdown,
    }


}


export default useUserDropdownV2;