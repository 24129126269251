import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Divider from '@material-ui/core/Divider';
import { PaperPage } from "@alethea-medical/react-components";
import QueueList from "./QueueList";
import parseJSONTimestamp from "../../helpers/parseJSONTimestamp";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
    }),
);

function createTimestampString(obj: any)
{
    return parseJSONTimestamp(obj)?.toDate().toLocaleString() ?? "Cannot Parse";
}


const QueueResend = () => {
    const classes = useStyles();

    return (
        <PaperPage className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Queue Resend</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <QueueList name="Fax Queue" collectionName="fax_queue" sortByTimestampKey="lastSentAt" 
                        columns={[
                            { key: "lastSentAt", name: "Last Sent At", gridSize: 2, renderValue: createTimestampString},
                            { key: "created", name: "Created", gridSize: 2, renderValue: createTimestampString},
                            { key: "retries", name: "Retries", gridSize: 1 },
                            { key: "errors", name: "Status/Error", gridSize: 2, renderValue: (errors: string[]) => 
                                errors.every((err: string) => err === "") ? "Sending" : errors.join(", ")
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <QueueList name="Email Queue" collectionName="email_queue" sortByTimestampKey="created"
                        columns={[
                            { key: "created", name: "Created", gridSize: 3, renderValue: createTimestampString},
                            { key: "delay", name: "Delayed Send", gridSize: 2, renderValue: (delay: boolean) => delay ? "Yes" : "No"},
                            { key: "error", name: "Status/Error", gridSize: 3, renderValue: (error?: string) => (!error || error === "") ? "Sending" : error},
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <QueueList name="Econsult Fax Queue" collectionName="econsult_fax_queue" sortByTimestampKey="created"
                        columns={[
                            { key: "created", name: "Created", gridSize: 2, renderValue: createTimestampString},
                            { key: "messageType", name: "Message Type", gridSize: 2 },
                            { key: "error", name: "Status/Error", gridSize: 3, renderValue: (error?: string) => (!error || error === "") ? "Sending" : error},
                        ]}
                    
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <QueueList name="AVA Queue" collectionName="ava_queue" sortByTimestampKey="created"
                        columns={[
                            { key: "created", name: "Created", gridSize: 2, renderValue: createTimestampString},
                            { key: "error", name: "Status/Error", gridSize: 6, renderValue: (error?: string) => (!error || error === "") ? "Sending" : error},
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <QueueList name="Patient CC Queue" collectionName="patient_cc_queue" sortByTimestampKey="created"
                        columns={[
                            { key: "created", name: "Created", gridSize: 2, renderValue: createTimestampString},
                            { key: "error", name: "Status/Error", gridSize: 6, renderValue: (error?: string) => (!error || error === "") ? "Sending" : error},
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <QueueList name="Secure Messages" collectionName="messages" parentCollectionName={"activities"} sortByTimestampKey="sentAt" isCollectionGroup queryField="handleCreatedError"
                        columns={[
                            { key: "sentAt", name: "Sent At", gridSize: 2, renderValue: createTimestampString},
                            { key: "handleCreatedError", name: "Status/Error", gridSize: 6, renderValue: (error?: string) => (!error || error === "") ? "Sending" : error},
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>

                <Grid item xs={12}>
                    <QueueList name="Patient Messages" collectionName="patient_messages" sortByTimestampKey="created" queryField="updateBillingRecordError"
                        columns={[
                            { key: "created", name: "Created", gridSize: 2, renderValue: createTimestampString},
                            { key: "updateBillingRecordError", name: "Status/Error", gridSize: 6, renderValue: (error?: string) => (!error || error === "") ? "Sending" : error},
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                
            </Grid>
        </PaperPage >
    );
}

export default QueueResend;
