import React, { useContext, useEffect, useState }  from "react";
import clsx from 'clsx';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { ListItemIcon } from "@material-ui/core";
import { Link } from 'react-router-dom';

import { withRouter } from "react-router-dom";


import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Drawer from '@material-ui/core/Drawer'
import Tooltip from '@material-ui/core/Tooltip';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import PersonAdd from '@material-ui/icons/PersonAdd'
import PaymentIcon from '@material-ui/icons/Payment';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PeopleIcon from '@material-ui/icons/People';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

import { AuthContext } from "../../AuthProvider";
import versionStrings from "../../versionStrings";
import { projectEnv } from "../../firebase";

const drawerWidth = 230;

const useStyles = makeStyles((theme: Theme) => ({

    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),       
    },
    icon: {
        minWidth: '45px',
        
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    header: {
        marginLeft: theme.spacing(2)
    },
    headerOpen: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    headerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeIn,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
    drawerButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
    },
    drawerButtonSelected: {
        backgroundColor: theme.palette.grey[200]
    },
    username: {
        whiteSpace: "normal",
    },
    versionString: {
        color: theme.palette.primary.main,
        fontSize: "0.75em"
    }
}))

interface SidebarItemProps {
    text: string,
    tooltipText?: string,
    textClass?: string,
    showText: boolean,
    link: string,
    icon: React.ReactElement,
    currentPath: string | undefined
}

const SidebarItem = ({text, tooltipText, textClass, showText, link, icon, currentPath, ...rest}: SidebarItemProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState<boolean>(false);

    useEffect(() => {
        setSelected(currentPath === link)
    }, [currentPath])

    return (
        <ListItem className={`${classes.drawerButton} ${selected ? classes.drawerButtonSelected : ""}`} button component={Link} to={link} {...rest}>
            <ListItemIcon className={classes.icon}>
                <Tooltip
                    title={tooltipText ? tooltipText : text}
                    children={icon}
                />
            </ListItemIcon>
            <Fade in={showText}>
                <ListItemText className={textClass} primary={text}/>
            </Fade>
        </ListItem>
    );
}

const Sidebar = withRouter(({ location }) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    
    const [open, setOpen] = useState(true);
    const [showText, setShowAletheaText] = useState(true);

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        if(open) {
            //Delay showing text so that it doesn't show up below the logo before the sidebar has time to open enough
            setTimeout(() => {
                setShowAletheaText(open);
            }, 200)
        }
        else {
            setShowAletheaText(open);
        }

    }, [open])


    return (
        <>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
                }}
            >               
                <List color="primary">  
                    <ListItem >
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <img src="/logo_big.png" className="img-fluid" alt="Alethea Logo" width="40" height="40"/>
                            </Grid>
                            {showText &&
                            <Grid item xs={1}>
                                <Fade in={showText}>
                                    <Typography className={classes.header} variant="h6" color="primary">
                                        Alethea Admin
                                    </Typography>
                                </Fade>
                            </Grid>
                            }
                        </Grid>
                    </ListItem>
                    <Divider/>
                    {authContext?.profile?.roles?.billingAdmin === true && 
                        <>
                            <SidebarItem text={"Billing"} showText={showText} link={"/dashboard/billing"} icon={<PaymentIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Auto Payments"} showText={showText} link={"/dashboard/autopayments"} icon={<AccountBalanceIcon color="primary"/>}
                                currentPath={location.pathname}/>
                        </>
                    }
                    {(authContext?.profile?.roles?.billingViewer === true || authContext?.profile?.roles?.billingAdmin === true) && 
                        <>
                            <SidebarItem text={"Billing Summary"} showText={showText} link={"/dashboard/billing-summary"} icon={<ReceiptIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Reports"} showText={showText} link={"/dashboard/reports"} icon={<AssessmentIcon color="primary"/>}
                                currentPath={location.pathname}/>
                        </>
                    }
                    {authContext?.profile?.roles?.accountManager === true && 
                        <>
                            <SidebarItem text={"Edit Users"} showText={showText} link={"/dashboard/edit-users"} icon={<AccountCircleIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Login Stats"} showText={showText} link={"/dashboard/view-user-metadata"} icon={<DataUsageIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Edit Roles"} showText={showText} link={"/dashboard/edit-roles"} icon={<PeopleIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Edit Specialists"} showText={showText} link={"/dashboard/edit-specialists"} icon={<AccountTreeIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Edit Specialties"} showText={showText} link={"/dashboard/edit-specialties"} icon={<GroupWorkIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Edit Clinics"} showText={showText} link={"/dashboard/edit-clinics"} icon={<BusinessIcon color="primary"/>}
                                currentPath={location.pathname}/>
                            <SidebarItem text={"Create User"} showText={showText} link={"/dashboard/create-user"} icon={<PersonAdd color="primary"/>}
                                currentPath={location.pathname}/>
                        </>
                    }
                    {authContext?.profile?.roles?.campaignAdmin === true && 
                        <SidebarItem text={"Campaigns"} showText={showText} link={"/dashboard/notification-campaigns"} icon={<AddAlertIcon color="primary"/>}
                            currentPath={location.pathname}/>
                    }

                    {authContext?.profile?.roles?.operations === true && 
                        <>

                            <SidebarItem text={"Queue Resend"} showText={showText} link={"/dashboard/queue-resend"} icon={<QueuePlayNextIcon color="primary"/>}
                                currentPath={location.pathname}/>
                        </>
                    }
                    <SidebarItem text={"Logout"} showText={showText} link={"/auth/logout"} icon={<ExitToAppIcon color="primary"/>}
                        currentPath={location.pathname}/>
                    <Divider/>
                </List>
                <div className={classes.toolbar}>
                    {open ?
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                        :
                        <IconButton onClick={handleDrawerOpen}>
                            <ChevronRightIcon />
                        </IconButton>
                    }
                </div> 
                {showText && 
                    <ListItem>
                        <Typography className={classes.versionString}>
                            Version {versionStrings.version}{projectEnv !== "prod" ? `, Build ${versionStrings.devBuild}` : ""}
                        </Typography>
                    </ListItem>
                }
                {(showText && projectEnv !== "prod") && (
                    <ListItem>
                        <Typography className={classes.versionString}>
                            Environment: {projectEnv.toUpperCase()}
                        </Typography>
                    </ListItem>
                )}

            </Drawer>
        </>
        );
})

export default Sidebar