import React, { useEffect, useState } from 'react';
import { PaperModal, useProcessState, ProcessState, ProcessStatus } from "@alethea-medical/alethea-components";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import app from '../../firebase';
import { RolePermissions } from '@alethea-medical/aletheamd-types';
import { dbNames, defaultRoles } from '@alethea-medical/aletheamd-db-keys';
import { RolePermissionsWithKey } from './EditRoles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';

interface CreateRoleProps {
    currentRoles: RolePermissionsWithKey[],
    updateRole: (roleId: string, data: RolePermissions, addRole?: boolean) => void
}

const CreateRole = ({ currentRoles, updateRole }: CreateRoleProps) => {

    const [showCreateRole, setShowCreateRole] = useState(false);
    const [newRoleId, setNewRoleId] = useState("");
    const [newRoleName, setNewRoleName] = useState("");

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const setDoc = app.functions().httpsCallable("portal-setDoc");

    const createRoleHandler = () => {
        if(newRoleId !== "" && newRoleName !== "") {
            if(currentRoles.every((r) => {
                return r.id !== newRoleId;
            })) {
                setProcessState(ProcessState.running);
                const newRoleData: RolePermissions = { name: newRoleName, description: "", permissions: []}
                setDoc({ collection: dbNames.rolePermissions, id: newRoleId, data: newRoleData })
                .then(() => {
                    updateRole(newRoleId, newRoleData, true);
                    setShowCreateRole(false);
                    reset();
                })
                .catch((error: Error) => {
                    errorHandler({
                        userMessage: error.message,
                        error: error
                    })
                })
            }
            else {
                errorHandler({
                    userMessage: `The role ID ${newRoleId} already exists. Please use a different ID.`
                })
            }
        }
        else {
            errorHandler({
                userMessage: "Please provide both a role ID and a role name."
            })
        }
    }

    const reset = () => {
        setProcessState(ProcessState.idle);
        setNewRoleId("");
    }

    useEffect(() => {
        if(!showCreateRole)
            reset();
    }, [showCreateRole])

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }

    const isInvalid = () => {
        return newRoleId === "" || newRoleName === "";
    }

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => {setShowCreateRole(true)}}>Create Role</Button>
            <PaperModal show={showCreateRole} setShow={setShowCreateRole} enablePadding flexHeight>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Create a role using one of the default IDs, or type in a custom role ID</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={Object.values(defaultRoles)}
                            value={newRoleId}
                            getOptionDisabled={(option) => currentRoles.some((r) => r.id === option)}
                            onInputChange={(_, value) => {
                                setNewRoleId(value);
                            }}
                            onChange={(_, newValue) => {
                                if(newValue !== null)
                                    setNewRoleId(newValue)
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Role ID (Must be unique)"
                                    variant="outlined"
                                    size="small"
                                />
                            }
                            disabled={isDisabled()}
                            freeSolo
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Role Name (For display purposes only)" value={newRoleName} onChange={(e) => { setNewRoleName(e.target.value as string)}} 
                            variant="outlined" size="small" fullWidth/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={createRoleHandler} disabled={isDisabled() || isInvalid()}>
                            Create Role
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage}/>
                    </Grid>
                </Grid>
            </PaperModal>
        </>
    );
}

export default CreateRole;