import { useState } from "react";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import JSONPretty from "react-json-pretty";
import app from "../../firebase";
import Grid from "@material-ui/core/Grid";
import { Column, QueueItem } from "./QueueList";

var JSONPrettyMon = require("react-json-pretty/dist/monikai");

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        jsonContainer: {
            maxWidth: "80vw",
        },
        documentID: {
            wordWrap: "break-word",
            whiteSpace: "normal",
        },
    })
);

interface QueueListItemProps {
    item: QueueItem;
    collectionName: string;
    parentCollectionName?: string;
    columns: Column[];
    updateQueueItem: (item: QueueItem) => void;
    deleteQueueItem: (id: string) => void;
}

const QueueListItem = ({
    item,
    collectionName,
    parentCollectionName,
    columns,
    updateQueueItem,
    deleteQueueItem,
}: QueueListItemProps) => {
    const classes = useStyles();
    const [showMore, setShowMore] = useState(false);

    const [status, setStatus] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState("");

    const queueResend = app.functions().httpsCallable("portal-queueResend");
    const mdDocument = app.functions().httpsCallable("portalV2-document");

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const refetchQueueDocument = (
        id: string,
        collectionName: string,
        parentId?: string
    ): Promise<void> => {
        const path = parentCollectionName
            ? `${parentCollectionName}/${parentId}/${collectionName}/${id}`
            : `${collectionName}/${id}`;
        return mdDocument({
            path,
            operation: "get",
        }).then((result) => {
            // If data is still there, then update with newest value
            if (result.data) {
                updateQueueItem({
                    id: id,
                    parentId: parentId,
                    data: result.data,
                });
            }
            // Otherwise delete the queue item
            else {
                deleteQueueItem(id);
            }
        });
    };

    const resend = (
        /** ID of the queue item being resent */
        id: string,
        /** Name of the queue in firestore */
        collectionName: string,
        /** ID of the parent of the queue item being resent (undefined if no parent) */
        parentId?: string
    ) => {
        setStatus(ProcessState.running);
        return queueResend({
            id: id,
            queue: collectionName,
            parentId: parentId,
        })
            .then((result) => {
                setStatus(ProcessState.success);

                // Refetch queue item
                return refetchQueueDocument(id, collectionName, parentId)
                    .then(() => {
                        setTimeout(() => {
                            // On success go idle
                            setStatus(ProcessState.idle);
                        }, 1000);
                    })
                    .catch((error: Error) => {
                        // If failed to fetch show an error
                        console.error(error);
                        setStatus(ProcessState.error);
                        setErrorMessage(
                            "Failed to refetch queue item, please refresh the list"
                        );
                    });
            })
            .catch((error: Error) => {
                // If queue failed to resend, fetch document to update error message
                return refetchQueueDocument(id, collectionName, parentId)
                    .catch(() => {
                        // Ignore fetch error and show original queue error
                        console.error(
                            `Refetch queue item error: ${error.message}`
                        );
                    })
                    .finally(() => {
                        // Show original queue error
                        setStatus(ProcessState.error);
                        setErrorMessage(error.message);
                        console.error(error);
                    });
            });
    };

    return (
        <>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={2} className={classes.documentID}>
                    {item.parentId ? `${item.parentId}/ ` : ""}
                    {item.id}
                </Grid>
                {columns.map((column, index) => {
                    return (
                        <Grid
                            item
                            xs={column.gridSize}
                            key={`${item.id}_${column.key}_${index}`}
                        >
                            {column.renderValue
                                ? column.renderValue(item.data[column.key])
                                : item.data[column.key]}
                        </Grid>
                    );
                })}
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            resend(item.id, collectionName, item.parentId);
                        }}
                    >
                        Resend
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={toggleShowMore}
                        endIcon={
                            showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />
                        }
                    >
                        {showMore ? "Hide" : "Show"}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <ProcessStatus
                        state={status}
                        errorMessage={errorMessage}
                        successMessage={"Queue item resent."}
                    ></ProcessStatus>
                </Grid>
            </Grid>
            <Collapse in={showMore}>
                <div className={classes.jsonContainer}>
                    <JSONPretty
                        data={item.data}
                        theme={JSONPrettyMon}
                    ></JSONPretty>
                </div>
            </Collapse>
        </>
    );
};

export default QueueListItem;

