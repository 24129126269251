import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import IconButton from '@material-ui/core/IconButton';
import moment from "moment-timezone";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pointer: {
            cursor: "pointer"
        }
    }),
);

interface MonthPickerProps {
    fromDate: Date,
    setMonth: (from: Date, to: Date) => void,
    label?: string
}

const MonthPicker = ({ fromDate, setMonth, label }: MonthPickerProps) => {
    const classes = useStyles();
    const handleChangeDate = (date: MaterialUiPickersDate) => {
        
        if(date !== null) {
            const from = date?.clone().startOf('month');
            const to = date?.clone().endOf('month');
            setMonth(from.toDate(), to.toDate());
        }
    }

    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IconButton onClick={() => {
                    handleChangeDate(moment(fromDate.setMonth(fromDate.getMonth() - 1)))
                }}>
                    <ChevronLeft/>
                </IconButton>
                
                <DatePicker 
                    inputProps={{
                        className: classes.pointer
                    }}
                    views={["month", "year"]}
                    variant="inline" 
                    autoOk 
                    label={label ? label : "Month"} 
                    value={fromDate} 
                    onChange={handleChangeDate}
                />
                <IconButton onClick={() => {
                    handleChangeDate(moment(fromDate.setMonth(fromDate.getMonth() + 1)))
                }}>
                    <ChevronRight/>
                </IconButton>
            </MuiPickersUtilsProvider>

        </>
    );
}

export default MonthPicker;