import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import auth from "../../firebase/auth";

const Logout = () => {
    const history = useHistory();
    
    useEffect(() => {
        auth.signOut()
        .then(() => {
            history.push('/auth/login');
        })
        .catch((error: Error) => {
            console.error(error);
        })
    }, [])

    return (
        <>
        </>
    );
}

export default Logout;