import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ProcessState } from "@alethea-medical/alethea-components";
import app from "../../firebase";
import { useState } from "react";
import { parse } from "json2csv";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Typography } from "@material-ui/core";
import { saveAs } from "file-saver";

interface AveiroReportProps {
    fromDate: Date;
    toDate: Date;
    setProcessState: (state: ProcessState) => void;
    errorHandler: (error: { error?: any; userMessage?: string }) => void;
}

const AveiroReport = ({
    fromDate,
    toDate,
    setProcessState,
    errorHandler,
}: AveiroReportProps) => {
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const generateAveiroReport = app
        .functions()
        .httpsCallable("generateAveiroReport");

    const generateAveiroReportHandler = () => {
        setProcessState(ProcessState.running);
        setButtonDisabled(true);
        generateAveiroReport({
            dateFrom: fromDate.getTime(),
            dateTo: toDate.getTime(),
        })
            .then((response) => {
                setButtonDisabled(false);
                setProcessState(ProcessState.idle);
                reportToCSV(response.data);
            })
            .catch((error) => {
                setButtonDisabled(false);
                errorHandler({ error });
            });
    };

    const reportToCSV = (report: { [key: string]: any }) => {
        if (report.length === 0) {
            errorHandler({ userMessage: "No data to export" });
            return;
        }

        const csv = parse(report);
        const fileToSave = new Blob([csv], { type: "text/csv" });
        const fileName = `aveiro_payments_${fromDate
            .toLocaleString("en-US", { month: "short", day: "2-digit" })
            .toLowerCase()
            .replace(/\s/g, "_")}_${toDate
            .toLocaleString("en-US", { month: "short", day: "2-digit" })
            .toLowerCase()
            .replace(/\s/g, "_")}`;
        saveAs(fileToSave, fileName);
    };

    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={6}>
                <Typography variant="h6">Aveiro Payments Report</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    disabled={buttonDisabled}
                    onClick={generateAveiroReportHandler}
                    startIcon={<GetAppIcon />}
                >
                    Generate Report CSV
                </Button>
            </Grid>
        </Grid>
    );
};

export default AveiroReport;
