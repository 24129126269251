import { useState , useEffect} from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";

import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import QueueListItem from './QueueListItem';
import app from "../../firebase";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginBottom: theme.spacing(2)
        },
        table: {
            overflow: 'visible',
            width: '100%',
            marginTop: theme.spacing(2)
        },
    }),
);


export interface QueueItem {
    id: string,
    data: any,
    parentId?: string,
}

export interface Column {
    key: string,
    name: string,
    gridSize: GridSize,
    renderValue?: (cellValue?: any) => string,
}

interface QueueListProps {
    /** Name used in the header */
    name: string,
    /** Name of the queue collection in firestore */
    collectionName: string,
    /** If provided, then collectionName is assumed to be a sub collection*/
    parentCollectionName?: string
    /** If true, collectionGroup will be used when querying the database instead of collection */
    isCollectionGroup?: boolean,
    /** Key of timestamp to sort by */
    sortByTimestampKey: string,
    /** Column data */
    columns: Column[],
    /** If provided, only documents with the field queryField will be fetched */
    queryField?: string,
}

const QueueList = ({ name, collectionName, parentCollectionName, isCollectionGroup, sortByTimestampKey, columns, queryField}: QueueListProps) => {
 
    const classes = useStyles();
    const mdQuery = app.functions().httpsCallable("portalV2-query");
    const [status, setStatus] = useState(ProcessState.idle);
    const [errorMessage, setErrorMessage] = useState('');

    const [queueItems, setQueueItems] = useState<QueueItem[]>([]);

    const [show, setShow] = useState(false);
    const toggleShow = () => {
        setShow(!show);
    }

    const updateQueueItem = (item: QueueItem) => {
        const idx = queueItems.findIndex((i) => {
            return i.id === item.id;
        })
        if(idx !== -1) {
            const newQueueItems = [...queueItems]
            newQueueItems[idx] = item;
            setQueueItems(newQueueItems);
        }
    }

    const deleteQueueItem = (id: string) => {
        const idx = queueItems.findIndex((i) => {
            return i.id === id;
        })
        if(idx !== -1) {
            const newQueueItems = [...queueItems]
            newQueueItems.splice(idx, 1);
            setQueueItems(newQueueItems);
        }
    }

    useEffect(() => {
        fetchQueueItems();
    }, [])

    const fetchQueueItems = () => {
        setStatus(ProcessState.running);
        setQueueItems([]);

        // Use collection group if setting is true
        const request: any = {
            path: collectionName,
            isCollectionGroup,
        }

        // provide orderBy, to only fetch items where the field with name errorName exists
        if(queryField) {
            request.orderBy = {
                field: queryField,
                direction: "desc"
            }
        }

        mdQuery(request)
        .then((result) => {
            setStatus(ProcessState.idle);
            setQueueItems(result.data);
        })
        .catch((error: Error) => {
            setStatus(ProcessState.error);
            setErrorMessage(error.message);
            console.error(error);
        })
    }


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant="h5">{name}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="subtitle1">{queueItems.length} items in queue</Typography>
            </Grid>
            <IconButton className={classes.button} onClick={fetchQueueItems}><RefreshIcon/></IconButton>
            <Button className={classes.button} onClick={toggleShow}>{show ? <>Hide <ExpandLessIcon/></> : <>Show <ExpandMoreIcon/></>}</Button>
            <Grid item xs={12}>
                <ProcessStatus state={status} errorMessage={errorMessage}></ProcessStatus>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={show}>
                    <Grid container spacing={1} className={classes.table}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>Document ID</Grid>
                                {columns.map((column) => {
                                    return (
                                        <Grid item xs={column.gridSize} key={`${collectionName}_${column.key}`}>
                                            {column.name}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        {queueItems.sort((a, b) => {
                            return a.data[sortByTimestampKey]?._seconds > b.data[sortByTimestampKey]?._seconds ? -1 : 1
                        }).map((item) => 
                            <Grid item xs={12} key={`${collectionName}_${item.id}`}>
                                <QueueListItem item={item} collectionName={collectionName} parentCollectionName={parentCollectionName} columns={columns} updateQueueItem={updateQueueItem} deleteQueueItem={deleteQueueItem}/>
                            </Grid>
                        )}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>

    );
}

export default QueueList;