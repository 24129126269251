import FormTextField from "../../../components/FormTextField";
import { Control, useWatch } from "react-hook-form";
import Tooltip from "@material-ui/core/Tooltip";

import FormCheckbox from "../../../components/FormCheckbox";
import PhoneInput from "../../../components/PhoneInput";
import FormSelect from "../../../components/FormSelect";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import LocationBilling from "./LocationBilling";
import Typography from "@material-ui/core/Typography";
import FormSelectAutocomplete from "../../../components/FormSelectAutocomplete";
import app from "../../../firebase";
import { useState, useEffect } from "react";
import { ProcessStatus, ProcessState } from "@alethea-medical/react-components";

interface ClinicProps {
    control: Control;
    index: number;
    remove: (index?: number | number[] | undefined) => void;
}

const Clinic = ({ control, index, remove }: ClinicProps) => {
    const provinces = [
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NT",
        "NS",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT",
    ];
    const province = useWatch({
        control,
        name: `locations.${index}.province`,
        defaultValue: "AB",
    });

    const isDefault = useWatch({
        control,
        name: `locations.${index}.default`,
        defaultValue: false,
    });

    const isDisabled = useWatch({
        control,
        name: `locations.${index}.disabled`,
        defaultValue: false,
    });

    const getDocument = app.functions().httpsCallable("portalV2-document");
    const [emrs, setEmrs] = useState([]);
    const [clinics, setClinics] = useState([]);

    const getEmrs = () => {
        return getDocument({ path: "cms/emrs", operation: "get" });
    };

    const getClinics = () => {
        return getDocument({ path: "cms/clinics", operation: "get" });
    };

    useEffect(() => {
        getEmrs().then((result) => {
            setEmrs(result.data.emrList ?? []);
        });

        getClinics().then((result) => {
            setClinics(result.data.clinicNames ?? []);
        });
    }, []);

    return (
        <>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography variant="h5">Clinic {index + 1}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Remove this clinic? (Warning: removing or changing the order of locations can cause unexpected behaviour for this user if they have pre-existing data such as eConsults.)"
                                )
                            ) {
                                remove(index);
                            }
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        Delete Clinic
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>Clinic Info</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelectAutocomplete
                                name={`locations.${index}.clinicName`}
                                control={control}
                                label="Clinic Name"
                                options={clinics}
                                getOptionLabel={(option: string) => option}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Clinic Name is required",
                                    },
                                }}
                                {...{ freeSolo: true, autoSelect: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput
                                name={`locations.${index}.clinicPhone`}
                                control={control}
                                label="Clinic Phone Number"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Phone Number is required",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelectAutocomplete
                                name={`locations.${index}.emr`}
                                control={control}
                                label="EMR"
                                options={emrs}
                                getOptionLabel={(option: string) => option}
                                {...{ freeSolo: true, autoSelect: true }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PhoneInput
                                name={`locations.${index}.fax`}
                                control={control}
                                label="Fax Number"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Fax Number is required",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name={`locations.${index}.city`}
                                control={control}
                                label="City"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "City is required",
                                    },
                                }}
                            />
                        </Grid>
                        <Tooltip
                            title="Ex: SW, NE, Central"
                            placement="right"
                            arrow
                        >
                            <Grid item xs={12}>
                                <FormTextField
                                    name={`locations.${index}.division`}
                                    control={control}
                                    label="City Division"
                                />
                            </Grid>
                        </Tooltip>

                        <Grid item xs={12}>
                            <FormSelect
                                name={`locations.${index}.province`}
                                control={control}
                                options={provinces}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox
                                name={`locations.${index}.billable`}
                                control={control}
                                label="Billable"
                            />

                            <FormCheckbox
                                name={`locations.${index}.disabled`}
                                control={control}
                                label="Disabled"
                            />

                            <FormCheckbox
                                name={`locations.${index}.default`}
                                control={control}
                                label="Default"
                            />
                        </Grid>
                        {isDefault && isDisabled && (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    marginTop: "-10px",
                                    marginBottom: "10px",
                                }}
                            >
                                <ProcessStatus
                                    state={ProcessState.error}
                                    errorMessage={
                                        "Disabled clinics can't be the default, the default flag will be removed."
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <LocationBilling
                        control={control}
                        index={index}
                        province={province}
                    />
                </Grid>
            </Grid>
        </>
    );
};
export default Clinic;
