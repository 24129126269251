import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import GetCampaigns, { rowsPerPageOptions } from './GetCampaigns';
import CampaignRow from './CampaignRow';
import CreateCampaign from './CreateCampaign';
import EditCampaign from './EditCampaign';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RefreshIcon from "@material-ui/icons/Refresh";
import Select from '@material-ui/core/Select';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            padding: theme.spacing(2),
        }
    })
)
const FcmCampaign = () => {
    const classes = useStyles();

    const { sortedCampaigns, updateCampaign, numPerPage, setNumPerPage, pageIndex, nextPage, prevPage,
        refresh,
        disableLoadMoreLoading, disableLoadMoreEndOfResults, 
        renderGetState } = GetCampaigns()
        
    const { setCampaign, renderModal } = EditCampaign( { updateCampaign })



    return (
        <Grid container alignItems="center">
            <Grid item xs={12} className={classes.item}>
                <Typography  variant="h4">Notification Campaigns</Typography>
            </Grid>
            <Grid item className={classes.item}>
                <IconButton onClick={refresh}>
                    <RefreshIcon/>
                </IconButton>
            </Grid>
            <Grid item className={classes.item}>
                <CreateCampaign updateCampaign={updateCampaign}/>
            </Grid>
            {renderModal()}
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                <TableCell>
                                    Campaign
                                </TableCell>
                                <TableCell>
                                    Created
                                </TableCell>
                                <TableCell>
                                    Sent At
                                </TableCell>
                                <TableCell>
                                    Status
                                </TableCell>
                                <TableCell>
                                    Sends
                                </TableCell>
                                <TableCell>
                                    Opens
                                </TableCell>
                                <TableCell>
                                    Open Rate
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedCampaigns.map((campaign) => (
                                <CampaignRow key={`campaign_${campaign.id}`} id={campaign.id} campaign={campaign.data} setCampaign={setCampaign} updateCampaign={updateCampaign}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                {renderGetState()}
                <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                    <Grid item>
                        Rows per Page: 
                    </Grid>
                    <Grid item>
                        <Select
                            value={numPerPage}
                            onChange={(event) => {setNumPerPage(event.target.value as number)}}
                        >
                            {rowsPerPageOptions.map((option) => (
                                <MenuItem key={`rowsPerPage_${option}`} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item>
                        <Button onClick={prevPage} disabled={disableLoadMoreLoading || pageIndex === 0} variant="text" startIcon={<ChevronLeftIcon/>}>
                            Prev
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={nextPage} disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults} variant="text" endIcon={<ChevronRightIcon/>}>
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default FcmCampaign;