import firebase from "firebase";

interface Timestamp1 {
    _seconds: number, _nanoseconds: number
}
interface Timestamp2 {
    seconds: number, nanoseconds: number
}

function parseJSONTimestamp(timestamp: Timestamp1 | Timestamp2 | undefined) {
    
    if((timestamp as Timestamp1)?._seconds !== undefined && (timestamp as Timestamp1)?._nanoseconds !== undefined) {
        return firebase.firestore.Timestamp.fromMillis((timestamp as Timestamp1)._seconds * 1000 + (timestamp as Timestamp1)._nanoseconds / 100000);
    }
    else if((timestamp as Timestamp2)?.seconds !== undefined && (timestamp as Timestamp2)?.nanoseconds !== undefined) {
        return firebase.firestore.Timestamp.fromMillis((timestamp as Timestamp2).seconds * 1000 + (timestamp as Timestamp2).nanoseconds / 100000);

    }
    else {
        return undefined;
    }
}

export default parseJSONTimestamp