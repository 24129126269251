import { useState } from "react"
import { ProcessState } from "@alethea-medical/react-components";

interface ErrorHandlerOptions {
    error?: Error, 
    userMessage?: string, 
    hideErrorMessage?: boolean
}

function useProcessState () {

	const [processState, setProcessState] = useState<ProcessState>(ProcessState.idle);
	const [processErrorMessage, setProcessErrorMessage] = useState<string>("");
    const [processSuccessMessage, setProcessSuccessMessage] = useState<string>("");

    
	const errorHandler = (options: ErrorHandlerOptions) => {
		if(options.userMessage !== undefined) {
            if(options.hideErrorMessage || options.error === undefined) {
                setProcessErrorMessage(options.userMessage);    
            }
            else {
                setProcessErrorMessage(`${options.userMessage} : ${options.error.message}`);
            }
			
			setProcessState(ProcessState.error);
		}
        if(options.error !== undefined) {
            console.error(options.error);
        }

	}

    return {
        processState, setProcessState,
        processErrorMessage, setProcessErrorMessage,
        processSuccessMessage, setProcessSuccessMessage,
        errorHandler
    }
}

export default useProcessState;