import React from 'react';
import Paper from '@material-ui/core/Paper';
interface TabPanelProps {
    className?: string,
    index: number,
    value: number,
    children?: React.ReactNode;
}

const TabPanel = ({ className, value, index, children }: TabPanelProps) => {
    
    return (
        <Paper
            className={className}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </Paper>
    );
}

export default TabPanel;