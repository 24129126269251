import { useMemo, useEffect } from "react";
import { User } from "@alethea-medical/aletheamd-types";
import { defaultRoles } from "@alethea-medical/aletheamd-db-keys";
import { ProcessStatus, ProcessState } from "@alethea-medical/react-components";
import { useFieldArray, useForm } from "react-hook-form";
import app from "../../../firebase";
import useProcessState from "../../../components/useProcessState";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormProfileInput from "../FormProfileInput";
import Typography from "@material-ui/core/Typography";
import { ReactHookFormUserProfile } from "../userTypes";
import FormRoleInput, { RoleAsObject } from "../FormRoleInput";
import getFaxPrefs from "./getFaxPrefs";

interface EditProfileProps {
    user: User;
    refreshCurrentUser: () => void;
}

const EditProfile = ({ user, refreshCurrentUser }: EditProfileProps) => {
    //Returns formatted profile either for defaults, or to use to update the user object in database
    const getProfileValues = (
        userData: Partial<ReactHookFormUserProfile>,
        isDefault?: boolean
    ) => {
        const preferences = userData?.preferences ?? {};
        const newProfile: Partial<ReactHookFormUserProfile> = {
            ban: userData.ban,
            firstName: userData.firstName,
            lastName: userData.lastName,
            practiceId: userData.practiceId,
            credentials: userData.credentials ? userData.credentials : "",
            title: userData.title ? userData.title : "",
            skillCode: userData.skillCode ? userData.skillCode : "",
            //We don't update locations here, so don't include

            //Make sure boolean values are defaulted to either true of false, and not undefined
            billingPreferences: {
                banNotReceived:
                    userData.billingPreferences?.banNotReceived === true,
                useSEmailCode:
                    userData.billingPreferences?.useSEmailCode === true,
            },

            //Keep resident for backwards compatibility in mobile app
            roles: {
                resident:
                    userData?.userRoles !== undefined
                        ? userData.userRoles.includes(defaultRoles.resident)
                        : false,
            },
            preferences: {
                ...preferences, // Populate all pre-existing preferences (in case this form doesn't contains all preferences)
                email: {
                    // Then update with form values
                    secureMessaging:
                        userData.preferences?.email?.secureMessaging === true,
                    /** If forms preference does not exist on profile, then default to using the secure messaging value (Legacy - Pre-v8.5.4.2023.09.28: use secure messaging to check to send this email or not) */
                    submittedPatientForms:
                        userData.preferences?.email?.submittedPatientForms ??
                        userData.preferences?.email?.secureMessaging === true,
                    failedEmails:
                        userData.preferences?.email?.failedEmails === true,
                    overdueSecureMessages:
                        userData.preferences?.email?.overdueSecureMessages ===
                        true,
                },
                fax: getFaxPrefs(userData, isDefault),
                mobilePushNotifications: {
                    /** If mobile push notification preferences do not exist on profile, then default to true (Legacy - Pre-v8.5.4.2023.09.28: did not check any preference) */
                    secureMessaging:
                        userData.preferences?.mobilePushNotifications
                            ?.secureMessaging ?? true,
                    econsult:
                        userData.preferences?.mobilePushNotifications
                            ?.econsult ?? true,
                    aletheaNews:
                        userData.preferences?.mobilePushNotifications
                            ?.aletheaNews ?? true,
                },
                secureMessaging: {
                    nudgeOverdueMessages:
                        userData.preferences?.secureMessaging
                            ?.nudgeOverdueMessages === true,
                },
                phoneConsult: {
                    phoneNumber:
                        userData.preferences?.phoneConsult?.phoneNumber ?? "",
                    calendarEmail:
                        userData.preferences?.phoneConsult?.calendarEmail ?? "",
                },
                homepage: userData.preferences?.homepage,
            },
            userRoles:
                userData?.userRoles !== undefined ? userData.userRoles : [],
            /*Create a separate field that stores roles as objects rather than strings. This is necessary because react hook form does not allow string arrays to be
            used with useFieldArray*/
            userRolesObject:
                userData?.userRoles !== undefined
                    ? userData.userRoles.map((r) => {
                          return { roleId: r };
                      })
                    : [],
        };
        return newProfile;
    };

    const { handleSubmit, control, reset, setValue } = useForm({
        mode: "onTouched",
        defaultValues: useMemo(() => getProfileValues(user, true), [user]),
    });

    const {
        fields: userRoles,
        append: appendRoles,
        remove: removeRoles,
    } = useFieldArray({
        control,
        name: "userRolesObject",
        shouldUnregister: true,
    });

    useEffect(() => {
        reset(getProfileValues(user, true));
    }, [user]);

    const updateDoc = app.functions().httpsCallable("portal-updateDoc");
    const { processState, setProcessState, processErrorMessage, errorHandler } =
        useProcessState();

    const onSubmit = (data: any) => {
        // If MD Specialist or Specialist role is selected, skill code can't be empty
        if (
            data.userRolesObject.some((r: RoleAsObject) =>
                [defaultRoles.specialist, defaultRoles.mdSpecialist].includes(
                    r.roleId
                )
            ) &&
            data.skillCode === ""
        ) {
            errorHandler({
                userMessage:
                    "Skill code is required for specialists (user has Specialist role)",
            });
            return;
        }

        setProcessState(ProcessState.running);

        // Trim input values
        if (data.firstName) data.firstName = data.firstName.trim();
        if (data.lastName) data.lastName = data.lastName.trim();
        if (data.practiceId) data.practiceId = data.practiceId.trim();
        if (data.credentials) data.credentials = data.credentials.trim();
        if (data.title) data.title = data.title.trim();

        //Set user roles to the values contained in the userRolesObject array
        //The userRolesObject array is the array that gets updated when roles are added in the autocomplete
        data.userRoles = data.userRolesObject.map(
            (r: RoleAsObject) => r.roleId
        );

        //Parse the data
        const newProfile = getProfileValues(data, false);

        //Delete the userRolesObject field (the user profile object does not need the userRoleSObject array)
        delete newProfile.userRolesObject;

        updateDoc({ collection: "users", id: user.uid, data: newProfile })
            .then(() => {
                refreshCurrentUser();
                setProcessState(ProcessState.success);
                setTimeout(() => {
                    setProcessState(ProcessState.idle);
                }, 1000);
            })
            .catch((error: Error) => {
                errorHandler({
                    userMessage: error.message,
                    error: error,
                });
            });
    };

    const onError = () => {
        errorHandler({
            userMessage: "Check form for errors.",
        });
    };

    const isDisabled = () => {
        return (
            processState === ProcessState.running ||
            processState === ProcessState.success
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <fieldset disabled={isDisabled()}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                UID: {user.uid}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormProfileInput
                                control={control}
                                setValue={setValue}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* Force type for append roles. I don't know why it doesn't have the right type, its basically the same as locations*/}
                            <FormRoleInput
                                control={control}
                                userRoles={userRoles}
                                append={
                                    appendRoles as unknown as (
                                        role: RoleAsObject
                                    ) => void
                                }
                                remove={removeRoles}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={isDisabled()}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus
                                state={processState}
                                errorMessage={processErrorMessage}
                                successMessage={"Profile updated"}
                            />
                        </Grid>
                    </Grid>
                </fieldset>
            </form>
        </>
    );
};

export default EditProfile;
