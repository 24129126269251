import { ProcessState, useProcessState } from "@alethea-medical/alethea-components";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { Specialist } from "@alethea-medical/aletheamd-types";
import { useEffect, useState } from "react";
import app from "../../../firebase";

export interface ConsultantGroupItem {
    id: string,
    data: Specialist.ConsultantGroup
}

interface EditConsultantGroupsControllerProps {
    
}

export const newGroupId = "new_group_id"// Use as new group placeholder ID

const EditConsultantGroupsController = ({}: EditConsultantGroupsControllerProps) => {
    
    const getDoc = app.functions().httpsCallable("portal-getDoc");


    const [consultantGroups, setConsultantGroups] = useState<ConsultantGroupItem[]>([])
    const [selectedGroup, setSelectedGroup] = useState<ConsultantGroupItem>()

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({});
    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success
    }

    const refreshGroupDropdown = () => {
        setProcessState(ProcessState.running);
        getDoc({ collection: dbNames.consultantGroups })
            .then((result) => {
                setProcessState(ProcessState.idle);
                result.data.sort(sortConsultantGroups)
                setConsultantGroups(result.data)
                
                // If previously selected group was deleted, then unselect group
                if(!result.data.find((group: {id: string}) => group.id === selectedGroup?.id)) {
                    setSelectedGroup(undefined)
                }
                // If previously selected group was newly created, unselect it since the ID has now changed
                if(selectedGroup?.id === newGroupId) {
                    setSelectedGroup(undefined)
                }
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: error.message
                })
            })
    }

    useEffect(() => {
        refreshGroupDropdown()
    }, []);

    const sortConsultantGroups = (a: ConsultantGroupItem, b: ConsultantGroupItem) => {
        return `${a.data.groupName} ${a.id}`.toLowerCase() > `${b.data.groupName} ${b.id}`.toLowerCase() ? 1 : -1;
    }

    const onSelectGroup = (groupId: string) => {
        setSelectedGroup(consultantGroups.find((g) => g.id === groupId))
    }

    const createGroupHandler = () => {
        if(consultantGroups.find((g) => g.id === newGroupId)) {
            errorHandler({
                userMessage: "Save the new group before creating a new one."
            })
            return
        }

        const newGroup: ConsultantGroupItem = {
            id: newGroupId,
            data: {
                groupName: "New Group",
                doTriage: false,
                triagers: [],
                consultants: [],
                members: [],
            }
                
        }

        setConsultantGroups([...consultantGroups, newGroup])
        setSelectedGroup(newGroup)
    }

    return {
        consultantGroups, 
        selectedGroup, setSelectedGroup,
        createGroupHandler,
        onSelectGroup, refreshGroupDropdown,
        processState, processErrorMessage, isDisabled: isDisabled()
    }
}

export default EditConsultantGroupsController;