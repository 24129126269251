import React, { useEffect, useState } from "react";
import auth from "./firebase/auth";
import app from "./firebase"
import { AdminUserProfile } from "../shared/types";
import db from "./firebase/db";
import { getDocumentData } from "@alethea-medical/utilities"


export type ContextProps = {
    user: firebase.User | null;
    profile?: AdminUserProfile;
    authenticated: boolean;
    setUser: any;
    loadingAuthState: boolean;
};

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useState(null as firebase.User | null);
    const [profile, setProfile] = useState<AdminUserProfile>();
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged((user: any) => {
            setUser(user);
            setLoadingAuthState(false);
            if(user !== null)
            {
                app.analytics().setUserId(user.uid);

                db.collection("admin_users").doc(user.uid).get().then(getDocumentData)
                .then((newProfile: AdminUserProfile) => {
                    setProfile(newProfile)
                })
            }
            else {
                console.log("Logged out");
                setUser(null);
                setProfile(undefined);
                console.log("Cleared profile");
            }
        });
    }, []);

    return (
        <AuthContext.Provider 
            value={{
                user,
                profile,
                authenticated: user !== null,
                setUser,
                loadingAuthState
            }}>
                {children}
        </AuthContext.Provider>
    );
}