import { TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { PayrollRows, PayrollRow } from "./types";

interface PayrollTableTotalsRowProps {
    rows: PayrollRows,
}


const useStyles = makeStyles((theme) => ({
    statusCell: {
        textAlign: "center"
    },
    tableCell: {
        overflow: 'auto',
        fontWeight: 600,
        color: theme.palette.primary.main,
        borderBottom: "2px solid #ddd"
    },
    subTable: {
        overflow: 'hidden',
    },
}));

const key = "payroll_total_row"
const doctorName = "Month Total"
const PayrollTableTotalsRow = ({ rows }: PayrollTableTotalsRowProps) => {
    const classes = useStyles();    
    
    const [rowTotals, setRowTotals] = useState<PayrollRow>({
        key,
        recordsToPay: [],
        doctorName,
        totalEconsults: 0,
        totalEconsultRejections: 0,
        totalCommunications: 0,
        totalCommunicationRejections: 0,
        totalBilling: 0,
        totalExpectedBilling: 0,
        totalOwedToDoctor: 0,
        totalPaidToDoctor: 0,
        totalAmountPending: 0,
        totalAmountSubmitted: 0,
        totalAmountFailed: 0,
    })

    useEffect(() => {
        const rowVals = Object.values(rows)
        if(rowVals.length > 0) {
            setRowTotals(rowVals.reduce((prev, current) => {
                return {
                    key,
                    recordsToPay: [],
                    doctorName,
                    totalEconsults: prev.totalEconsults + current.totalEconsults,
                    totalEconsultRejections: prev.totalEconsultRejections + current.totalEconsultRejections,
                    totalCommunications: prev.totalCommunications + current.totalCommunications,
                    totalCommunicationRejections: prev.totalCommunicationRejections + current.totalCommunicationRejections,
                    totalBilling: prev.totalBilling + current.totalBilling,
                    totalExpectedBilling: prev.totalExpectedBilling + current.totalExpectedBilling,
                    totalOwedToDoctor: prev.totalOwedToDoctor + current.totalOwedToDoctor,
                    totalPaidToDoctor: prev.totalPaidToDoctor + current.totalPaidToDoctor,
                    totalAmountPending: prev.totalAmountPending + current.totalAmountPending,
                    totalAmountSubmitted: prev.totalAmountSubmitted + current.totalAmountSubmitted,
                    totalAmountFailed: prev.totalAmountFailed + current.totalAmountFailed,
                }
            }))
        }
    }, [rows])

    return (
        <>
            <TableRow
                tabIndex={-1}
            >
                <TableCell className={classes.tableCell} align="center"></TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.doctorName}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalEconsults}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalEconsultRejections}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalCommunications}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalCommunicationRejections}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalBilling.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalExpectedBilling.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalOwedToDoctor.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalPaidToDoctor.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalAmountPending.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalAmountSubmitted.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="left">{rowTotals.totalAmountFailed.toFixed(2)}</TableCell>
                <TableCell className={classes.tableCell} align="center"></TableCell>
            </TableRow>
        </>
    );
}

export default PayrollTableTotalsRow;